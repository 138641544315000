import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  ListItem,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AddBusinessHours from "./AddBusinessHours";
import { format } from "date-fns";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import TicketSettingsAction from "../../stores/ticketSettings/TicketSettingsAction";
import StatusAction from "../../stores/status/StatusAction";
import SwitchComponent from "../../../components/switchComponent/SwitchComponent";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import AutoCompleteMultiSelect from "../../../components/autoCompleteComponent/AutoCompleteMultiSelect";
import MuiTextField from "../../../components/muiTextField/MuiTextField";
import MuiTimePicker from "../../../components/dateTimePicker/MuiTimePicker";
import MuiButton from "../../../components/muiButton/MuiButton";
import UploadPopper from "../../../a3label_org_intranet/a3labels/components/poppers/UploadPopper";
import InfoIcon from "@mui/icons-material/Info";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";

const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, [
    TicketSettingsAction.REQUEST_TICKET_SETTINGS,
    StatusAction.REQUEST_STATUS_FILTER,
  ]),
  ticketSettings: state.ticketSettings.ticketSettings.hits || {},
  statusFilter: state.statusFilter.statusFilter.hits || [],
});

const MiscellaneousSettings = (props) => {
  // ticket settings api
  function fetchTicketSettings(params) {
    props.dispatch(TicketSettingsAction.requestTicketSettings(params));
  }

  const [open, setOpen] = useState(false);

  // status filter api
  function fetchStatusFilter() {
    let param = {
      page_limit: "none",
      order_by: `[["order_number","asc"]]`,
    };
    props.dispatch(StatusAction.requestStatusFilter(param));
  }

  let PutId =
    props.ticketSettings.hits &&
    props.ticketSettings.hits[0] &&
    props.ticketSettings.hits[0]._id;

  // status list
  let statusList = [];

  if (props.statusFilter.hits) {
    props.statusFilter.hits.map((item) => {
      statusList.push({
        status_id: item._id,
        status_name: item._source.status_name,
      });
    });
  }

  let filtered_status_list = [];
  const fetchStatusChange = (obt_status) => {
    if (obt_status) {
      filtered_status_list = statusList.filter((item) => {
        return obt_status.status_id !== item.status_id;
      });
    } else {
      filtered_status_list = statusList;
    }
  };

  useEffect(() => {
    fetchTicketSettings();
    fetchStatusFilter();
  }, []);

  function FRTFunction(param) {
    return (
      statusList &&
      statusList
        .filter((item) => item.status_id === param)
        .map((item) => {
          return { status_id: item.status_id, status_name: item.status_name };
        })
    );
  }

  const columns = [
    {
      field: "id",
      headerName: "SL NO",
      width: 150,
      flex: 2,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "day",
      headerName: "Days",
      width: 250,
      flex: 4,
    },
    {
      field: "is_holiday",
      headerName: "Work Day",
      width: 250,
      flex: 4,
      renderCell: (params) => (
        <Grid
          display={"center"}
          justifyContent="center"
          alignSelf={"center"}
          p={1.5}
        >
          <SwitchComponent
            // id={item.operation_id ? item.operation_id : ""}
            // label={item.operation_name ? item.operation_name : ""}
            labelPlacement="start"
            checked={params.row.is_holiday}
            onClick={() => {
              // handleCHange(
              //   params.row.operation._id,
              //   item.operation_id,
              //   item.operation_name,
              //   item.enabled,
              //   params.row.operation._source.operation
              // );
            }}
          />
        </Grid>
      ),
    },

    {
      field: "from",
      headerName: "From",
      width: 250,
      flex: 4,
    },
    {
      field: "to",
      headerName: "To",
      width: 250,
      flex: 4,
    },
  ];

  function Weeks(item) {
    if (item === 1) {
      return "Sunday";
    } else if (item === 2) {
      return "Monday";
    } else if (item === 3) {
      return "Tuesday";
    } else if (item === 4) {
      return "Wednesday";
    } else if (item === 5) {
      return "Thursday";
    } else if (item === 6) {
      return "Friday";
    } else if (item === 7) {
      return "Saturday";
    }
  }

  function DateConverter(item) {
    return format(new Date(), "yyyy-MM-dd") + "T" + item;
  }

  function DateConverterfromMins(item) {
    // Input: Total minutes
    const totalMinutes = item;

    // Calculate hours and minutes
    const hours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;
    const minutes = Math.floor(remainingMinutes);
    const seconds = Math.round((remainingMinutes - minutes) * 60);

    // Convert hours and minutes to string with leading zeros
    const hoursString = hours.toString().padStart(2, "0");
    const minutesString = minutes.toString().padStart(2, "0");
    const secondsString = seconds.toString().padStart(2, "0");

    // Format the time string
    const timeString = `${hoursString}:${minutesString}:${secondsString}`;

    // Output: Time in "hh:mm" format

    return format(new Date(), "yyyy-MM-dd") + "T" + timeString;
  }

  let business_hours_list = [];

  if (props.ticketSettings.hits) {
    props.ticketSettings.hits &&
      props.ticketSettings.hits[0] &&
      props.ticketSettings.hits[0]._source &&
      props.ticketSettings.hits[0]._source.business_hours &&
      props.ticketSettings.hits[0]._source.business_hours.map((item, index) => {
        return business_hours_list.push({
          day: Weeks(item.day),
          is_holiday: item.is_holiday,
          from: DateConverter(item.from),
          to: DateConverter(item.to),
        });
      });
  }

  // frt status
  let FRT_status =
    props.ticketSettings.hits &&
    props.ticketSettings.hits[0] &&
    props.ticketSettings.hits[0]._source.frt_status;

  // tat status
  let TAT_status =
    props.ticketSettings.hits &&
    props.ticketSettings.hits[0] &&
    props.ticketSettings.hits[0]._source.tat_status;

  // tat status
  let max_ticket =
    props.ticketSettings.hits &&
    props.ticketSettings.hits[0] &&
    props.ticketSettings.hits[0]._source.max_ticket;

  // frt breach time
  let frt_breach_time =
    props.ticketSettings.hits &&
    props.ticketSettings.hits[0] &&
    props.ticketSettings.hits[0]._source.frt_breach_time &&
    props.ticketSettings.hits[0]._source.frt_breach_time;

  // tat breach time
  let tat_breach_time =
    props.ticketSettings.hits &&
    props.ticketSettings.hits[0] &&
    props.ticketSettings.hits[0]._source.tat_breach_time &&
    props.ticketSettings.hits[0]._source.tat_breach_time;

  // flr time time
  let flr_time =
    props.ticketSettings.hits &&
    props.ticketSettings.hits[0] &&
    props.ticketSettings.hits[0]._source.flr_time &&
    props.ticketSettings.hits[0]._source.flr_time;

  // flr_breach_alert_notification
  let flr_breach_alert_notification =
    props.ticketSettings.hits &&
    props.ticketSettings.hits[0] &&
    props.ticketSettings.hits[0]._source.flr_breach_alert_notification;

  // flr_breach_alert_notification_window_time
  let flr_breach_alert_notification_window_time =
    props.ticketSettings.hits &&
    props.ticketSettings.hits[0] &&
    props.ticketSettings.hits[0]._source
      .flr_breach_alert_notification_window_time &&
    props.ticketSettings.hits[0]._source
      .flr_breach_alert_notification_window_time;

  // flr_breach_alert_notification
  let flr_breach_countdown_timer =
    props.ticketSettings.hits &&
    props.ticketSettings.hits[0] &&
    props.ticketSettings.hits[0]._source.flr_breach_countdown_timer;

  // flr_breach_alert_notification_window_time
  let flr_breach_countdown_timer_window_time =
    props.ticketSettings.hits &&
    props.ticketSettings.hits[0] &&
    props.ticketSettings.hits[0]._source
      .flr_breach_countdown_timer_window_time &&
    props.ticketSettings.hits[0]._source.flr_breach_countdown_timer_window_time;

  // ticket_closure_max_duration
  // let ticket_closure_max_duration =
  //   props.ticketSettings.hits &&
  //   props.ticketSettings.hits[0] &&
  //   props.ticketSettings.hits[0]._source.ticket_closure_max_duration &&
  //   props.ticketSettings.hits[0]._source.ticket_closure_max_duration;

  let initial = {};

  Object.assign(
    initial,
    FRT_status
      ? {
          FRT_status:
            FRTFunction(FRT_status.status_id) &&
            FRTFunction(FRT_status.status_id)[0],
        }
      : {
          FRT_status:
            statusList && statusList.length === 1 ? statusList[0] : null,
        },
    TAT_status
      ? {
          TAT_status:
            FRTFunction(TAT_status.status_id) &&
            FRTFunction(TAT_status.status_id)[0],
        }
      : {
          TAT_status:
            statusList && statusList.length === 1 ? statusList[0] : null,
        },

    max_ticket
      ? {
          max_ticket: max_ticket,
        }
      : {
          max_ticket: "",
        },

    // frt_breach_time
    //   ? { frt_breach_time: frt_breach_time }
    //   : { frt_breach_time: null },
    // tat_breach_time
    //   ? { tat_breach_time: tat_breach_time }
    //   : { tat_breach_time: null },

    frt_breach_time
      ? {
          frt_breach_time: DateConverterfromMins(frt_breach_time),
        }
      : { frt_breach_time: null },

    tat_breach_time
      ? {
          tat_breach_time: DateConverterfromMins(tat_breach_time),
        }
      : { tat_breach_time: null },

    flr_time ? { flr_time: flr_time } : { flr_time: "" },
    // ticket_closure_max_duration !== null
    //   ? {
    //       ticket_closure_max_duration: DateConverter(
    //         ticket_closure_max_duration
    //       ),
    //     }
    //   : { ticket_closure_max_duration: "" },

    flr_time !== null
      ? {
          flr_time: DateConverter(flr_time),
        }
      : { flr_time: "" },

    flr_breach_alert_notification !== null
      ? {
          flr_breach_alert_notification: flr_breach_alert_notification,
        }
      : { flr_breach_alert_notification: false },

    flr_breach_alert_notification_window_time !== null
      ? {
          flr_breach_alert_notification_window_time: DateConverter(
            flr_breach_alert_notification_window_time
          ),
        }
      : { flr_breach_alert_notification_window_time: "" },

    flr_breach_countdown_timer !== null
      ? {
          flr_breach_countdown_timer: flr_breach_countdown_timer,
        }
      : { flr_breach_countdown_timer: false },

    flr_breach_countdown_timer_window_time !== null
      ? {
          flr_breach_countdown_timer_window_time: DateConverter(
            flr_breach_countdown_timer_window_time
          ),
        }
      : { flr_breach_countdown_timer_window_time: "" }
  );

  // if (FRT_status && TAT_status) {
  //   initial = {
  //     FRT_status:
  //       FRTFunction(FRT_status.status_id) &&
  //       FRTFunction(FRT_status.status_id)[0],
  //     TAT_status:
  //       FRTFunction(TAT_status.status_id) &&
  //       FRTFunction(TAT_status.status_id)[0],
  //   };
  // } else {
  //   initial = {
  //     FRT_status: { status_id: "", status_name: "" },
  //     TAT_status: { status_id: "", status_name: "" },
  //   };
  // }

  function Required() {
    return (
      <>
        <span>Required</span> <span style={{ color: "red" }}>*</span>
      </>
    );
  }

  let returnTimeValue = (data) => {
    // Input string
    let timeStr = data;

    // Split the string by ':'
    let timeArr = timeStr.split(":");

    // Extract hours, minutes, and seconds
    let hours = parseInt(timeArr[0]);
    let minutes = parseInt(timeArr[1]);
    let seconds = parseInt(timeArr[2]);

    // Check if hours, minutes, and seconds are less than 10
    if (hours < 10) {
      hours = "0" + hours;
    }

    if (minutes < 10) {
      minutes = "0" + minutes;
    }

    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    // Concatenate the prefix-zeroed hours, minutes, and seconds into a final string
    let finalTimeStr = hours + ":" + minutes + ":" + seconds;

    return finalTimeStr;

    // Output the final string
  };

  const getDurationFunction = (value) => {
    return value.$d
      ? `${value.$H < 10 ? `0${value.$H}` : value.$H}:${
          value.$m < 10 ? `0${value.$m}` : value.$m
        }:${value.$s < 10 ? `0${value.$s}` : value.$s}`
      : value.slice(11) === "undefined"
      ? "00:00:00"
      : returnTimeValue(value.slice(11));
  };

  const minutesFunction = (value) => {
    let returnMinutesString = () => {
      const timeString = `${value.$H}:${value.$m}:${value.$s}`; // Include seconds

      const [hours, minutes, seconds] = timeString.split(":").map(Number);

      // Convert hours, minutes, and seconds to total minutes
      const totalMinutes = hours * 60 + minutes + seconds / 60;

      return `${totalMinutes}`;
    };

    let returnExistingMinutesString = (data) => {
      const timeString = data;
      const [hours, minutes, seconds] = timeString.split(":").map(Number);

      // Convert hours, minutes, and seconds to total minutes
      const totalMinutes = hours * 60 + minutes + seconds / 60;

      return `${totalMinutes}`;
    };

    return value.$d
      ? returnMinutesString()
      : value.slice(11) === "undefined"
      ? "00:00"
      : returnExistingMinutesString(value.slice(11));
  };

  const handleNumberKeyPress = (event) => {
    const keyCode = event.which;
    if (keyCode < 48 || keyCode > 57) {
      // Prevent input of non-numeric characters
      event.preventDefault();
    }
  };

  return (
    <Box width={"100%"} height="100%">
      <LoadingIndicator isActive={props.isRequesting}>
        {!props.isRequesting ? (
          <div>
            <Formik
              initialValues={initial}
              enableReinitialize={true}
              validate={(values) => {
                var errors = {};

                if (!values.FRT_status) {
                  errors.FRT_status = <Required />;
                }

                if (!values.TAT_status) {
                  errors.TAT_status = <Required />;
                }

                return errors;
              }}
              onSubmit={(values, formikHelpers) => {
                let obj = {};

                Object.assign(
                  obj,
                  values.FRT_status && {
                    frt_status: values.FRT_status.status_id,
                  },
                  values.TAT_status && {
                    tat_status: values.TAT_status.status_id,
                  },
                  values.max_ticket && {
                    max_ticket: Number(values.max_ticket),
                  },
                  values.frt_breach_time && {
                    frt_breach_time: minutesFunction(values.frt_breach_time),
                  },
                  values.tat_breach_time && {
                    tat_breach_time: minutesFunction(values.tat_breach_time),
                  },
                  values.flr_time && {
                    flr_time: getDurationFunction(values.flr_time),
                  },
                  {
                    flr_breach_alert_notification:
                      props.ticketSettings &&
                      props.ticketSettings.hits &&
                      props.ticketSettings.hits[0] &&
                      props.ticketSettings.hits[0]._id
                        ? values.flr_breach_alert_notification
                        : false,
                  },
                  values.flr_breach_alert_notification_window_time &&
                    values.flr_breach_alert_notification
                    ? {
                        flr_breach_alert_notification_window_time:
                          getDurationFunction(
                            values.flr_breach_alert_notification_window_time
                          ),
                      }
                    : {
                        flr_breach_alert_notification_window_time: "00:00:00",
                      },
                  {
                    flr_breach_countdown_timer:
                      props.ticketSettings &&
                      props.ticketSettings.hits &&
                      props.ticketSettings.hits[0] &&
                      props.ticketSettings.hits[0]._id
                        ? values.flr_breach_countdown_timer
                        : false,
                  },
                  values.flr_breach_countdown_timer_window_time &&
                    values.flr_breach_countdown_timer
                    ? {
                        flr_breach_countdown_timer_window_time:
                          getDurationFunction(
                            values.flr_breach_countdown_timer_window_time
                          ),
                      }
                    : {
                        flr_breach_countdown_timer_window_time: "00:00:00",
                      }
                );

                if (
                  props.ticketSettings &&
                  props.ticketSettings.hits &&
                  props.ticketSettings.hits[0] &&
                  props.ticketSettings.hits[0]._id
                ) {
                  props
                    .dispatch(
                      TicketSettingsAction.requestPutTicketSettings(
                        JSON.stringify(obj),
                        PutId
                      )
                    )
                    .then(() => {
                      props.dispatch(
                        TicketSettingsAction.requestTicketSettings()
                      );
                    });
                } else {
                  props
                    .dispatch(
                      TicketSettingsAction.requestPostTicketSettings(obj)
                    )
                    .then(() => {
                      props.dispatch(
                        TicketSettingsAction.requestTicketSettings()
                      );
                    });
                }
              }}
            >
              {({
                errors,
                isValid,
                helperText,
                touched,
                dirty,
                handleChange,
                values,
              }) => (
                <Form>
                  <Grid
                    container
                    item
                    xl={12}
                    lg={12}
                    sm={12}
                    md={12}
                    gap={1}
                    alignItems="flex-start"
                    justifyContent={"flex-start"}
                    pt={2}
                  >
                    <Grid item lg={2} sm={2} md={2}>
                      <Typography>
                        <UploadPopper format={"Initial status value"}>
                          <InfoIcon sx={{ fontSize: 15 }} /> &nbsp; FRT Status :
                        </UploadPopper>
                      </Typography>
                      <Field
                        name="FRT_status"
                        size="small"
                        component={AutoCompleteMultiSelect}
                        options={
                          filtered_status_list ? filtered_status_list : []
                        }
                        textFieldProps={{
                          margin: "normal",
                          variant: "outlined",
                          placeholder: "FRT Status",
                          autoFocus: true,
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.status_id &&
                          option.status_id === value.status_id &&
                          value.status_id
                        }
                        getOptionLabel={(option) =>
                          option.status_name && option.status_name
                        }
                      />

                      {values.FRT_status
                        ? fetchStatusChange(values.FRT_status)
                        : fetchStatusChange(null)}
                    </Grid>
                    <Grid item lg={2} sm={2} md={2}>
                      <Typography>
                        <UploadPopper format={"Final status value"}>
                          <InfoIcon sx={{ fontSize: 15 }} /> &nbsp; TAT Status :
                        </UploadPopper>
                      </Typography>
                      <Field
                        name="TAT_status"
                        size="small"
                        component={AutoCompleteMultiSelect}
                        options={
                          filtered_status_list ? filtered_status_list : []
                        }
                        textFieldProps={{
                          margin: "normal",
                          variant: "outlined",
                          placeholder: "TAT Status",
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.status_id &&
                          option.status_id === value.status_id &&
                          option.status_id
                        }
                        getOptionLabel={(option) =>
                          option.status_name && option.status_name
                        }
                      />

                      {values.TAT_status
                        ? fetchStatusChange(values.TAT_status)
                        : fetchStatusChange(null)}
                    </Grid>

                    <Grid item lg={2} sm={2} md={2}>
                      <p className="mb-3">Max Ticket :</p>
                      <Field
                        // text feild from material Ui
                        as={MuiTextField}
                        fullWidth
                        variant="outlined"
                        id="max_ticket"
                        size="small"
                        name="max_ticket"
                        type="text"
                        inputMode="numeric"
                        handleKeyPress={handleNumberKeyPress}
                        placeholder="Max Ticket"
                        // value={formik.values.tag_name}
                        error={
                          Boolean(errors.max_ticket) &&
                          Boolean(touched.max_ticket)
                        }
                        helperText={
                          Boolean(touched.max_ticket) && errors.max_ticket
                        }
                      />
                    </Grid>

                    {/* <Grid
                      item
                      // lg={2} sm={2} md={2}
                    >
                      <p className="mb-3">Ticket Closure Max Duration :</p>
                      <Field
                        name="ticket_closure_max_duration"
                        // value={values.ticket_closure_max_duration}
                        component={MuiTimePicker}
                         format={"hh:mm"}
                        width="100%"
                        size="small"
                      />
                    </Grid> */}

                    <Grid
                      item
                      // lg={2} sm={2} md={2}
                    >
                      <p className="mb-3">FRT Breach Time :</p>
                      <Field
                        name="frt_breach_time"
                        component={MuiTimePicker}
                        views={["hours", "minutes", "seconds"]}
                        format={"HH:mm:ss"}
                        value={values.frt_breach_time}
                        width="100%"
                        size="small"
                      />
                    </Grid>

                    <Grid
                      item
                      // lg={2} sm={2} md={2}
                    >
                      <p className="mb-3">Ticket Closure Max Duration :</p>
                      <Field
                        value={values.tat_breach_time}
                        name="tat_breach_time"
                        component={MuiTimePicker}
                        views={["hours", "minutes", "seconds"]}
                        format={"HH:mm:ss"}
                        width="100%"
                        size="small"
                      />
                    </Grid>

                    <Grid
                      item
                      // lg={2} sm={2} md={2}
                    >
                      <p className="mb-3">FRT Time :</p>
                      <Field
                        name="flr_time"
                        component={MuiTimePicker}
                        views={["hours", "minutes", "seconds"]}
                        format={"HH:mm:ss"}
                        width="100%"
                        size="small"
                        value={values.flr_time}
                      />
                    </Grid>

                    <Grid
                      mt={4.5}
                      item
                      // container
                      textAlign="center"
                      justifyContent={"center"}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.flr_breach_alert_notification}
                          />
                        }
                        label="FRT Breach Alert Notification"
                        name="flr_breach_alert_notification"
                        value={values.flr_breach_alert_notification}
                        onChange={handleChange}
                      />
                    </Grid>

                    {values.flr_breach_alert_notification && (
                      <Grid item lg={2} sm={2} md={2}>
                        <p className="mb-3">
                          FRT Breach Alert Notification Window Time :
                        </p>
                        <Field
                          value={
                            values.flr_breach_alert_notification_window_time
                          }
                          name="flr_breach_alert_notification_window_time"
                          component={MuiTimePicker}
                          views={["hours", "minutes", "seconds"]}
                          format={"HH:mm:ss"}
                          width="100%"
                          size="small"
                        />
                      </Grid>
                    )}

                    <Grid
                      mt={4.5}
                      item
                      // container
                      textAlign="center"
                      justifyContent={"center"}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.flr_breach_countdown_timer}
                          />
                        }
                        label="FRT Breach Countdown Timer"
                        name="flr_breach_countdown_timer"
                        value={values.flr_breach_countdown_timer}
                        onChange={handleChange}
                      />
                    </Grid>

                    {values.flr_breach_countdown_timer && (
                      <Grid
                        item
                        // lg={2} sm={2} md={2}
                      >
                        <p className="mb-3">
                          FRT Breach Countdown Timer Window Time :
                        </p>
                        <Field
                          value={values.flr_breach_countdown_timer_window_time}
                          name="flr_breach_countdown_timer_window_time"
                          component={MuiTimePicker}
                          views={["hours", "minutes", "seconds"]}
                          format={"HH:mm:ss"}
                          width="100%"
                          size="small"
                        />
                      </Grid>
                    )}

                    {/* AUTO RESPONSE */}
                    <Grid
                      mt={4.5}
                      item
                      // container
                      textAlign="center"
                      justifyContent={"center"}
                    >
                      <FormControlLabel
                        control={<Checkbox checked={values?.auto_response} />}
                        label="Auto Response"
                        name="auto_response"
                        value={values?.auto_response}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item lg={2} sm={2} md={2} mt={4.5}>
                      <MuiButton type="submit" name={"Submit"} width="100%" />
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>

            <br />
            <Typography variant="h3">Business Hours</Typography>

            <Grid display="flex" container item lg={12} md={12} sm={12}>
              <Grid item lg={8} md={12} sm={12} xs={12}>
                <AddBusinessHours
                  business_hours_list={
                    business_hours_list && business_hours_list
                  }
                  PutId={PutId}
                />
              </Grid>
            </Grid>
          </div>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "80vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingBgImage />
          </Box>
        )}
      </LoadingIndicator>
    </Box>
  );
};

export default connect(mapStateToProps)(MiscellaneousSettings);
