import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import MainNav from "../components/main-nav/MainNav";
import { useDispatch } from "react-redux";
import Toasts from "../components/toasts/Toasts";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AuthAction from "../stores/Auth/AuthAction";
import "./App.css";
import HttpErrorResponseModel from "../models/HttpErrorResponseModel";
//CUSTOM THEME CREATION
export const theme = createTheme({
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          border: "1px solid #2a5e6c",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#2a5e6c",
      contrastText: "#e5e2e2",
      dark: "#000000",
      light: "#9dc0c9",
      changeIcon: "#F44336",
    },
    secondary: {
      main: "#e93017",
      light: "#f79f8a",
    },
    common: {
      grey: "#d9d9d9",
    },
    info: {
      main: "#4e5c5e",
    },
    appbar: {
      icons: "#a0bfc9",
    },

    sentimentIconColor: {
      positive: "#367989",
      neutral: "#1976d2",
      negative: "#e64b25",
    },

    text: {
      primary: "#2a5e6c",
      light: "#9dc0c9",
      dark: "#707070",
      gray: "#bab8b8",
      ticketContent: "#484848",
      first_letter: "#d8aa4f",
      error: "#f26d4e",
    },
    divider: "#2a5e6c",

    borderColor: {
      textField: "#a0bfc9",
      imageBox: "#e7eef0",
      uploadButtons: "#6bc7ce",
      uploadAssetButton: "#bebec0",
    },

    background: {
      main: "#2a5e6c",
      primary: "#d3e2e6",
      default: "#f3f9fb",
      paper: "#fff",
      button: "#d6ebf0",
      comman: "#797676",
      border: "#e5e5ea",
      light: "#d8eaef",
      error: "#fcd3ca",
    },

    ticketListView: {
      iconColor: "#86aeb6",
      headerIcon: "#003a4c",
      scroller: "#85bac6",
      checkbox: "#39717c",
      sortPopperBorder: " #e4ecef",
    },

    // Datagrid
    datagrid: {
      background: {
        datagridCell: "#ffffff",
        datagridHeader: "#d3e2e6",
        hover: "#eeeeee",
        duplicate: "#dbe7eb",
      },
      Columns: {
        fontSize: 17,
      },
    },
    calendar: {
      background: {
        header: "#d0dfe3",
        body: "#f3f9fb",
      },
    },

    // post button
    // addButton: {
    //   height: "40px",
    //   width: "30px",
    //   borderRadius: "55%",
    // },

    addButton: {
      height: "40px",
      width: "40px",
      borderRadius: "55%",
    },

    //Button Component

    buttonComponent: {
      background: {
        primary: "#57828e",
        success: "#35C37D",
        error: "#ff4444",
      },
      hover: {
        primary: "#0b2e36",
        success: "#035029",
        error: "#CC0000",
      },
      color: "#d6ebf0",
      border: "solid 1px #eaeaea",
      borderRadius: {
        small: "5px",
        medium: "10px",
        large: "12px",
      },
      fontSize: {
        small: "10px",
        medium: "12px",
        large: "14px",
        extraLarge: "16px",
      },
      paddingX: {
        small: "10px",
        medium: "20px",
        large: "30px",
      },
      width: {
        small: "100px",
        medium: "150px",
        large: "200px",
      },
      // borderRadius: {
      //   small: "8px",
      //   medium: "16px",
      // },
    },
    dashboard: {
      red: "#f79f8a",
      yellow: "#ffe203",
      green: "#29c060",
      lightGreen: "#06f234",
      timeText: "#bfbfbf",
      lightTeal: "#a0bfc9",
      icons: "#899da2",
    },
  },

  pieChart: {
    color1: "#fc8d72",
    color2: "#22bcba",
    color3: "#cae5e1",
    color4: "#b8c7c0",
    color5: "#41808e",
    color6: "#a69bac",
  },

  platform: {
    color1: "#CCCCCC",
    color2: "#A4D9D1",
    color3: "#CAE5E1",
    color4: "#9BB2AE",
    color5: "#E2E2E2",
    color6: "#6BA5B2",
    color7: "#42808E",
    color8: "#468C9D",
    color9: "#9DBFD4",
    color10: "#468C9D",
    color11: "#92C4D0",
    color12: "#B4D5DD",
    color13: "#D8EAEF",
    color14: "#FCE5E0",
    color15: "#FCD3CA",
    color16: "#FABBAC",
    color17: "#0b394a",
    color18: "#676869",
    color19: "#F3F8F9",
    color20: "#A2C8CF",
  },

  table: {
    header: "#e4ecef",
    rowColor: "#eff4f6",
    rowText: "#39717c",
    border: "#e4ecef",
    borderRadius: 0,
    layoutRadius: 2,
  },

  reports: {
    positive: "#468c9d",
    neutral: "#92c4d0",
    negative: "#E64B25",
    total: "#2a5e6c",
    color1: "#E2E2E2",
    color2: "#CAE5E1",
    color3: "#FABBAC",
    color4: "#B4D5DD",
    color5: "#468C9D",
    color6: "#A4D9D1",
    color7: "#42808E",
    headerSize: "14px",
    gridBorder: "#e5e2e2",
    headerPadding: "5px 10px",
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1200,
    },
  },

  typography: {
    fontFamily: "BookAntiqua",
    fontWeightRegular: "normal",
    fontSize: "14px",
    fontWeightBold: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    color: "#2a5e6c",
    h1: {
      fontSize: "32px",
      fontFamily: "BookAntiqua",
      color: "#2a5e6c",
    },
    h2: {
      fontSize: "28px",
      fontFamily: "BookAntiqua",
      color: "#2a5e6c",
    },
    h5: {
      fontFamily: "BookAntiqua",
      color: "#2a5e6c",
    },
    h6: {
      fontFamily: "BookAntiqua",
      color: "#2a5e6c",
    },

    h3: {
      fontFamily: "BookAntiqua",
      color: "#2a5e6c",
    },

    h4: {
      fontFamily: "BookAntiqua",
      color: "#2a5e6c",
    },

    b: {
      fontFamily: "BookAntiqua",
      color: "#2a5e6c",
    },

    button: {
      fontWeight: 500,
      fontSize: "0.875rem",
      textTransform: "uppercase",
      border: "solid 1px #eaeaea",
      borderRadius: "3px",
      color: "#d6ebf0",
    },
    subtitle1: {
      fontFamily: "BookAntiqua",
      fontSize: "18px",
    },
    appIcon: {
      width: 18,
      height: 18,
    },
    body1: {
      fontSize: "14px",
    },
    padding: 0,
  },
  shadows: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
  customer_popover_shadows: `0 3px 2px 0 rgba(0, 0, 0, 0.16)`,
  shape: { borderRadius: 3 },
  border: "1px solid #f2f2f2",
  borderRadius: "8px",
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          ":-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px white inset",
            backgroundColor: "red !important",
          },
        },
      },
    },

    // Name of the component
    MuiDataGrid: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
      cell: {
        background: "red !important",
        content: "hello!",
        padding: 0,
      },
    },

    //Mui Form control style overrides

    MuiFormControl: {
      styleOverrides: {
        fullWidth: true,
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        root: {
          ".MuiOutlinedInput-root": {
            outline: "none",
            backgroundColor: "#fff",
            fontSize: "14px",
            borderRadius: "5px",
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #2a5e6c",
              outline: "none",
            },
          },
          ".MuiAutocomplete-tagSizeMedium": {
            height: "fit-content",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          outline: "solid 1px #a0bfc9",
          backgroundColor: "#fff",
          fontSize: "14px",
          borderRadius: "5px",
          // ".MuiSelect-select":{
          //   border:"1.5px solid 2a5e6c"
          // }
        },
      },
    },
  },
});

export default function App() {
  const dispatch = useDispatch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const init = async () => {
    await dispatch(AuthAction.InitLogin()).then((model) => {
      const isError = model instanceof HttpErrorResponseModel;
    });
  };

  useEffect(() => {
    document.title = process.env.REACT_APP_TITLE || "No Title";

    init();
  }, [init]);

  const [online, setOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setOnline(true);
    const handleOffline = () => setOnline(false);
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [navigator.onLine]);

  return (
    <div className="app">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <MainNav />
          <Toasts />
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}
