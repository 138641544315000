import { theme } from "../../../../views/App";

export const formatName = (name, fontWeight) => {
  const words = name.split(" ");

  const formattedWords = words.map((word, index) => (
    <span
      key={index}
      style={{ fontWeight: fontWeight ? fontWeight : "normal" }}
    >
      <span style={{ color: theme.palette.text.first_letter }}>
        {word.charAt(0)}
      </span>
      <span style={{ color: theme.typography.h1.color }}>{word.slice(1)} </span>
    </span>
  ));
  return formattedWords;
};
