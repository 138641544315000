import React, { useState, useEffect, useRef } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import environment from "environment";
import FacebookIcon from "@mui/icons-material/Facebook";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import TelegramIcon from "@mui/icons-material/Telegram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import AppleIcon from "@mui/icons-material/Apple";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import GoogleIcon from "@mui/icons-material/Google";
import HelpIcon from "@mui/icons-material/Help";
import "./AddAccount.css";
import DeleteIcon from "@mui/icons-material/Delete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAmilia,
  faDiscord,
  faFacebookF,
  faGooglePlay,
  faXTwitter,
  faQuora,
  faThreads,
} from "@fortawesome/free-brands-svg-icons";
import { faAppStore } from "@fortawesome/free-brands-svg-icons";
import AddGlassdoorAccount from "./AddGlassdoorAccount";
import CropPortraitIcon from "@mui/icons-material/CropPortrait";
import AddWhatsappAccount from "./AddWhatsappAccount";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Avatar,
  useMediaQuery,
} from "@mui/material";
import {
  returnLogo,
  returnPlatformDropdownLogos,
  returnPlatformLogo,
} from "../../../components/ticketPartials";
import AddTelegramAccount from "./AddTelegramAccount";
import EditIcon from "@mui/icons-material/Edit";
import PlaystoreAccountAdditionals from "./PlaystoreAccountAdditionals";
import AddConsumerAccount from "./AddConsumerAccount";
import AddEmailAccount from "./AddEmailAccount";
import AddAppStoreAccount from "./AddAppStoreAccount";
import AddAmbitionBoxAccount from "./AddAmbitionBoxAccount";
import CallIcon from "@mui/icons-material/Call";
import AddIndeedAccount from "./AddIndeedAccount";
import AddRedditAccount from "./AddRedditAccount";
import RedditIcon from "@mui/icons-material/Reddit";
import AddQuoraAccount from "./AddQuoraAccount";
import PinterestIcon from "@mui/icons-material/Pinterest";
import AddTrustpilotAccount from "./AddTrustpilotAccount";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import ProjectAction from "../../stores/project/projectAction";
import GoogleAccountAction from "../../stores/googleAccount/GoogleAccountAction";
import YoutubeAccountAction from "../../stores/youtubeAccount/YoutubeAccountAction";
import InstagramAccountAction from "../../stores/InstagramAccount/InstagramAccountAction";
import EmailAccountAction from "../../stores/emailAccount/EmailAccountAction";
import PlatformAccountsAction from "../../stores/platformAccounts/PlatformAccountsAction";
import PlatformAction from "../../stores/platform/PlatformAction";
import AddAccountAction from "../../stores/addAccount/AddAccountAction";
import FaceBookAccountAction from "../../stores/faceBookAccount/FaceBookAccountAction";
import TelegramAccountAction from "../../stores/telegramAccount/TelegramAccountAction";
import WhatsappAccountAction from "../../stores/whatsappAccount/WhatsappAccountAction";
import AppStoreAddAccountAction from "../../stores/appstoreAddAccount/AppStoreAddAccountAction";
import GlassdoorAccountAction from "../../stores/glassdoorAccount/GlassdoorAccountAction";
import AmbitionBoxAccountAction from "../../stores/ambitionBoxAccount/AmbitionBoxAccountAction";
import GMBAccountAction from "../../stores/gmb_Account/GMBAccountAction";
import LinkedInAccountAction from "../../stores/linkedIn_account/LinkedInAccountAction";
import ConsumerAccountAction from "../../stores/consumer_account/ConsumerAccountAction";
import IndeedAccountAction from "../../stores/indeedAccount/IndeedAccountAction";
import TrustpilotAccountAction from "../../stores/ticketWorkedOn/trustPilotAccount/TrustpilotAccountAction";
import RedditAccountAction from "../../stores/ticketWorkedOn/redditAccount/RedditAccountAction";
import QuoraAccountAction from "../../stores/quoraAccount/QuoraAccountAction";
import DiscordAccountAction from "../../stores/discordAccount/DiscordAccountAction";
import PinterestAccountAction from "../../stores/ticketWorkedOn/pinterestAccount/PinterestAccountAction";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import AutoCompleteComponent from "../../../components/autoCompleteComponent/Autocomplete";
import MuiButton from "../../../components/muiButton/MuiButton";
import SwitchComponent from "../../../components/switchComponent/SwitchComponent";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import AddThreadsAccount from "./AddThreadsAccount";
import ThreadsAccountAction from "../../stores/threadsAccount/ThreadsAccountAction";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import { theme } from "../../../views/App";
import ManualSwitchButton from "../../../components/switchComponent/ManualSwitchButton";

let mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    ProjectAction.REQUEST_PROJECT,
    GoogleAccountAction.REQUEST_PUT_GOOGLE_ACCOUNT,
    YoutubeAccountAction.REQUEST_PUT_YOUTUBE_ACCOUNT,
    InstagramAccountAction.REQUEST_PUT_INSTAGRAM_ACCOUNT,
    EmailAccountAction.REQUEST_PUT_EMAIL_ACCOUNT,
    PlatformAccountsAction.REQUEST_PLATFORM_ACCOUNT,
    PlatformAccountsAction.REQUEST_PLATFORM_ACCOUNT_FILTER,
    PlatformAction.REQUEST_PLATFORM,
  ]),
  addAccount: state.addAccount.addAccount || [],
  projectfilter: state.projectfilter.projectfilter || [],
  platformAccount: state.platformAccount.platformAccount || [],
  platformAccountFilter:
    state.platformAccountFilter.platformAccountFilter || [],
  platform: state.platform.platform || [],
});

const useStyles = makeStyles({
  card: {
    // marginTop: "20px",
    width: "315px",
    height: "300px",
    boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
    border: "solid 0.5px #d3e2e6",
    borderRadius: "6px",
    // marginRight: "25px",
    overflow: "auto",
  },
  avatar: {
    width: "auto",
    height: "auto",
  },
});

// add account details
const AddAccount = (props) => {
  const { isRequesting } = props;

  let projectList = [];

  // useState Functions
  const [accountName, setAccountName] = useState(null);
  const [status, setStatus] = useState(null);
  const [add_account, setAdd_account] = useState(null);
  // const [project, setProject] = useState(null);
  const [open, setOpen] = useState(false);
  const [openAddAppstore, setOpenAddAppstore] = useState(false);
  const [openAddThreads, setopenAddThreads] = useState(false);

  const [openAddGlassdoor, setOpenAddGlassdoor] = useState(false);
  const [openAddAmbitionBox, setOpenAddAmbitionBox] = useState(false);
  const [openWhatsapp, setOpenWhatsapp] = useState(false);
  const [openConsumer, setOpenConsumer] = useState(false);
  const [openIndeed, setOpenIndeed] = useState(false);
  const [openTrustpilot, setOpenTrustpilot] = useState(false);

  const [openReddit, setOpenReddit] = useState(false);
  const [openQuora, setOpenQuora] = useState(false);

  const [openTelegram, setOpenTelegram] = useState(false);

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [deleteProps, setDeleteProps] = useState({});
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);
  const [PlaystoreAccount, setPlaystoreAccount] = useState(false);
  const [PlaystoreAccountData, setPlaystoreAccountData] = useState(null);
  const [consumerAccountData, setConsumerAccountData] = useState(null);
  const [redditAccountData, setRedditAccountData] = useState(null);
  const [quoraAccountData, setQuoraAccountData] = useState(null);

  const [checked, setChecked] = useState(false);
  const handleChange = () => {
    setChecked(!checked);
  };

  if (props.projectfilter.hits) {
    props.projectfilter.hits.hits.forEach((value) => {
      projectList.push(value._source.project_name);
    });
  }

  const project_array = [];

  if (props.projectfilter.hits) {
    props.projectfilter.hits.hits.forEach((value) => {
      project_array.push({
        project_id: value._id,
        project_name: value._source.project_name,
      });
    });
  }

  let uniqueList = [];

  let platformAccountsList = [];

  if (props.platformAccountFilter.hits) {
    props.platformAccountFilter.hits.hits.forEach((val) => {
      platformAccountsList.push(val._source.account_name);
      if (val._source.account_name) {
        uniqueList.push(val._source.account_name);
      }
      if (val._source.app_name) {
        uniqueList.push(val._source.app_name);
      }
    });
  }
  uniqueList = [...new Set(uniqueList)];

  uniqueList.sort((a, b) =>
    typeof a === "string" ? a.localeCompare(b) : null
  );
  uniqueList = [...new Set(uniqueList)];

  function fetchProjectFilter(filter_params) {
    props.dispatch(ProjectAction.requestProjectFilter(filter_params));
  }

  function fetchPlatformAccounts(params) {
    props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
  }

  function fetchPlatformAccountFilter(params) {
    props.dispatch(PlatformAccountsAction.requestPlatformAccountFilter(params));
  }

  function fetchOrgPlatforms() {
    let param = {
      //   level: "organization",
      page_limit: "none",
      order_by: `[["platform_name.keyword","asc"]]`,
    };
    props.dispatch(PlatformAction.requestPlatform(param));
  }

  // useEffect Function
  useEffect(() => {
    let params = {
      page_limit: "none",
      order_by: '[["created_on", "desc"]]',
    };
    let account_params = {
      page_limit: "none",
      // order_by: `[["account_name.keyword", "asc"]]`,
    };

    // if (
    //   props?.projectfilter === undefined ||
    //   props?.projectfilter.length === 0
    // ) {
    fetchProjectFilter(params);
    // }

    // if (
    //   props?.platformAccountFilter === undefined ||
    //   props?.platformAccountFilter.length === 0
    // ) {
    fetchPlatformAccountFilter(account_params);
    // }

    fetchPlatformAccounts(params);
    fetchOrgPlatforms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isRequesting) {
      setFilterLoader(false);
      setClearLoader(false);
    }
  }, [isRequesting]);

  // put to model Functions
  // twitter Account
  const handleEnableUser = (boolVal, id) => {
    let params = {
      order_by: '[["created_on", "desc"]]',
      page_number: 0,
      page_limit: "none",
    };

    let values = {
      enabled: boolVal === true ? false : true,
    };
    props
      .dispatch(
        AddAccountAction.requestPutAddAccount(JSON.stringify(values), id)
      )
      .then(() => {
        // props.dispatch(AddAccountAction.requestAddAccount(params));
        props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
      });
  };

  // const handleDeleteTwitterUser = (id) => {
  //   if (deleteMethod) {
  //     let params = {
  //       page_limit: "none",
  //     };
  //     props.dispatch(AddAccountAction.requestDeleteAddAccount(id)).then(() => {
  //       props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
  //       // setOpenConfirmation(false);
  //       // setDeleteMethod(false);
  //     });
  //   }
  // };

  // delete function after confirming

  function DeleteAccount() {
    let params = {
      page_limit: "none",
      order_by: '[["created_on", "desc"]]',
    };
    // Twitter Delete Method
    if (deleteProps.Platform === "Twitter") {
      props
        .dispatch(AddAccountAction.requestDeleteAddAccount(deleteProps.Id))
        .then(() => {
          props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
        });
    }
    // Youtube Delete Method

    if (deleteProps.Platform === "Youtube") {
      props
        .dispatch(
          YoutubeAccountAction.requestDeleteYoutubeAccount(deleteProps.Id)
        )
        .then(() => {
          props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
        });
    }
    // Facebook Delete Method
    if (deleteProps.Platform === "Facebook") {
      props
        .dispatch(
          FaceBookAccountAction.requestDeleteFaceBookAccount(deleteProps.Id)
        )
        .then(() => {
          props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
        });
    }

    // Instagram Delete Method
    if (deleteProps.Platform === "Instagram") {
      props
        .dispatch(
          InstagramAccountAction.requestDeleteInstagramAccount(deleteProps.Id)
        )
        .then(() => {
          props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
        });
    }

    // Email Delete Method
    if (deleteProps.Platform === "Email") {
      props
        .dispatch(EmailAccountAction.requestDeleteEmailAccount(deleteProps.Id))
        .then(() => {
          props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
        });
    }

    // Telegram delete method
    if (deleteProps.Platform === "Telegram") {
      props
        .dispatch(
          TelegramAccountAction.requestDeleteTelegramAccount(deleteProps.Id)
        )
        .then(() => {
          props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
        });
    }

    // Whatsapp delete method
    if (deleteProps.Platform === "Whatsapp") {
      props
        .dispatch(
          WhatsappAccountAction.requestDeleteWhatsappAccount(deleteProps.Id)
        )
        .then(() => {
          props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
        });
    }

    // Appstore delete method
    if (deleteProps.Platform === "Appstore") {
      props
        .dispatch(
          AppStoreAddAccountAction.requestDeleteAppstoreAccount(deleteProps.Id)
        )
        .then(() => {
          props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
        });
    }

    // GlassDoor delete methods
    if (deleteProps.Platform === "Glassdoor") {
      props
        .dispatch(
          GlassdoorAccountAction.requestDeleteGlassdoorAccount(deleteProps.Id)
        )
        .then(() => {
          props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
        });
    }

    // Ambition box delete method
    if (deleteProps.Platform === "Ambition Box") {
      props
        .dispatch(
          AmbitionBoxAccountAction.requestDeleteAmbitionBoxAccount(
            deleteProps.Id
          )
        )
        .then(() => {
          props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
        });
    }

    if (deleteProps.Platform === "Playstore") {
      props
        .dispatch(
          GoogleAccountAction.requestDeleteGoogleAccount(deleteProps.Id)
        )
        .then(() => {
          props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
        });
    }

    if (deleteProps.Platform === "GMB") {
      props
        .dispatch(GMBAccountAction.requestDeleteGMBAccount(deleteProps.Id))
        .then(() => {
          props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
        });
    }

    if (deleteProps.Platform === "LinkedIn") {
      props
        .dispatch(
          LinkedInAccountAction.requestDeleteLinkedInAccount(deleteProps.Id)
        )
        .then(() => {
          props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
        });
    }

    if (deleteProps.Platform === "Consumer Complaints") {
      props
        .dispatch(
          ConsumerAccountAction.requestDeleteConsumerAccount(deleteProps.Id)
        )
        .then(() => {
          props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
        });
    }

    if (deleteProps.Platform === "Indeed") {
      props
        .dispatch(
          IndeedAccountAction.requestDeleteIndeedAccount(deleteProps.Id)
        )
        .then(() => {
          props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
        });
    }

    if (deleteProps.Platform === "Threads") {
      props
        .dispatch(
          ThreadsAccountAction.requestDeleteThreadsAccount(deleteProps.Id)
        )
        .then(() => {
          props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
        });
    }

    if (deleteProps.Platform === "Trustpilot") {
      props
        .dispatch(
          TrustpilotAccountAction.requestDeleteTrustpilotAccount(deleteProps.Id)
        )
        .then(() => {
          props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
        });
    }

    if (deleteProps.Platform === "Reddit") {
      props
        .dispatch(
          RedditAccountAction.requestDeleteRedditAccount(deleteProps.Id)
        )
        .then(() => {
          props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
        });
    }

    if (deleteProps.Platform === "Quora") {
      props
        .dispatch(QuoraAccountAction.requestDeleteQuoraAccount(deleteProps.Id))
        .then(() => {
          props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
        });
    }

    if (deleteProps.Platform === "Discord") {
      props
        .dispatch(
          DiscordAccountAction.requestDeleteDiscordAccount(deleteProps.Id)
        )
        .then(() => {
          props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
        });
    }

    if (deleteProps.Platform === "Pinterest") {
      props
        .dispatch(
          PinterestAccountAction.requestDeletePinterestAccount(deleteProps.Id)
        )
        .then(() => {
          props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
        });
    }

    setOpenConfirmation(false);
  }

  // playstore ? google Account
  const handleEnableGoogleUser = (boolVal, id) => {
    let params = {
      order_by: '[["created_on", "desc"]]',
      page_number: 0,
      page_limit: "none",
    };

    let values = {
      enabled: boolVal === true ? false : true,
    };
    props
      .dispatch(
        GoogleAccountAction.requestPutGoogleAccount(JSON.stringify(values), id)
      )
      .then(() => {
        props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
      });
  };

  // youtube Account
  const handleEnableYoutubeUser = (boolVal, id) => {
    let params = {
      order_by: '[["created_on", "desc"]]',
      page_number: 0,
      page_limit: "none",
    };

    let values = {
      enabled: boolVal === true ? false : true,
    };
    props
      .dispatch(
        YoutubeAccountAction.requestPutYoutubeAccount(
          JSON.stringify(values),
          id
        )
      )
      .then(() => {
        props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
      });
  };

  // facebook Account
  const handleEnableFacebookUser = (boolVal, id) => {
    let params = {
      order_by: '[["created_on", "desc"]]',
      page_number: 0,
      page_limit: "none",
    };

    let values = {
      enabled: boolVal === true ? false : true,
    };
    props
      .dispatch(
        FaceBookAccountAction.requestPutFaceBookAccount(
          JSON.stringify(values),
          id
        )
      )
      .then(() => {
        props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
      });
  };

  // instagram account
  const handleEnableInstagramUser = (boolVal, id) => {
    let params = {
      order_by: '[["created_on", "desc"]]',
      page_number: 0,
      page_limit: "none",
    };

    let values = {
      enabled: boolVal === true ? false : true,
    };
    props
      .dispatch(
        InstagramAccountAction.requestPutInstagramAccount(
          JSON.stringify(values),
          id
        )
      )
      .then(() => {
        props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
      });
  };

  // Email account
  const handleEnableEmailUser = (boolVal, id) => {
    let params = {
      order_by: '[["created_on", "desc"]]',
      page_number: 0,
      page_limit: "none",
    };

    let values = {
      enabled: boolVal === true ? false : true,
    };
    props
      .dispatch(
        EmailAccountAction.requestPutEmailAccount(JSON.stringify(values), id)
      )
      .then(() => {
        props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
      });
  };

  // Whatsapp account
  const handleEnableWhatsappUser = (boolVal, id) => {
    let params = {
      // order_by: '[["created_on", "desc"]]',
      // page_number: 0,
      page_limit: "none",
    };

    let values = {
      enabled: boolVal === true ? false : true,
    };
    props
      .dispatch(
        WhatsappAccountAction.requestPutWhatsappAccount(
          JSON.stringify(values),
          id
        )
      )
      .then(() => {
        props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
      });
  };

  //Appstore Account
  const handleAppstoreEnableApp = (bool, id) => {
    let params = {
      order_by: '[["created_on", "desc"]]',
      page_number: 0,
      page_limit: "none",
    };
    let values = {
      enabled: bool === true ? false : true,
    };

    props
      .dispatch(
        AppStoreAddAccountAction.requestPutAppstoreAccount(
          JSON.stringify(values),
          id
        )
      )
      .then(() => {
        props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
      });
  };

  //Glassdoor Account
  const handleEnableGlassdoorAccount = (bool, id) => {
    let params = {
      order_by: '[["created_on", "desc"]]',
      page_number: 0,
      page_limit: "none",
    };
    let values = {
      enabled: bool === true ? false : true,
    };

    props
      .dispatch(
        GlassdoorAccountAction.requestPutGlassdoorAccount(
          JSON.stringify(values),
          id
        )
      )
      .then(() => {
        props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
      });
  };

  //Glassdoor Account
  const handleEnableTelegramAccount = (bool, id) => {
    let params = {
      order_by: '[["created_on", "desc"]]',
      page_number: 0,
      page_limit: "none",
    };
    let values = {
      enabled: bool === true ? false : true,
    };

    props
      .dispatch(
        TelegramAccountAction.requestPutTelegramAccount(
          JSON.stringify(values),
          id
        )
      )
      .then(() => {
        props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
      });
  };

  //AmbitionBox Account
  const handleEnableAmbitionBoxAccount = (bool, id) => {
    let params = {
      order_by: '[["created_on", "desc"]]',
      page_number: 0,
      page_limit: "none",
    };
    let values = {
      enabled: bool === true ? false : true,
    };

    props
      .dispatch(
        AmbitionBoxAccountAction.requestPutAmbitionBoxAccount(
          JSON.stringify(values),
          id
        )
      )
      .then(() => {
        props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
      });
  };

  // GMB account enable handling
  const handleEnableGMBAccount = (bool, id) => {
    let params = {
      order_by: '[["created_on", "desc"]]',
      page_number: 0,
      page_limit: "none",
    };
    let values = {
      enabled: bool === true ? false : true,
    };

    props
      .dispatch(
        GMBAccountAction.requestPutGMBAccount(JSON.stringify(values), id)
      )
      .then(() => {
        props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
      });
  };

  const autoCompleteRef = useRef(null);

  // LinkedIn account enable handling
  const handleEnableLinkedInAccount = (bool, id) => {
    let params = {
      order_by: '[["created_on", "desc"]]',
      page_number: 0,
      page_limit: "none",
    };
    let values = {
      enabled: bool === true ? false : true,
    };

    props
      .dispatch(
        LinkedInAccountAction.requestPutLinkedInAccount(
          JSON.stringify(values),
          id
        )
      )
      .then(() => {
        props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
      });
  };

  // LinkedIn account enable handling
  const handleEnableConsumerAccount = (bool, id) => {
    let params = {
      order_by: '[["created_on", "desc"]]',
      page_number: 0,
      page_limit: "none",
    };
    let values = {
      enabled: bool === true ? false : true,
    };

    props
      .dispatch(
        ConsumerAccountAction.requestPutConsumerAccount(
          JSON.stringify(values),
          id
        )
      )
      .then(() => {
        props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
      });
  };

  // Indeed account enable handling
  const handleEnableIndeedAccount = (bool, id) => {
    let params = {
      order_by: '[["created_on", "desc"]]',
      page_number: 0,
      page_limit: "none",
    };

    let values = {
      enabled: bool === true ? false : true,
    };

    props
      .dispatch(
        IndeedAccountAction.requestPutIndeedAccount(JSON.stringify(values), id)
      )
      .then(() => {
        props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
      });
  };

  // Threads account enable handling
  const handleEnableThreadsAccount = (bool, id) => {
    let params = {
      order_by: '[["created_on", "desc"]]',
      page_number: 0,
      page_limit: "none",
    };

    let values = {
      enabled: bool === true ? false : true,
    };

    props
      .dispatch(
        ThreadsAccountAction.requestPutThreadsAccount(
          JSON.stringify(values),
          id
        )
      )
      .then(() => {
        props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
      });
  };

  // Trustpilot account enable handling
  const handleEnableTrustpilotAccount = (bool, id) => {
    let params = {
      order_by: '[["created_on", "desc"]]',
      page_number: 0,
      page_limit: "none",
    };

    let values = {
      enabled: bool === true ? false : true,
    };

    props
      .dispatch(
        TrustpilotAccountAction.requestPutTrustpilotAccount(
          JSON.stringify(values),
          id
        )
      )
      .then(() => {
        props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
      });
  };

  // Reddit account enable handling
  const handleEnableRedditAccount = (bool, id) => {
    let params = {
      order_by: '[["created_on", "desc"]]',
      page_number: 0,
      page_limit: "none",
    };

    let values = {
      enabled: bool === true ? false : true,
    };

    props
      .dispatch(
        RedditAccountAction.requestPutRedditAccount(JSON.stringify(values), id)
      )
      .then(() => {
        props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
      });
  };

  // Quora account enable handling
  const handleEnableQuoraAccount = (bool, id) => {
    let params = {
      order_by: '[["created_on", "desc"]]',
      page_number: 0,
      page_limit: "none",
    };

    let values = {
      enabled: bool === true ? false : true,
    };

    props
      .dispatch(
        QuoraAccountAction.requestPutQuoraAccount(JSON.stringify(values), id)
      )
      .then(() => {
        props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
      });
  };

  // Discord account enable handling
  const handleEnableDiscordAccount = (bool, id) => {
    let params = {
      order_by: '[["created_on", "desc"]]',
      page_number: 0,
      page_limit: "none",
    };

    let values = {
      enabled: bool === true ? false : true,
    };

    props
      .dispatch(
        DiscordAccountAction.requestPutDiscordAccount(
          JSON.stringify(values),
          id
        )
      )
      .then(() => {
        props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
      });
  };

  // Pinterest account enable handling
  const handleEnablePinterestAccount = (bool, id) => {
    let params = {
      order_by: '[["created_on", "desc"]]',
      page_number: 0,
      page_limit: "none",
    };

    let values = {
      enabled: bool === true ? false : true,
    };

    props
      .dispatch(
        PinterestAccountAction.requestPutPinterestAccount(
          JSON.stringify(values),
          id
        )
      )
      .then(() => {
        props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
      });
  };

  let accountList = [];

  if (props.platform.hits) {
    props.platform.hits.hits.map((item) => {
      return accountList.push(item._source.platform_name);
    });
  }

  accountList.sort((a, b) => a.localeCompare(b));
  accountList = [...new Set(accountList)];

  // status account list
  const statusList = ["Enabled", "Disabled"];

  // twitter_o_auth
  let requestToken = () => {
    axios.get(`${environment.api.baseApi}/request_token`).then((res) => {
      const response = res.data;
      window.location.assign(res.data.url);
    });
  };

  let requestRedditToken = () => {
    axios
      .get(`${environment.api.baseApi}/request_reddit_auth_code`)
      .then((res) => {
        const response = res.data;
        const token = response.url;

        window.location.assign(token);
      });
  };

  // google_o_auth
  let requestGoogleToken = () => {
    axios.get(`${environment.api.baseApi}/google_login`).then((res) => {
      const response = res.data;
      const token = response.url;

      window.location.assign(token);

      // window.open(token, "");
    });
  };

  // youtube_o_auth
  let requestYoutubeToken = () => {
    axios.get(`${environment.api.baseApi}/youtube_login`).then((res) => {
      const response = res.data;

      const token = response.url;
      window.location.assign(token);

      // window.open(token, "");
    });
  };

  // facebook/instagram_o_auth
  let requestFacebokToken = () => {
    axios.get(`${environment.api.baseApi}/facebook_login`).then((res) => {
      const response = res.data;

      const token = response.url;
      window.location.assign(token);

      // window.open(token, "");
    });
  };

  // gmb_o_auth
  let requestGmbToken = () => {
    axios.get(`${environment.api.baseApi}/gmb_login`).then((res) => {
      const response = res.data;
      const token = response.url;
      window.location.assign(token);

      // window.open(token, "");
    });
  };

  // LinkedIn_o_auth
  let requestLinkedInToken = () => {
    axios.get(`${environment.api.baseApi}/linkedin_login`).then((res) => {
      const response = res.data;
      const token = response.url;
      window.location.assign(token);

      // window.open(token, "");
    });
  };

  // discord_o_auth
  let requestDiscordToken = () => {
    axios.get(`${environment.api.baseApi}/discord_auth_request`).then((res) => {
      const response = res.data;

      const token = response.url;
      window.location.assign(token);
    });
  };

  // pinterset_o_auth
  let requestPinterestToken = () => {
    axios.get(`${environment.api.baseApi}/get_auth_url`).then((res) => {
      const response = res.data;

      const token = response.url;
      window.location.assign(token);
    });
  };

  // o_auth Handle Change function
  const addAccountHandleChange = (event, value) => {
    if (value === "Twitter") {
      setAdd_account(value);
      requestToken();
    } else if (value === "Facebook") {
      setAdd_account(value);
      requestFacebokToken();
    } else if (value === "Instagram") {
      setAdd_account(value);
      requestFacebokToken();
    } else if (value === "Playstore") {
      setAdd_account(value);
      requestGoogleToken();
    } else if (value === "Youtube") {
      setAdd_account(value);
      requestYoutubeToken();
    } else if (value === "Email") {
      setAdd_account(value);
      setOpen(true);
    } else if (value === "Appstore") {
      setAdd_account(value);
      setOpenAddAppstore(true);
    } else if (value === "Threads") {
      setAdd_account(value);
      setopenAddThreads(true);
    } else if (value === "Glassdoor") {
      setAdd_account(value);
      setOpenAddGlassdoor(true);
    } else if (value === "Ambition Box") {
      setAdd_account(value);
      setOpenAddAmbitionBox(true);
    } else if (value === "Whatsapp") {
      setAdd_account(value);
      setOpenWhatsapp(true);
    } else if (value === "Telegram") {
      setAdd_account(value);
      setOpenTelegram(true);
    } else if (value === "GMB") {
      setAdd_account(value);
      requestGmbToken();
    } else if (value === "LinkedIn") {
      setAdd_account(value);
      requestLinkedInToken();
    } else if (value === "Consumer Complaints") {
      setAdd_account(value);
      setOpenConsumer(true);
    } else if (value === "Indeed") {
      setAdd_account(value);
      setOpenIndeed(true);
    } else if (value === "Trust Pilot") {
      setAdd_account(value);
      setOpenTrustpilot(true);
    } else if (value === "Reddit") {
      setAdd_account(value);
      requestRedditToken();

      // setOpenReddit(true);
    } else if (value === "Quora") {
      setAdd_account(value);
      setOpenQuora(true);
    } else if (value === "Discord") {
      setAdd_account(value);
      requestDiscordToken();
    } else if (value === "Pinterest") {
      setAdd_account(value);
      requestPinterestToken();
    } else {
      setAdd_account(null);
    }
  };
  // autocomplete handleChange
  // const platformHandleChange = (event, value) => {
  //   setPlatform(value);
  // };

  // accountName Handle Change function
  const AccountNameHandleChange = (event, value) => {
    if (value != null) {
      setAccountName(value);
    } else {
      setAccountName(null);

      let params = {
        page_limit: "none",
        order_by: '[["created_on", "desc"]]',
      };

      let account_params = {
        page_limit: "none",
        // order_by: `[["account_name.keyword", "asc"]]`,
      };

      fetchPlatformAccounts(params);
      fetchPlatformAccountFilter(account_params);
    }
  };

  // status Handle Change function
  const statusHandleChange = (event, value) => {
    if (value === "Enabled") {
      setStatus("Enabled");
    } else if (value === "Disabled") {
      setStatus("Disabled");
    } else {
      setStatus(null);
    }
  };

  // project Handle Change function
  // const projectHandleChange = (event, value) => {
  //   if (value != null) {
  //     setProject(value);
  //   } else {
  //     setProject(null);
  //   }
  // };

  // styles
  const classes = useStyles();

  // clear state
  const ClearState = () => {
    setClearLoader(true);
    setFilterClicked(false);

    if (
      accountName !== null ||
      status !== null
      // || project !== null
    ) {
      setAccountName(null);
      setStatus(null);
      // setProject(null);
    }

    let params = {
      page_limit: "none",
      order_by: '[["created_on", "desc"]]',
    };

    FilterClicked && fetchPlatformAccounts(params);
  };

  const handleFilter = () => {
    setFilterLoader(true);
    setClearLoader(false);

    const filter = [];

    if (status !== null) {
      filter.push(
        `["enabled","must","","match","${status === "Enabled" ? true : false}"]`
      );
    }

    if (accountName !== null) {
      filter.push(
        `["account_name.keyword","must","","match","${accountName}"]`
      );
    }

    if (status !== null || accountName !== null) {
      const params = {
        filters: `[${filter}]`,
        order_by: '[["created_on", "desc"]]',

        // order_by: '[["modified_on", "desc"]]',
      };
      setFilterClicked(true);

      fetchPlatformAccounts(params);
    } else {
      alert("Please Add Filters");
      setClearLoader(false);
    }
  };

  const all_accounts = [];

  const windowHeight = window.innerHeight;

  const isXl = useMediaQuery(() => theme.breakpoints.up("xl"));
  const isLg = useMediaQuery(() => theme.breakpoints.up("lg"));
  const isLaptop = useMediaQuery(() => theme.breakpoints.up("laptop"));
  const isMd = useMediaQuery(() => theme.breakpoints.up("md"));

  const heightOfHeader = isXl
    ? windowHeight * 0.2 // Adjust as needed for xl
    : isLg
    ? windowHeight * 0.28 // Adjust as needed for lg
    : isLaptop
    ? windowHeight * 0.28 // Adjust as needed for md
    : isMd
    ? windowHeight * 0.28 // Adjust as needed for md
    : windowHeight * 0.28; // Default for smaller sizes

  const heightOfDiv = isXl
    ? windowHeight * 0.7 // Adjust as needed for xl
    : isLg
    ? windowHeight * 0.62 // Adjust as needed for lg
    : isLaptop
    ? windowHeight * 0.62 // Adjust as needed for md
    : isMd
    ? windowHeight * 0.65 // Adjust as needed for md
    : windowHeight * 0.65; // Default for smaller sizes

  if (props.platformAccount.hits) {
    props.platformAccount.hits.hits.map((item) => {
      if (
        item._source.platform === "Twitter" ||
        item._source.platform === "Youtube" ||
        item._source.platform === "Instagram" ||
        item._source.platform === "Email"
      ) {
        all_accounts.push({
          _id: item._id,
          platform: item._source.platform,
          account_name: item._source.account_name,
          updated_by: item._source.modified_by
            ? item._source.modified_by.user_name
            : "",
          updated_on: item._source.modified_on,
          enabled: item._source.enabled,
          last_sync_date: item._source.last_sync_date,
          username: item._source.username,
        });
      } else if (item._source.platform === "Playstore") {
        all_accounts.push({
          _id: item._id,
          platform: item._source.platform,
          account_name: item._source.account_name,
          updated_by: item._source.modified_by
            ? item._source.modified_by.user_name
            : "",

          updated_on: item._source.modified_on,
          enabled: item._source.enabled,
          last_sync_date: item._source.last_sync_date,
          apps: item._source.apps,
        });
      } else if (item._source.platform === "Appstore") {
        all_accounts.push({
          _id: item._id,
          platform: item._source.platform,
          app_name: item._source.app_name,
          updated_by: item._source.modified_by
            ? item._source.modified_by.user_name
            : "",

          updated_on: item._source.modified_on,
          enabled: item._source.enabled,
          last_sync_date: item._source.last_sync_date,
        });
      } else if (item._source.platform === "Glassdoor") {
        all_accounts.push({
          _id: item._id,
          platform: item._source.platform,
          company: item._source.account_name,
          updated_by: item._source.modified_by
            ? item._source.modified_by.user_name
            : "",

          updated_on: item._source.modified_on,
          enabled: item._source.enabled,
          last_sync_date: item._source.last_sync_date,
        });
      } else if (item._source.platform === "Ambition Box") {
        all_accounts.push({
          _id: item._id,
          platform: item._source.platform,
          company: item._source.account_name,
          updated_by: item._source.modified_by
            ? item._source.modified_by.user_name
            : "",

          updated_on: item._source.modified_on,
          enabled: item._source.enabled,
          last_sync_date: item._source.last_sync_date,
        });
      } else if (item._source.platform === "Facebook") {
        all_accounts.push({
          _id: item._id,
          platform: item._source.platform,
          account_name: item._source.account_name,
          page_name: item._source.page_name,
          updated_by: item._source.modified_by
            ? item._source.modified_by.user_name
            : "",
          updated_on: item._source.modified_on,
          enabled: item._source.enabled,
          last_sync_date: item._source.last_sync_date,
          page_profile_url: item._source.page_profile_url,
        });
      } else if (item._source.platform === "GMB") {
        all_accounts.push({
          _id: item._id,
          platform: item._source.platform,
          account_name: item._source.account_name,
          updated_by: item._source.modified_by
            ? item._source.modified_by.user_name
            : "",
          updated_on: item._source.modified_on,
          enabled: item._source.enabled,
          last_sync_date: item._source.last_sync_date,
        });
      } else if (item._source.platform === "LinkedIn") {
        all_accounts.push({
          _id: item._id,
          platform: item._source.platform,
          account_name: item._source.account_name,
          page_name: item._source.page_name,
          updated_by: item._source.modified_by
            ? item._source.modified_by.user_name
            : "",
          updated_on: item._source.modified_on,
          enabled: item._source.enabled,
        });
      } else if (item._source.platform === "Consumer Complaints") {
        all_accounts.push({
          _id: item._id,
          platform: item._source.platform,
          account_name: item._source.account_name,
          page_name: item._source.page_name ? item._source.page_name : null,
          last_sync_date: item._source.last_sync_date,
          enabled: item._source.enabled,
          keywords: item._source.keywords,
        });
      } else if (item._source.platform === "Telegram") {
        all_accounts.push({
          _id: item._id,
          platform: item._source.platform,
          account_name: item._source.account_name,
          updated_by: item._source.modified_by
            ? item._source.modified_by.user_name
            : "",
          updated_on: item._source.modified_on,
          enabled: item._source.enabled,
          last_sync_date: item._source.last_sync_date,
          account_number: item._id,
        });
      } else if (
        item._source.platform === "Indeed" ||
        item._source.platform === "Trustpilot"
      ) {
        all_accounts.push({
          _id: item._id,
          platform: item._source.platform,
          account_name: item._source.account_name,
          updated_by: item._source.modified_by
            ? item._source.modified_by.user_name
            : "",
          updated_on: item._source.modified_on ? item._source.modified_on : "",
          enabled: item._source.enabled,
          last_sync_date: item._source.last_sync_date,
        });
      } else if (item._source.platform === "Reddit") {
        all_accounts.push({
          _id: item._id,
          platform: item._source.platform,
          account_name: item._source.account_name,
          updated_by: item._source.modified_by
            ? item._source.modified_by.user_name
            : "",
          updated_on: item._source.modified_on ? item._source.modified_on : "",
          enabled: item._source.enabled,
          last_sync_date: item._source.last_sync_date,
          keywords: item._source.keywords,
        });
      } else if (item._source.platform === "Quora") {
        all_accounts.push({
          _id: item._id,
          platform: item._source.platform,
          account_name: item._source.account_name,
          updated_by: item._source.modified_by
            ? item._source.modified_by.user_name
            : "",
          updated_on: item._source.modified_on ? item._source.modified_on : "",
          enabled: item._source.enabled,
          last_sync_date: item._source.last_sync_date,
          keywords: item._source.keywords,
        });
      } else if (item._source.platform === "Discord") {
        all_accounts.push({
          _id: item._id,
          platform: item._source.platform,
          account_name: item._source.channel_name,
          updated_by: item._source.modified_by
            ? item._source.modified_by.user_name
            : "",
          updated_on: item._source.modified_on ? item._source.modified_on : "",
          enabled: item._source.enabled,
          last_sync_date: item._source.last_sync_date,
          keywords: item._source.keywords,
        });
      } else if (item._source.platform === "Pinterest") {
        all_accounts.push({
          _id: item._id,
          platform: item._source.platform,
          account_name: item._source.account_name,
          account_profile_url: item._source.account_profile_url,
          updated_by: item._source.modified_by
            ? item._source.modified_by.user_name
            : "",
          updated_on: item._source.modified_on ? item._source.modified_on : "",
          enabled: item._source.enabled,
          last_sync_date: item._source.last_sync_date,
        });
      } else if (item._source.platform === "Whatsapp") {
        all_accounts.push({
          _id: item._id,
          platform: item._source.platform,
          account_name: item._source.account_name,
          updated_by: item._source.modified_by
            ? item._source.modified_by.user_name
            : "",
          updated_on: item._source.modified_on,
          enabled: item._source.enabled,
          username: item._source.username,
          whatsapp_number: item._source.whatsapp_number,
        });
      } else if (item._source.platform === "Threads") {
        all_accounts.push({
          _id: item._id,
          platform: item._source.platform,
          last_sync_date: item._source.last_sync_date,

          account_name: item._source.account_name,
          updated_by: item._source.modified_by
            ? item._source.modified_by.user_name
            : "",
          updated_on: item._source.modified_on,
          enabled: item._source.enabled,
          account_id: item._id,
        });
      }
    });
  }

  function srcPlatform(item) {
    if (item.platform === "Twitter") {
      return (
        <FontAwesomeIcon
          icon={faXTwitter}
          style={{ width: "53px", height: "45px" }}
        ></FontAwesomeIcon>
      );
    } else if (item.platform === "Playstore") {
      return (
        <FontAwesomeIcon
          icon={faGooglePlay}
          style={{ width: "53px", height: "45px" }}
        ></FontAwesomeIcon>
      );
    } else if (item.platform === "Youtube") {
      return (
        <YouTubeIcon style={{ width: "53px", height: "45px", color: "red" }} />
      );
    } else if (item.platform === "Facebook") {
      if (item.page_profile_url && item.page_profile_url !== undefined) {
        return (
          <Avatar
            alt="img"
            src={item.page_profile_url}
            className={classes.avatar}
          />
        );
      } else {
        return (
          <FontAwesomeIcon
            icon={faFacebookF}
            style={{ width: "53px", height: "45px" }}
          ></FontAwesomeIcon>
        );
      }
    } else if (item.platform === "Pinterest") {
      if (item.account_profile_url && item.account_profile_url !== undefined) {
        return (
          <Avatar
            alt="img"
            src={item.account_profile_url}
            // className={classes.avatar}
          />
        );
      } else {
        return <PinterestIcon style={{ width: "53px", height: "45px" }} />;
      }
    } else if (item.platform === "Instagram") {
      return <InstagramIcon style={{ width: "53px", height: "45px" }} />;
    } else if (item.platform === "Appstore") {
      return (
        <FontAwesomeIcon
          icon={faAppStore}
          style={{ width: "53px", height: "45px" }}
        ></FontAwesomeIcon>
      );
    } else if (item.platform === "Glassdoor") {
      return <CropPortraitIcon style={{ width: "53px", height: "45px" }} />;
    } else if (item.platform === "Ambition Box") {
      return (
        <FontAwesomeIcon
          icon={faAmilia}
          style={{ width: "53px", height: "35px" }}
        ></FontAwesomeIcon>
      );
    } else if (item.platform === "Threads") {
      return (
        <FontAwesomeIcon
          icon={faThreads}
          style={{ width: "53px", height: "35px" }}
        ></FontAwesomeIcon>
      );
    } else if (item.platform === "Email") {
      return <EmailIcon style={{ width: "53px", height: "45px" }} />;
    } else if (item.platform === "Whatsapp") {
      return <WhatsAppIcon style={{ width: "53px", height: "45px" }} />;
    } else if (item.platform === "GMB") {
      return <GoogleIcon style={{ width: "53px", height: "45px" }} />;
    } else if (item.platform === "LinkedIn") {
      return <LinkedInIcon style={{ width: "53px", height: "45px" }} />;
    } else if (item.platform === "Telegram") {
      return <TelegramIcon style={{ width: "53px", height: "45px" }} />;
    } else if (item.platform === "Consumer Complaints") {
      return <CallIcon style={{ width: "53px", height: "45px" }} />;
    } else if (item.platform === "Indeed") {
      return (
        <span title="Indeed">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            width="53px"
            height="35px"
            fill={theme.palette.primary.main}
          >
            <path d="M 18.5 2 C 10.75 2 6 11.25 6 16 C 6 16 9.25 4 19 4 C 22.625 4 25 6 25 6 C 25 6 24 2 18.5 2 z M 17.5 7 A 3.5 3.5 0 1 0 17.5 14 A 3.5 3.5 0 1 0 17.5 7 z M 20 16 C 20 16 19 17 15 17 L 15 27.5 C 15 28.881 16.119 30 17.5 30 C 18.881 30 20 28.881 20 27.5 L 20 16 z" />
          </svg>
        </span>
      );
    } else if (item.platform === "Trustpilot") {
      return (
        <span title="Trust Pilot">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 22 25"
            width="60px"
            height="40px"
            fill={theme.palette.primary.main} // Replace with the appropriate color value
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M12 16.55l-6.545 3.99 1.555-7.015L2.02 9.25l7.257-.63L12 2l2.723 6.62 7.257.63-5.99 4.26 1.556 7.015L12 16.55zm0 0" />
          </svg>
        </span>
      );
    } else if (item.platform === "Reddit") {
      return <RedditIcon style={{ width: "53px", height: "45px" }} />;
    } else if (item.platform === "Quora") {
      return (
        <FontAwesomeIcon
          icon={faQuora}
          style={{ width: "53px", height: "35px" }}
        ></FontAwesomeIcon>
      );
    } else if (item.platform === "Discord") {
      return (
        <FontAwesomeIcon
          icon={faDiscord}
          style={{ width: "53px", height: "35px" }}
        ></FontAwesomeIcon>
      );
    }
  }

  return (
    <React.Fragment>
      <Box
        style={{
          position: "relative",
        }}
      >
        {/* project post modal screen */}
        <AddEmailAccount
          open={open}
          onSubmit={() => {}}
          onClose={() => setOpen(false)}
          setAdd_account={setAdd_account}
          project_array={project_array}
        />

        <AddAppStoreAccount
          open={openAddAppstore}
          onSubmit={() => {}}
          onClose={() => setOpenAddAppstore(false)}
          setAdd_account={setAdd_account}
          project_array={project_array}
        />

        <AddThreadsAccount
          open={openAddThreads}
          onSubmit={() => {}}
          onClose={() => setopenAddThreads(false)}
          setAdd_account={setAdd_account}
          project_array={project_array}
        />

        <AddGlassdoorAccount
          open={openAddGlassdoor}
          onSubmit={() => {}}
          onClose={() => setOpenAddGlassdoor(false)}
          setAdd_account={setAdd_account}
        />

        <AddWhatsappAccount
          open={openWhatsapp}
          onSubmit={() => {}}
          onClose={() => setOpenWhatsapp(false)}
          setAdd_account={setAdd_account}
          project_array={project_array}
        />

        <AddConsumerAccount
          open={openConsumer}
          onSubmit={() => {}}
          onClose={() => {
            setConsumerAccountData(null);
            setOpenConsumer(false);
          }}
          setAdd_account={setAdd_account}
          consumerAccountData={consumerAccountData}
          project_array={project_array}
        />

        <AddRedditAccount
          open={openReddit}
          onSubmit={() => {}}
          onClose={() => {
            setOpenReddit(false);
            setRedditAccountData(null);
          }}
          setAdd_account={setAdd_account}
          redditAccountData={redditAccountData}
        />

        <AddQuoraAccount
          open={openQuora}
          onSubmit={() => {}}
          onClose={() => {
            setOpenQuora(false);
            setQuoraAccountData(null);
          }}
          setAdd_account={setAdd_account}
          quoraAccountData={quoraAccountData}
        />

        <AddIndeedAccount
          open={openIndeed}
          onSubmit={() => {}}
          onClose={() => {
            setOpenIndeed(false);
          }}
          setAdd_account={setAdd_account}
        />

        <AddTrustpilotAccount
          open={openTrustpilot}
          onSubmit={() => {}}
          onClose={() => {
            setOpenTrustpilot(false);
          }}
          setAdd_account={setAdd_account}
        />

        <AddTelegramAccount
          open={openTelegram}
          onSubmit={() => {}}
          onClose={() => setOpenTelegram(false)}
          setAdd_account={setAdd_account}
          project_array={project_array}
        />

        <PlaystoreAccountAdditionals
          open={PlaystoreAccount}
          onSubmit={() => {}}
          onClose={() => setPlaystoreAccount(false)}
          project_array={project_array}
          PlaystoreAccountData={PlaystoreAccountData}
        />

        <AddAmbitionBoxAccount
          open={openAddAmbitionBox}
          onSubmit={() => {}}
          onClose={() => setOpenAddAmbitionBox(false)}
          setAdd_account={setAdd_account}
        />

        {/* *********heading*******Started******** */}
        <LoadingIndicator isActive={isRequesting}>
          {!isRequesting ? (
            <div
              sx={{
                position: "relative",
              }}
            >
              <Box
                sx={{
                  position: "sticky",
                  top: 65,
                  zIndex: 999,
                  backgroundColor: theme.palette.background.default,
                }}
              >
                {/* *********heading*******Ended******** */}

                {/* *********Filter*******Started******** */}
                <Box
                  sx={{
                    width: "100%",
                    paddingX: 5,
                    paddingY: 1,
                  }}
                >
                  <Grid
                    item
                    container
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    sx={12}
                    xs={12}
                    justifyContent="space-between"
                    alignItems={"center"}
                    // style={{ marginBottom: "20px" }}
                    // className="bg-info"
                  >
                    <Grid
                      item
                      lg={6}
                      md={6}
                      sm={6}
                      sx={6}
                      xs={6}
                      container
                      alignItems={"center"}
                    >
                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        sx={12}
                        xs={12}
                        container
                        justifyContent={"flex-start"}
                        gap={2}
                      >
                        <Grid item xl={3} lg={3} md={4} sm={6} sx={6} xs={6}>
                          {/* Account Name */}
                          <AutoCompleteComponent
                            value={accountName}
                            handleChange={AccountNameHandleChange}
                            list={uniqueList}
                            textFieldProps={{
                              placeholder: "Account Name",
                            }}
                            size="small"
                            width="100%"
                          />
                        </Grid>

                        <Grid item xl={3} lg={3} md={4} sm={8} sx={6} xs={6}>
                          {/* Status */}
                          <AutoCompleteComponent
                            value={status}
                            handleChange={statusHandleChange}
                            list={statusList}
                            textFieldProps={{
                              placeholder: "Status",
                            }}
                            size="small"
                            width="100%"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      alignItems={"center"}
                      justifyContent="flex-end"
                      xl={6}
                      lg={6}
                      md={5}
                      sm={6}
                      // mt={2}
                      gap={2}
                    >
                      <Grid lg={2} md={2} sm={2}>
                        <MuiButton
                          name={"Filter"}
                          loading={filterLoader && isRequesting}
                          onClick={handleFilter}
                          width="100%"
                        />
                      </Grid>
                      <Grid lg={2} md={2} sm={2}>
                        <MuiButton
                          name={"Clear"}
                          width="100%"
                          loading={clearLoader && isRequesting}
                          onClick={ClearState}
                        />
                      </Grid>

                      <Grid lg={3} md={4} sm={4}>
                        <AutoCompleteComponent
                          width="100%"
                          value={add_account}
                          handleChange={addAccountHandleChange}
                          list={accountList}
                          textFieldProps={{
                            placeholder: "Connect Account",
                          }}
                          getOptionDisabled={(option) => {
                            return option === "None";
                          }}
                          size="small"
                          renderOption={(props, option) => {
                            return (
                              <Box
                                component="li"
                                sx={{
                                  "& > img": {
                                    mr: 2,
                                    flexShrink: 0,
                                    maxWidth: "20px",
                                    maxHeight: "20px",
                                  },
                                }}
                                {...props}
                              >
                                <Box width={"auto"} sx={{ mr: "5px" }}>
                                  {returnPlatformDropdownLogos(option)}
                                </Box>
                                {option}
                              </Box>
                            );
                          }}
                          lastComponent
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* <Box
                    style={{}}
                    // className="bg-success"
                  >
                    <Divider
                      sx={{
                        color: theme.palette.divider,
                        borderBottomWidth: 1,
                      }}
                      className="p-0 m-0"
                    />
                  </Box> */}
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    backgroundColor: theme.palette.background.primary,
                    padding: "3px",
                  }}
                >
                  <Typography variant="h5" align="center">
                    Accounts
                  </Typography>
                </Box>

                <Dialog open={openConfirmation} fullWidth maxWidth="sm">
                  <DialogContent>
                    <div style={{ justifyContent: "center", display: "flex" }}>
                      <h5>Are you sure you want to delete this account?</h5>
                    </div>
                  </DialogContent>

                  <DialogActions>
                    <Button
                      size="small"
                      onClick={() => {
                        setOpenConfirmation(false);
                        setDeleteProps({});
                      }}
                    >
                      <ClearIcon />
                    </Button>
                    <Button size="small" onClick={() => DeleteAccount()}>
                      <CheckIcon />
                    </Button>
                  </DialogActions>
                </Dialog>

                {/* *********Filter*******Ended******** */}

                {/* <Grid
                  container
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  sx={12}
                  xs={12}
                  spacing={2}
                  justifyContent="flex-end"
                >
            
                </Grid> */}
              </Box>
              {/* *********************add Account form *********************/}

              <Box
                sx={{
                  position: "relative",
                }}
              >
                {!isRequesting &&
                props.platformAccount.hits &&
                props.platformAccount.hits.hits.length !== 0 ? (
                  <Grid container my={1}>
                    {/* ********************* add Account form *********************/}

                    {/* ********************* list view and add form *********************/}

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      {all_accounts && all_accounts.length !== 0
                        ? all_accounts.map((item, index) => {
                            return (
                              <Box
                                sx={{
                                  margin: 1,
                                }}
                                key="key"
                                data-testid="platformCard"
                              >
                                <Card
                                  className={`${classes.card} scrollable`}
                                  style={{ position: "relative" }}
                                >
                                  <Box
                                    mt={3}
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {/* </Typography> */}
                                    <Box className="avatar_container">
                                      {/* {srcPlatform(item)} */}
                                      {returnPlatformLogo(item?.platform)}
                                    </Box>
                                  </Box>

                                  <CardContent>
                                    <Grid
                                      container
                                      item
                                      xl={12}
                                      lg={12}
                                      sm={12}
                                      sx={12}
                                      xs={12}
                                      mb={1}
                                      textAlign="center"
                                      justifyContent="center"
                                      alignItems="center"
                                    >
                                      {item.company && (
                                        <Grid
                                          item
                                          xl={12}
                                          lg={12}
                                          sm={12}
                                          sx={12}
                                          xs={12}
                                          textAlign="center"
                                        >
                                          <Typography
                                            style={{ fontWeight: "bold" }}
                                            variant="h5"
                                          >
                                            {" "}
                                            Company : {item.company}
                                          </Typography>
                                        </Grid>
                                      )}

                                      {item.app_name && (
                                        <Grid
                                          item
                                          xl={12}
                                          lg={12}
                                          sm={12}
                                          sx={12}
                                          xs={12}
                                          textAlign="center"
                                        >
                                          <Typography
                                            style={{ fontWeight: "bold" }}
                                            variant="h5"
                                          >
                                            {" "}
                                            App Name : {item.app_name}
                                          </Typography>
                                        </Grid>
                                      )}

                                      {item.account_name && (
                                        <Grid
                                          item
                                          xl={12}
                                          lg={12}
                                          sm={12}
                                          sx={12}
                                          xs={12}
                                          textAlign="center"
                                        >
                                          <Typography
                                            style={{ fontWeight: "bold" }}
                                            variant="h5"
                                          >
                                            {item.platform === "Youtube" ||
                                            item.platform === "Discord"
                                              ? "Channel Name"
                                              : item.platform === "Facebook" ||
                                                item.platform === "LinkedIn"
                                              ? "Page Name"
                                              : item.platform === "Whatsapp" ||
                                                item.platform === "Indeed" ||
                                                item.platform === "Pinterest"
                                              ? "Account Name"
                                              : item.platform === "Quora"
                                              ? "Brand Name"
                                              : "Username"}{" "}
                                            :{" "}
                                            {item.platform === "Facebook" ||
                                            item.platform === "LinkedIn"
                                              ? item.page_name
                                              : item.account_name}
                                          </Typography>
                                        </Grid>
                                      )}
                                      {item.whatsapp_number && (
                                        <Grid
                                          item
                                          xl={12}
                                          lg={12}
                                          sm={12}
                                          sx={12}
                                          xs={12}
                                          textAlign="center"
                                        >
                                          <Typography
                                          // style={{ fontWeight: "bold" }}
                                          // variant="h5"
                                          >
                                            WhatsApp number:{" "}
                                            {item.whatsapp_number}
                                          </Typography>
                                        </Grid>
                                      )}

                                      {item.platform === "Threads" &&
                                        item.account_id && (
                                          <Grid
                                            item
                                            xl={12}
                                            lg={12}
                                            sm={12}
                                            sx={12}
                                            xs={12}
                                            textAlign="center"
                                          >
                                            <span
                                              style={{
                                                color: theme.palette.text.dark,
                                              }}
                                            >
                                              Account ID: {item.account_id}
                                            </span>
                                          </Grid>
                                        )}
                                    </Grid>
                                    {item.platform === "Twitter" && (
                                      <Grid
                                        container
                                        item
                                        xl={12}
                                        lg={12}
                                        sm={12}
                                        sx={12}
                                        xs={12}
                                        my={1}
                                        textAlign="center"
                                        justifyContent="center"
                                        alignItems="center"
                                      >
                                        <span
                                          style={{
                                            textAlign: "center",
                                            fontSize: "15px",
                                            color: theme.palette.text.dark,
                                          }}
                                        >
                                          Handle: {"@" + item.account_name}
                                        </span>
                                      </Grid>
                                    )}
                                    {item.platform === "Telegram" && (
                                      <Grid
                                        container
                                        item
                                        xl={12}
                                        lg={12}
                                        sm={12}
                                        sx={12}
                                        xs={12}
                                        my={1}
                                        textAlign="center"
                                        justifyContent="center"
                                        alignItems="center"
                                      >
                                        <span
                                          style={{
                                            textAlign: "center",
                                            fontSize: "15px",
                                            color: theme.palette.text.dark,
                                          }}
                                        >
                                          La Account number:{" "}
                                          {item.account_number}
                                        </span>
                                      </Grid>
                                    )}
                                    {(item.platform === "Facebook" ||
                                      item.platform === "LinkedIn") && (
                                      <Grid
                                        container
                                        item
                                        xl={12}
                                        lg={12}
                                        sm={12}
                                        sx={12}
                                        xs={12}
                                        my={1}
                                        textAlign="center"
                                        justifyContent="center"
                                        alignItems="center"
                                      >
                                        <span
                                          style={{
                                            textAlign: "center",
                                            fontSize: "15px",
                                            color: theme.palette.text.dark,
                                          }}
                                        >
                                          Account Name : {item.account_name}
                                        </span>
                                      </Grid>
                                    )}
                                    {item.platform === "Email" && (
                                      <Grid
                                        container
                                        item
                                        xl={12}
                                        lg={12}
                                        sm={12}
                                        sx={12}
                                        xs={12}
                                        my={1}
                                        textAlign="center"
                                        justifyContent="center"
                                        alignItems="center"
                                      >
                                        <span
                                          style={{
                                            textAlign: "center",
                                            fontSize: "15px",
                                            color: theme.palette.text.dark,
                                          }}
                                        >
                                          Email Id: {item.username}
                                        </span>
                                      </Grid>
                                    )}
                                    {item.modified_by?.user_name && (
                                      <Grid
                                        container
                                        item
                                        xl={12}
                                        lg={12}
                                        sm={12}
                                        sx={12}
                                        xs={12}
                                        my={1}
                                        textAlign="center"
                                        justifyContent="center"
                                        alignItems="center"
                                      >
                                        <span
                                          style={{
                                            textAlign: "center",
                                            fontSize: "15px",
                                            color: theme.palette.text.dark,
                                          }}
                                        >
                                          Updated By :{" "}
                                          {item.modified_by?.user_name}
                                        </span>
                                      </Grid>
                                    )}

                                    {item.modified_on && (
                                      <Grid
                                        container
                                        item
                                        xl={12}
                                        lg={12}
                                        sm={12}
                                        sx={12}
                                        xs={12}
                                        my={1}
                                        textAlign="center"
                                        justifyContent="center"
                                        alignItems="center"
                                      >
                                        <span
                                          style={{
                                            textAlign: "center",
                                            fontSize: "15px",
                                            color: theme.palette.text.dark,
                                          }}
                                        >
                                          Updated On :{" "}
                                          {item.modified_on &&
                                            (function updatedInDate() {
                                              let updated = item.modified_on;
                                              let dateConversion = new Date(
                                                updated
                                              );
                                              dateConversion =
                                                dateConversion.toDateString();

                                              return dateConversion;
                                            })()}
                                        </span>
                                      </Grid>
                                    )}
                                    <Grid
                                      container
                                      item
                                      xl={12}
                                      lg={12}
                                      sm={12}
                                      xs={12}
                                      my={1}
                                      textAlign="center"
                                      // className="bg-warning"
                                      justifyContent="center"
                                      alignItems="center"
                                    >
                                      {item.last_sync_date ? (
                                        <span
                                          style={{
                                            textAlign: "center",
                                            fontSize: "15px",
                                            color: theme.palette.text.dark,
                                          }}
                                        >
                                          Last Sync Date :{" "}
                                          {(function createdDate() {
                                            let created = item.last_sync_date;
                                            let dateConversion = new Date(
                                              created
                                            );
                                            dateConversion =
                                              dateConversion.toDateString() +
                                              ", " +
                                              dateConversion.toLocaleTimeString(
                                                // "en-US",
                                                {
                                                  hour: "numeric",
                                                  minute: "numeric",
                                                  hour12: true,
                                                }
                                              );

                                            return dateConversion;
                                          })()}
                                        </span>
                                      ) : null}
                                    </Grid>
                                  </CardContent>

                                  <CardActions
                                    style={{
                                      position:
                                        item.modified_on &&
                                        item.modified_by?.user_name
                                          ? "relative"
                                          : "absolute",
                                      bottom: 0,
                                      left: 0,
                                      right: 0,
                                    }}
                                  >
                                    <Grid
                                      container
                                      xl={12}
                                      lg={12}
                                      sm={12}
                                      xs={12}
                                      justifyContent="space-between"
                                      alignItems="flex-start"
                                    >
                                      <Grid
                                        item
                                        xl={3}
                                        lg={3}
                                        sm={3}
                                        // sx={3}
                                        xs={3}
                                        textAlign="left"
                                        sx={{
                                          color:
                                            theme.palette.ticketListView
                                              .iconColor,
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            paddingLeft: "5px",
                                            fontSize: "15px",
                                            color:
                                              theme.palette.ticketListView
                                                .iconColor,
                                          }}
                                        >
                                          {item.enabled === true
                                            ? "Active"
                                            : "Disabled"}
                                        </Box>
                                      </Grid>
                                      <Grid
                                        item
                                        // xl={1}
                                        // lg={1}
                                        // sm={1}
                                        // sx={1}
                                        // xs={1}
                                      >
                                        {item.platform === "Twitter" && (
                                          <Box className="add_account_icons">
                                            {/* <SwitchComponent
                                              name="logic"
                                              // checked={
                                              //   item.enabled === true ? true : false
                                              // }
                                              defaultChecked={
                                                item.enabled === true
                                                  ? true
                                                  : false
                                              }
                                              inputProps={{
                                                "aria-label": "controlled",
                                              }}
                                              onClick={() => {
                                                handleEnableUser(
                                                  item.enabled,
                                                  item._id
                                                );
                                              }}
                                            /> */}
                                            <div
                                              style={{
                                                marginRight: "10px",
                                                marginTop: "2px",
                                              }}
                                            >
                                              <ManualSwitchButton
                                                checked={item.enabled}
                                                onChange={(e) => {
                                                  handleEnableUser(
                                                    item.enabled,
                                                    item._id
                                                  );
                                                }}
                                              />
                                            </div>
                                            <DeleteIcon
                                              fontSize="25px"
                                              sx={{ cursor: "pointer" }}
                                              onClick={() => {
                                                setOpenConfirmation(true);
                                                setDeleteProps({
                                                  Id: item._id,
                                                  Platform: item.platform,
                                                });
                                              }}
                                            />
                                          </Box>
                                        )}
                                        {item.platform === "Playstore" && (
                                          <Box className="add_account_icons  p-0 m-0 ">
                                            <div
                                              style={{
                                                marginRight: "10px",
                                                marginTop: "2px",
                                              }}
                                            >
                                              <EditIcon
                                                className="p-0 mr-2"
                                                fontSize="25px"
                                                sx={{ cursor: "pointer" }}
                                                onClick={() => {
                                                  setPlaystoreAccount(true);
                                                  setPlaystoreAccountData(item);
                                                }}
                                              />
                                              <ManualSwitchButton
                                                checked={item.enabled}
                                                onChange={(e) => {
                                                  handleEnableGoogleUser(
                                                    item.enabled,
                                                    item._id
                                                  );
                                                }}
                                              />
                                            </div>
                                            <DeleteIcon
                                              className="p-0 m-0"
                                              fontSize="25px"
                                              sx={{ cursor: "pointer" }}
                                              onClick={() => {
                                                setOpenConfirmation(true);
                                                setDeleteProps({
                                                  Id: item._id,
                                                  Platform: item.platform,
                                                });
                                              }}
                                            />
                                          </Box>
                                        )}
                                        {item.platform === "Youtube" && (
                                          <Box className="add_account_icons">
                                            <div
                                              style={{
                                                marginRight: "10px",
                                                marginTop: "2px",
                                              }}
                                            >
                                              <ManualSwitchButton
                                                checked={item.enabled}
                                                onChange={(e) => {
                                                  handleEnableYoutubeUser(
                                                    item.enabled,
                                                    item._id
                                                  );
                                                }}
                                              />
                                            </div>
                                            <DeleteIcon
                                              fontSize="25px"
                                              sx={{ cursor: "pointer" }}
                                              onClick={() => {
                                                setOpenConfirmation(true);
                                                setDeleteProps({
                                                  Id: item._id,
                                                  Platform: item.platform,
                                                });
                                              }}
                                            />
                                          </Box>
                                        )}
                                        {item.platform === "Facebook" && (
                                          <Box className="add_account_icons">
                                            <div
                                              style={{
                                                marginRight: "10px",
                                                marginTop: "2px",
                                              }}
                                            >
                                              <ManualSwitchButton
                                                checked={item.enabled}
                                                onChange={(e) => {
                                                  handleEnableFacebookUser(
                                                    item.enabled,
                                                    item._id
                                                  );
                                                }}
                                              />
                                            </div>
                                            <DeleteIcon
                                              fontSize="25px"
                                              sx={{ cursor: "pointer" }}
                                              onClick={() => {
                                                setOpenConfirmation(true);
                                                setDeleteProps({
                                                  Id: item._id,
                                                  Platform: item.platform,
                                                });
                                              }}
                                            />
                                          </Box>
                                        )}
                                        {item.platform === "Instagram" && (
                                          <Box className="add_account_icons">
                                            <div
                                              style={{
                                                marginRight: "10px",
                                                marginTop: "2px",
                                              }}
                                            >
                                              <ManualSwitchButton
                                                checked={item.enabled}
                                                onChange={(e) => {
                                                  handleEnableInstagramUser(
                                                    item.enabled,
                                                    item._id
                                                  );
                                                }}
                                              />
                                            </div>
                                            <DeleteIcon
                                              fontSize="25px"
                                              sx={{ cursor: "pointer" }}
                                              onClick={() => {
                                                setOpenConfirmation(true);
                                                setDeleteProps({
                                                  Id: item._id,
                                                  Platform: item.platform,
                                                });
                                              }}
                                            />
                                          </Box>
                                        )}
                                        {item.platform === "Email" && (
                                          <Box className="add_account_icons">
                                            <div
                                              style={{
                                                marginRight: "10px",
                                                marginTop: "2px",
                                              }}
                                            >
                                              <ManualSwitchButton
                                                checked={item.enabled}
                                                onChange={(e) => {
                                                  handleEnableEmailUser(
                                                    item.enabled,
                                                    item._id
                                                  );
                                                }}
                                              />
                                            </div>
                                            <DeleteIcon
                                              fontSize="25px"
                                              sx={{ cursor: "pointer" }}
                                              onClick={() => {
                                                setOpenConfirmation(true);
                                                setDeleteProps({
                                                  Id: item._id,
                                                  Platform: item.platform,
                                                });
                                              }}
                                            />
                                          </Box>
                                        )}
                                        {item.platform === "Whatsapp" && (
                                          <Box className="add_account_icons">
                                            <div
                                              style={{
                                                marginRight: "10px",
                                                marginTop: "2px",
                                              }}
                                            >
                                              <ManualSwitchButton
                                                checked={item.enabled}
                                                onChange={(e) => {
                                                  handleEnableWhatsappUser(
                                                    item.enabled,
                                                    item._id
                                                  );
                                                }}
                                              />
                                            </div>
                                            <DeleteIcon
                                              fontSize="25px"
                                              sx={{ cursor: "pointer" }}
                                              onClick={() => {
                                                setOpenConfirmation(true);
                                                setDeleteProps({
                                                  Id: item._id,
                                                  Platform: item.platform,
                                                });
                                              }}
                                            />
                                          </Box>
                                        )}
                                        {item.platform === "Appstore" && (
                                          <Box className="add_account_icons">
                                            <div
                                              style={{
                                                marginRight: "10px",
                                                marginTop: "2px",
                                              }}
                                            >
                                              <ManualSwitchButton
                                                checked={item.enabled}
                                                onChange={(e) => {
                                                  handleAppstoreEnableApp(
                                                    item.enabled,
                                                    item._id
                                                  );
                                                }}
                                              />
                                            </div>
                                            <DeleteIcon
                                              fontSize="25px"
                                              sx={{ cursor: "pointer" }}
                                              onClick={() => {
                                                setOpenConfirmation(true);
                                                setDeleteProps({
                                                  Id: item._id,
                                                  Platform: item.platform,
                                                });
                                              }}
                                            />
                                          </Box>
                                        )}
                                        {item.platform === "Glassdoor" && (
                                          <Box className="add_account_icons">
                                            <div
                                              style={{
                                                marginRight: "10px",
                                                marginTop: "2px",
                                              }}
                                            >
                                              <ManualSwitchButton
                                                checked={item.enabled}
                                                onChange={(e) => {
                                                  handleEnableGlassdoorAccount(
                                                    item.enabled,
                                                    item._id
                                                  );
                                                }}
                                              />
                                            </div>
                                            <DeleteIcon
                                              fontSize="25px"
                                              sx={{ cursor: "pointer" }}
                                              onClick={() => {
                                                setOpenConfirmation(true);
                                                setDeleteProps({
                                                  Id: item._id,
                                                  Platform: item.platform,
                                                });
                                              }}
                                            />
                                          </Box>
                                        )}
                                        {item.platform === "Telegram" && (
                                          <Box className="add_account_icons">
                                            <div
                                              style={{
                                                marginRight: "10px",
                                                marginTop: "2px",
                                              }}
                                            >
                                              <ManualSwitchButton
                                                checked={item.enabled}
                                                onChange={(e) => {
                                                  handleEnableTelegramAccount(
                                                    item.enabled,
                                                    item._id
                                                  );
                                                }}
                                              />
                                            </div>
                                            <DeleteIcon
                                              fontSize="25px"
                                              sx={{ cursor: "pointer" }}
                                              onClick={() => {
                                                setOpenConfirmation(true);
                                                setDeleteProps({
                                                  Id: item._id,
                                                  Platform: item.platform,
                                                });
                                              }}
                                            />
                                          </Box>
                                        )}
                                        {item.platform === "Ambition Box" && (
                                          <Box className="add_account_icons">
                                            <div
                                              style={{
                                                marginRight: "10px",
                                                marginTop: "2px",
                                              }}
                                            >
                                              <ManualSwitchButton
                                                checked={item.enabled}
                                                onChange={(e) => {
                                                  handleEnableAmbitionBoxAccount(
                                                    item.enabled,
                                                    item._id
                                                  );
                                                }}
                                              />
                                            </div>
                                            <DeleteIcon
                                              fontSize="25px"
                                              sx={{ cursor: "pointer" }}
                                              onClick={() => {
                                                setOpenConfirmation(true);
                                                setDeleteProps({
                                                  Id: item._id,
                                                  Platform: item.platform,
                                                });
                                              }}
                                            />
                                          </Box>
                                        )}
                                        {item.platform === "GMB" && (
                                          <Box className="add_account_icons">
                                            <div
                                              style={{
                                                marginRight: "10px",
                                                marginTop: "2px",
                                              }}
                                            >
                                              <ManualSwitchButton
                                                checked={item.enabled}
                                                onChange={(e) => {
                                                  handleEnableGMBAccount(
                                                    item.enabled,
                                                    item._id
                                                  );
                                                }}
                                              />
                                            </div>
                                            <DeleteIcon
                                              fontSize="25px"
                                              sx={{ cursor: "pointer" }}
                                              onClick={() => {
                                                setOpenConfirmation(true);
                                                setDeleteProps({
                                                  Id: item._id,
                                                  Platform: item.platform,
                                                });
                                              }}
                                            />
                                          </Box>
                                        )}
                                        {item.platform === "LinkedIn" && (
                                          <Box className="add_account_icons">
                                            <div
                                              style={{
                                                marginRight: "10px",
                                                marginTop: "2px",
                                              }}
                                            >
                                              <ManualSwitchButton
                                                checked={item.enabled}
                                                onChange={(e) => {
                                                  handleEnableLinkedInAccount(
                                                    item.enabled,
                                                    item._id
                                                  );
                                                }}
                                              />
                                            </div>
                                            <DeleteIcon
                                              fontSize="25px"
                                              sx={{ cursor: "pointer" }}
                                              onClick={() => {
                                                setOpenConfirmation(true);
                                                setDeleteProps({
                                                  Id: item._id,
                                                  Platform: item.platform,
                                                });
                                              }}
                                            />
                                          </Box>
                                        )}
                                        {item.platform ===
                                          "Consumer Complaints" && (
                                          <Box className="add_account_icons">
                                            <div
                                              style={{
                                                marginRight: "10px",
                                                marginTop: "2px",
                                              }}
                                            >
                                              <EditIcon
                                                className="p-0 mr-2"
                                                fontSize="25px"
                                                sx={{ cursor: "pointer" }}
                                                onClick={() => {
                                                  setOpenConsumer(true);
                                                  setConsumerAccountData(item);
                                                }}
                                              />
                                              <ManualSwitchButton
                                                checked={item.enabled}
                                                onChange={(e) => {
                                                  handleEnableConsumerAccount(
                                                    item.enabled,
                                                    item._id
                                                  );
                                                }}
                                              />
                                            </div>
                                            <DeleteIcon
                                              fontSize="25px"
                                              sx={{ cursor: "pointer" }}
                                              onClick={() => {
                                                setOpenConfirmation(true);
                                                setDeleteProps({
                                                  Id: item._id,
                                                  Platform: item.platform,
                                                });
                                              }}
                                            />
                                          </Box>
                                        )}
                                        {/* INDEED PLATFORM */}
                                        {item.platform === "Indeed" && (
                                          <Box className="add_account_icons">
                                            <div
                                              style={{
                                                marginRight: "10px",
                                                marginTop: "2px",
                                              }}
                                            >
                                              <ManualSwitchButton
                                                checked={item.enabled}
                                                onChange={(e) => {
                                                  handleEnableIndeedAccount(
                                                    item.enabled,
                                                    item._id
                                                  );
                                                }}
                                              />
                                            </div>
                                            <DeleteIcon
                                              fontSize="25px"
                                              sx={{ cursor: "pointer" }}
                                              onClick={() => {
                                                setOpenConfirmation(true);
                                                setDeleteProps({
                                                  Id: item._id,
                                                  Platform: item.platform,
                                                });
                                              }}
                                            />
                                          </Box>
                                        )}

                                        {/* Threads PLATFORM */}
                                        {item.platform === "Threads" && (
                                          <Box className="add_account_icons">
                                            <div
                                              style={{
                                                marginRight: "10px",
                                                marginTop: "2px",
                                              }}
                                            >
                                              <ManualSwitchButton
                                                checked={item.enabled}
                                                onChange={(e) => {
                                                  handleEnableThreadsAccount(
                                                    item.enabled,
                                                    item._id
                                                  );
                                                }}
                                              />
                                            </div>
                                            <DeleteIcon
                                              fontSize="25px"
                                              sx={{ cursor: "pointer" }}
                                              onClick={() => {
                                                setOpenConfirmation(true);
                                                setDeleteProps({
                                                  Id: item._id,
                                                  Platform: item.platform,
                                                });
                                              }}
                                            />
                                          </Box>
                                        )}

                                        {/* Trustpilot PLATFORM */}
                                        {item.platform === "Trustpilot" && (
                                          <Box className="add_account_icons">
                                            <div
                                              style={{
                                                marginRight: "10px",
                                                marginTop: "2px",
                                              }}
                                            >
                                              <ManualSwitchButton
                                                checked={item.enabled}
                                                onChange={(e) => {
                                                  handleEnableTrustpilotAccount(
                                                    item.enabled,
                                                    item._id
                                                  );
                                                }}
                                              />
                                            </div>
                                            <DeleteIcon
                                              fontSize="25px"
                                              sx={{ cursor: "pointer" }}
                                              onClick={() => {
                                                setOpenConfirmation(true);
                                                setDeleteProps({
                                                  Id: item._id,
                                                  Platform: item.platform,
                                                });
                                              }}
                                            />
                                          </Box>
                                        )}
                                        {/* REDDIT PLATFORM */}
                                        {item.platform === "Reddit" && (
                                          <Box className="add_account_icons">
                                            <div
                                              style={{
                                                marginRight: "10px",
                                                marginTop: "2px",
                                              }}
                                            >
                                              <EditIcon
                                                className="p-0 mr-2"
                                                fontSize="25px"
                                                sx={{ cursor: "pointer" }}
                                                onClick={() => {
                                                  setOpenReddit(true);
                                                  setRedditAccountData(item);
                                                }}
                                              />
                                              <ManualSwitchButton
                                                checked={item.enabled}
                                                onChange={(e) => {
                                                  handleEnableRedditAccount(
                                                    item.enabled,
                                                    item._id
                                                  );
                                                }}
                                              />
                                            </div>
                                            <DeleteIcon
                                              fontSize="25px"
                                              sx={{ cursor: "pointer" }}
                                              onClick={() => {
                                                setOpenConfirmation(true);
                                                setDeleteProps({
                                                  Id: item._id,
                                                  Platform: item.platform,
                                                });
                                              }}
                                            />
                                          </Box>
                                        )}
                                        {/* QUORA PLATFORM */}
                                        {item.platform === "Quora" && (
                                          <Box className="add_account_icons">
                                            <div
                                              style={{
                                                marginRight: "10px",
                                                marginTop: "2px",
                                              }}
                                            >
                                              <EditIcon
                                                className="p-0 mr-2"
                                                fontSize="25px"
                                                sx={{ cursor: "pointer" }}
                                                onClick={() => {
                                                  setOpenQuora(true);
                                                  setQuoraAccountData(item);
                                                }}
                                              />
                                              <ManualSwitchButton
                                                checked={item.enabled}
                                                onChange={(e) => {
                                                  handleEnableQuoraAccount(
                                                    item.enabled,
                                                    item._id
                                                  );
                                                }}
                                              />
                                            </div>
                                            <DeleteIcon
                                              fontSize="25px"
                                              sx={{ cursor: "pointer" }}
                                              onClick={() => {
                                                setOpenConfirmation(true);
                                                setDeleteProps({
                                                  Id: item._id,
                                                  Platform: item.platform,
                                                });
                                              }}
                                            />
                                          </Box>
                                        )}
                                        {/* DISCORD PLATFORM */}
                                        {item.platform === "Discord" && (
                                          <Box className="add_account_icons">
                                            <div
                                              style={{
                                                marginRight: "10px",
                                                marginTop: "2px",
                                              }}
                                            >
                                              <ManualSwitchButton
                                                checked={item.enabled}
                                                onChange={(e) => {
                                                  handleEnableDiscordAccount(
                                                    item.enabled,
                                                    item._id
                                                  );
                                                }}
                                              />
                                            </div>
                                            <DeleteIcon
                                              fontSize="25px"
                                              sx={{ cursor: "pointer" }}
                                              onClick={() => {
                                                setOpenConfirmation(true);
                                                setDeleteProps({
                                                  Id: item._id,
                                                  Platform: item.platform,
                                                });
                                              }}
                                            />
                                          </Box>
                                        )}
                                        {/* PINTEREST PLATFORM */}
                                        {item.platform === "Pinterest" && (
                                          <Box className="add_account_icons">
                                            <div
                                              style={{
                                                marginRight: "10px",
                                                marginTop: "2px",
                                              }}
                                            >
                                              <ManualSwitchButton
                                                checked={item.enabled}
                                                onChange={(e) => {
                                                  handleEnablePinterestAccount(
                                                    item.enabled,
                                                    item._id
                                                  );
                                                }}
                                              />
                                            </div>
                                            <DeleteIcon
                                              fontSize="25px"
                                              sx={{ cursor: "pointer" }}
                                              onClick={() => {
                                                setOpenConfirmation(true);
                                                setDeleteProps({
                                                  Id: item._id,
                                                  Platform: item.platform,
                                                });
                                              }}
                                            />
                                          </Box>
                                        )}
                                      </Grid>
                                    </Grid>
                                  </CardActions>
                                </Card>
                              </Box>
                            );
                          })
                        : null}
                    </Box>
                  </Grid>
                ) : (
                  <> {!isRequesting && <NoDataFound />}</>
                )}
              </Box>
            </div>
          ) : (
            <Box
              sx={{
                width: "100%",
                height: "80vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LoadingBgImage />
            </Box>
          )}
        </LoadingIndicator>
        {/* ********************* list view *********************/}
      </Box>
    </React.Fragment>
  );
};

export default connect(mapStateToProps)(AddAccount);
