import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import RequestingReducer from "./requesting/RequestingReducer";
import ErrorReducer from "./error/ErrorReducer";
import ToastsReducer from "./toasts/ToastsReducer";
import AuthReducer from "./Auth/AuthReducer";
import TwitterReducer from "../ormTicketing/stores/twitterFeeds/TwitterReducer";
import TicketTypeReducer from "./ticketType/TicketTypeReducer";
import UserReducer from "../ormTicketing/stores/user/UserReducer";
import UserFilterReducer from "../ormTicketing/stores/user/UserFilterReducer";
import UserPageLimitNoneReducer from "../ormTicketing/stores/user/UserPageLimitNoneReducer";
import TwitterFilterReducer from "../ormTicketing/stores/twitterFeeds/TwitterFilterReducer";
import TicketTypeFilterReducer from "./ticketType/TicketTypeFilterReducer";
import CustomTicketFieldsReducer from "./customticketfields/CustomTicketFieldsReducer";
import CustomTicketFieldFilterReducer from "./customticketfields/CustomTicketFieldFilterReducer";
import TicketWorkedOnReducer from "../ormTicketing/stores/ticketWorkedOn/TicketWorkedOnReducer";
import ResolvedTicketReducer from "../ormTicketing/stores/resolvedTicket/ResolvedTicketReducer";
import TicketFirstResponseTimeReducer from "../ormTicketing/stores/firstResponseTime/TicketFirstResponseTimeReducer";
import PlatformFilterReducer from "../ormTicketing/stores/platform/PlatformFilterReducer";
import SubSourceReducer from "./subSource/SubSourceReducer";
import LastSyncDateReducer from "./lastSyncDatebasedOnPlatform/lastSyncdateReducer";
import LastProjectByIdReducer from "../ormTicketing/stores/userLastWorkingProject/LastProjectByIdReducer";
import GoogleLoginReduser from "./googleLogin/GoogleLoginReduser";
import ResourceReducer from "./resource/ResourceReducer";
import ResourceFilterReducer from "./resource/ResourceFilterReducer";
import ManageRoleReducer from "../common/components/manageRole/ManageRoleReducer";
import AmbitionBoxFeedsReduser from "./ambitionBoxFeeds/AmbitionBoxFeedsReduser";
import AmbitionBoxFeedsFilterReduser from "./ambitionBoxFeeds/AmbitionBoxFeedsFilterReduser";
import ManageRoleTemplateReducer from "../common/components/manageRole/ManageRoleTemplateReducer";
import FrontEndResourceReducer from "../common/stores/frontEndResources/FrontEndResourceReducer";
import FrontEndResourceFilterReducer from "../common/stores/frontEndResources/FrontEndResourceFilterReducer";
import PagePermissionReduser from "./pagePermission/PagePermissionReduser.js";
import DeviceResetReducer from "./mobileDeviceReset/DeviceResetReducer";

import PlatformReducer from "../ormTicketing/stores/platform/PlatformReducer";
import CustomerDataReducer from "./ticketCustomerData/CustomerDataReducer";
import ReportTemplateReducer from "./reportTemplate/ReportTemplateReducer";
import ReportTemplateReducerFilter from "./reportTemplate/ReportTemplateReducerFilter";
import PageTaggedPostReducer from "./pageTaggedPosts/PageTaggedPostsReducer";
import WordCloudReducer from "./wordCloud/WordCloudReducer";
import ReportsLogoReducer from "./reportsLogo/ReportsLogoReducer";
import ReportsLogoFilterReducer from "./reportsLogo/ReportsLogoFilterReducer";
import CampainListReduser from "./campaignSchedule/CampainListReduser";
import AssignmentRuleReducer from "../ormTicketing/stores/assignmentRule/AssignmentRuleReducer";
import AssignmentRuleFilterReducer from "../ormTicketing/stores/assignmentRule/AssignmentRuleFilterReducer";
import CustomerDataByIdReduser from "./ticketCustomerData/CustomerDataByIdReduser";
import OrmNotificationReducer from "./ormNotifications/ORMNotificationReducer";
import OrmNotificationsByIdReducer from "./ormNotifications/OrmNotificationsByIdReducer";
import TicketcountReducer from "../ormTicketing/stores/ticketCount/TicketCountReducer";
import ReportTemplateByIdReduser from "./reportTemplate/ReportTemplateByIdReduser";
import BrandCompitatorReducer from "../ormTicketing/stores/brandCompitator/BrandCompitatorReducer";
import BrandCompitatorFilterReducer from "../ormTicketing/stores/brandCompitator/BrandCompitatorFilterReducer";
import TurnAroundTimeReducer from "../ormTicketing/stores/turnAroundTime/TurnAroundTimeReducer";
import AppStatusReducer from "../cloudMail/stores/appStatus/AppStatusReducer";
import CloudMailReduser from "../cloudMail/stores/cloudMail/CloudMailReduser";
import CloudMailFilterReduser from "../cloudMail/stores/cloudMail/CloudMailFilterReduser";
import CloudMailListReducer from "../cloudMail/stores/cloudMailMailList/CloudMailListReducer";
import CloudMailOrgReducer from "../cloudMail/stores/cloudMailOrganization/CloudMailOrgReducer";
import CloudSegmentCriteriaReducer from "../cloudMail/stores/cloudMailSegmentCriteria/CloudSegmentCriteriaReducer";
import AiChatReducer from "../chatGpt/stores/aiChat/AiChatReducer";
import AiChatFilterReducer from "../chatGpt/stores/aiChat/AiChatFilterReducer";
import DashboardReducer from "../ormTicketing/stores/dashboard/DashboardReducer";
import StatusReduser from "../ormTicketing/stores/status/StatusReduser";
import StatusFilterReduser from "../ormTicketing/stores/status/StatusFilterReduser";
import UserByIdReducer from "../ormTicketing/stores/user/UserByIdReducer";
import ProjectReducer from "../ormTicketing/stores/project/ProjectReducer";
import ProjectFilterReducer from "../ormTicketing/stores/project/ProjectFilterReducer";
import ProjectByIdReducer from "../ormTicketing/stores/project/ProjectByIdReducer";
import AddAccountReducer from "../ormTicketing/stores/addAccount/AddAccountReducer";
import AddAccountFilterReducer from "../ormTicketing/stores/addAccount/AddAccountFilterReducer";
import TagReducer from "../ormTicketing/stores/tag/TagReducer";
import CategoryReducer from "../ormTicketing/stores/category/CategoryReducer";
import FilterCategoryReducer from "../ormTicketing/stores/category/FilterCategoryReducer";
import TagFilterReducer from "../ormTicketing/stores/tag/TagFilterReducer";
import TicketSettingsReducer from "../ormTicketing/stores/ticketSettings/TicketSettingsReducer";
import ResponseCategoryReducer from "../ormTicketing/stores/responseCategory/ResponseCategoryReducer";
import ResponseMessageReducer from "../ormTicketing/stores/responseMessage/ResponseMessageReducer";
import ResponseMessageFilterReducer from "../ormTicketing/stores/responseMessage/ResponseMessageFilterReducer";
import KeywordConfigReducer from "../ormTicketing/stores/keywordConfiguration/KeywordConfigReducer";
import KeywordConfigFilterReduser from "../ormTicketing/stores/keywordConfiguration/KeywordConfigFilterReduser";
import CannedMessageReduser from "../ormTicketing/stores/cannedMessage/model/CannedMessageReduser";
import ResponseCategoryFilterReducer from "../ormTicketing/stores/responseCategory/ResponseCategoryFilterReducer";
import PlatformAccountReducer from "../ormTicketing/stores/platformAccounts/PlatformAccountsReducer";
import PlatformAccountFilterReducer from "../ormTicketing/stores/platformAccounts/PlatformAccountFilterReducer";
import ClientReducer from "../common/stores/client/ClientReducer";
import ClientFilterReducer from "../common/stores/client/ClientFilterReducer";
import UserPermissionReducer from "../common/stores/userPermission/UserPermissionReducer";
import RolesReducer from "../common/stores/roles/RolesReducer";
import RolesFilterReducer from "../common/stores/roles/RolesFilterReducer";
import OrganizationReduser from "../common/stores/organization/OrganizationReduser";
import OrganizationFilterReduser from "../common/stores/organization/OrganizationFilterReduser";
import UserRoleMappingReduser from "../common/stores/userRoleMapping/UserRoleMappingReduser";
import UserRoleMappingFilterReduser from "../common/stores/userRoleMapping/UserRoleMappingFilterReduser";
import TicketHistoryReducer from "../ormTicketing/stores/ticketHistory/TicketHistoryReducer";
import OrmPagesReducer from "../common/stores/ormPages/OrmPagesReducer";
import RolePermissionsReducer from "../common/stores/rolePermissions/RolePermissionsReducer";
import OperationReducer from "../common/stores/operations/OperationReducer";
import OrmResourceReducer from "../common/stores/ormResources/OrmResourceReducer";
import GoogleAccountReduser from "../ormTicketing/stores/googleAccount/GoogleAccountReduser";
import GoogleAccountFilterReducer from "../ormTicketing/stores/googleAccount/GoogleAccountFilterReducer";
import FaceBookAccountFilterReduser from "../ormTicketing/stores/faceBookAccount/FaceBookAccountFilterReduser";
import FaceBookAccountReduser from "../ormTicketing/stores/faceBookAccount/FaceBookAccountReduser";
import InstagramAccountReduser from "../ormTicketing/stores/InstagramAccount/InstagramAccountReduser";
import InstagramAccountFilterReduser from "../ormTicketing/stores/InstagramAccount/InstagramAccountFilterReduser";
import EmailAccountReduser from "../ormTicketing/stores/emailAccount/EmailAccountReduser";
import EmailAccountFilterReduser from "../ormTicketing/stores/emailAccount/EmailAccountFilterReduser";
import AppStoreAddAccountReducer from "../ormTicketing/stores/appstoreAddAccount/AppStoreAddAccountReducer";
import AppstoreAccountFilterReducer from "../ormTicketing/stores/appstoreAddAccount/AppstoreAccountFilterReducer";
import GlassdoorAccountReduser from "../ormTicketing/stores/glassdoorAccount/GlassdoorAccountReduser";
import GlassdoorAccountFilterReduser from "../ormTicketing/stores/glassdoorAccount/GlassdoorAccountFilterReduser";
import AmbitionBoxAccountReduser from "../ormTicketing/stores/ambitionBoxAccount/AmbitionBoxAccountReduser";
import AmbitionBoxAccountFilterReuser from "../ormTicketing/stores/ambitionBoxAccount/AmbitionBoxAccountFilterReuser";
import GMBAccountReducer from "../ormTicketing/stores/gmb_Account/GMBAccountReducer";
import TelegramAccountReducer from "../ormTicketing/stores/telegramAccount/TelegramAccountReducer";
import IndeedAccountReducer from "../ormTicketing/stores/indeedAccount/IndeedAccountReducer";
import RedditAccountReducer from "../ormTicketing/stores/ticketWorkedOn/redditAccount/RedditAccountReducer";
import DiscordAccountReducer from "../ormTicketing/stores/discordAccount/DiscordAccountReducer";
import PinterestAccountReduser from "../ormTicketing/stores/ticketWorkedOn/pinterestAccount/PinterestAccountReduser";
import TrustpilotAccountReduser from "../ormTicketing/stores/ticketWorkedOn/trustPilotAccount/TrustpilotAccountReduser";
import LinkedInAccountReducer from "../ormTicketing/stores/linkedIn_account/LinkedInAccountReducer";
import YoutubeAccountReduser from "../ormTicketing/stores/youtubeAccount/YoutubeAccountReduser";
import YoutubeAccountFilterReduser from "../ormTicketing/stores/youtubeAccount/YoutubeAccountFilterReduser";
import WhatsappAccountReduser from "../ormTicketing/stores/whatsappAccount/WhatsappAccountReduser";
import QuoraAccountReducer from "../ormTicketing/stores/quoraAccount/QuoraAccountReducer";
import PlatformSchedulerReduser from "../ormTicketing/stores/platformScheduler/PlatformSchedulerReduser";
import PlatformTaskSchedulingReduser from "../ormTicketing/stores/platformScheduler/PlatformTaskSchedulingReduser";
import TicketsReducer from "../ormTicketing/stores/tickets/TicketsReducer";
import AppStoreReducer from "../ormTicketing/stores/linkedInFeed/appstore/AppStoreReducer";
import AppStoreFilterReducer from "../ormTicketing/stores/linkedInFeed/appstore/AppStoreFilterReducer";
import FilterTicketReducer from "../ormTicketing/stores/tickets/FilterTicketReducer";
import TicketByIdReducer from "../ormTicketing/stores/tickets/TicketByIdReducer";
import TwitterDashboardReducer from "../ormTicketing/stores/reddit/twitterDashboard/TwitterDashboardReducer";
import ReplyReducer from "../ormTicketing/stores/platformAccounts/reply/ReplyReducer";
import TwitterDirectMessageReducer from "../ormTicketing/stores/linkedIn_account/twitterDirectMessage/TwitterDirectMessageReducer";
import RetweetReducer from "../ormTicketing/stores/retweet/RetweetReducer";
import UnRetweetReduser from "../ormTicketing/stores/unRetweet/UnRetweetReduser";
import PlayStoreReplyReducer from "../ormTicketing/stores/playStoreReply/PlayStoreReplyReducer";
import FacebookReducer from "../ormTicketing/stores/facebook/FacebookReducer";
import FacebookInnerFilterReducer from "../ormTicketing/stores/facebook/FacebookInnerFilterReducer";
import FacebookWebhookReducer from "../ormTicketing/stores/facebook/FacebookWebhookReducer";
import FacebookFilterReducer from "../ormTicketing/stores/facebook/FacebookFilterReducer";
import InstagramFeedsReducer from "../ormTicketing/stores/instagramFeeds/InstagramFeedsReducer";
import InstagramFeedsByIdReducer from "../ormTicketing/stores/instagramFeeds/InstagramFeedsByIdReducer";
import InstagramFilterReducer from "../ormTicketing/stores/instagramFeeds/InstagramFilterReducer";
import FacebookDmReducer from "../ormTicketing/stores/facebook_dm/FacebookDmReducer";
import YoutubeFeedByIdReducer from "../ormTicketing/stores/youtube/YoutubeFeedByIdReducer";
import YoutubeFeedFilterReducer from "../ormTicketing/stores/youtube/YoutubeFeedFilterReducer";
import YoutubeFeedReducer from "../ormTicketing/stores/youtube/YoutubeFeedReducer";
import YoutubeReplyPostReducer from "../ormTicketing/stores/youtube/YoutubeReplyPostReducer";
import EmailFeedsReduser from "../ormTicketing/stores/emailFeeds/EmailFeedsReduser";
import EmailFeedFilterReduser from "../ormTicketing/stores/emailFeeds/EmailFeedFilterReduser";
import PlayStoreReducer from "../ormTicketing/stores/playstore/PlayStoreReducer";
import PlayStoreFilterReducer from "../ormTicketing/stores/playstore/PlayStoreFilterReducer";
import WhatsAppFeedReduser from "../ormTicketing/stores/whatsappFeed/WhatsAppFeedReduser";
import WhatsAppFeedFilterReduser from "../ormTicketing/stores/whatsappFeed/WhatsAppFeedFilterReduser";
import TelegramFeedsReducer from "../ormTicketing/stores/telegram/TelegramFeedsReducer";
import TelegramFeedsFilterReducer from "../ormTicketing/stores/telegram/TelegramFeedsFilterReducer";
import QuoraFeedsReduser from "../ormTicketing/stores/quoraFeeds/QuoraFeedsReduser";
import QuoraFeedsFilterReduser from "../ormTicketing/stores/quoraFeeds/QuoraFeedsFilterReduser";
import GlassdoorFeedsReduser from "../ormTicketing/stores/glassdoorFeeds/GlassdoorFeedsReduser";
import GlassdoorFeedsFilterReduser from "../ormTicketing/stores/glassdoorFeeds/GlassdoorFeedsFilterReduser";
import TicketsByFiledsReduser from "../ormTicketing/stores/tickets/TicketsByFiledsReduser";
import InstagramDirectMessageReduser from "../ormTicketing/stores/instagramDirectMessage/InstagramDirectMessageReduser";
import TicketsReportReduser from "../ormTicketing/stores/tickets/TicketsReportReduser";
import TicketReportsReduserWithPagination from "../ormTicketing/stores/tickets/TicketReportsReduserWithPagination";
import GmbReviewFeedsReduser from "../ormTicketing/stores/gmbReviews/GmbReviewFeedsReduser";
import GmbReviewFeedsFilterReduser from "../ormTicketing/stores/gmbReviews/GmbReviewFeedsFilterReduser";
import GmbQuestionsReduser from "../ormTicketing/stores/gmbQuestions/GmbQuestionsReduser";
import GmbQuestionsFilterReduser from "../ormTicketing/stores/gmbQuestions/GmbQuestionsFilterReduser";
import LinkedInFeedsFilterReduser from "../ormTicketing/stores/linkedInFeed/LinkedInFeedFilterReducer";
import LinkedInFeedsReduser from "../ormTicketing/stores/linkedInFeed/LinkedInFeedReducer";
import IndeedFeedReducer from "../ormTicketing/stores/indeed/IndeedFeedReducer";
import RedditFeedReducer from "../ormTicketing/stores/reddit/RedditFeedReducer";
import ConsumerReducer from "../ormTicketing/stores/consumerFeeds/ConsumerReducer";
import ConsumerFilterReducer from "../ormTicketing/stores/consumerFeeds/ConsumerFilterReducer";
import ConsumerAccountReducer from "../ormTicketing/stores/consumer_account/ConsumerAccountReducer";
import RelatedTicketsReduser from "../ormTicketing/stores/tickets/RelatedTicketsReduser";
import TicketReportsReducer from "../ormTicketing/stores/reportsOnTickets/TicketReportsReducer";
import AgentReportsReducer from "../ormTicketing/stores/reportsOnTickets/AgentReportsReducer";
import SentimentalAnalysisReportReducer from "../ormTicketing/stores/reportsOnTickets/SentimentalAnalysisReportReducer";
import DiscordFeedReduser from "../ormTicketing/stores/discord/DiscordFeedReduser";
import DiscordFeedsFilterReduser from "../ormTicketing/stores/discord/DiscordFeedsFilterReduser";
import PinterestFeedsReduser from "../ormTicketing/stores/pinterest/PinterestFeedsReduser";
import PinterestFeedsFilterReduser from "../ormTicketing/stores/pinterest/PinterestFeedsFilterReduser";
import TrustpilotFeedsReduser from "../ormTicketing/stores/trustpilot/TrustpilotFeedsReduser";
import TrustpilotFeedsFilterReduser from "../ormTicketing/stores/trustpilot/TrustpilotFeedsFilterReduser";
import OrmReportLogoReducer from "../ormTicketing/stores/ormReportsLogo/OrmReportLogoReducer";
import PriorityReducer from "../ormTicketing/stores/priority/PriorityReducer";
import FilterPriorityReducer from "../ormTicketing/stores/priority/FilterPriorityReducer";
import TicketHistoryMassReducer from "../ormTicketing/stores/ticketHistory/TicketHistoryMassReducer";
import YoutubeDataExtractionFilterReducer from "../aiplex_tools_frontend_modular/dataExtraction/stores/youtubeDataExtraction/YoutubeDataExtractionFilterReducer";
import YoutubeDataExtractionReducer from "../aiplex_tools_frontend_modular/dataExtraction/stores/youtubeDataExtraction/YoutubeDataExtractionReducer";
import DataExtractionProjectsReducer from "../aiplex_tools_frontend_modular/dataExtraction/stores/youtubeDataExtraction/DataextractionProjectsReducer";

import { surveyRootReducer } from "../aiplex_tools_frontend_modular/survey/stores/surveyRootReducer";
import { a3RootReducer } from "../a3label_org_intranet/a3labels/stores/a3RootReducer";
import { crmRootReducer } from "../crm_core_frontend/crm_core/stores/crmRootReducer";
import { fingerPrintingRootReducer } from "../aiplex_tools_frontend_modular/fingerPrinting/stores/fingerPrintingRootReducer.js";
import { linkCheckingRootReducer } from "../aiplex_tools_frontend_modular/linkChecking/stores/linkCheckingRootReducer.js";
import { dsrRootReducer } from "../aiplex_tools_frontend_modular/dsr/stores/dsrRootReducer.js";
import { websweepXRootReducer } from "../aiplex_tools_frontend_modular/websweepX/stores/websweepXRootReducer.js";
import { siteLinkDiscoveryRootReducer } from "../aiplex_tools_frontend_modular/siteLinkDiscovery/stores/siteLinkDiscoveryRootReducer.js";
import { influencerRootReducer } from "../aiplex_tools_frontend_modular/influencer/stores/influencerRootReducer.js";
import { youtubeMcnRootReducer } from "../aiplex_tools_frontend_modular/youtube_mcn/stores/mcnRootReducer.js";
import { WebformsRootReducer } from "../aiplex_tools_frontend_modular/webformAutomation/stores/WebformsRootReducer.js";
import ThreadsFeedReduser from "../ormTicketing/stores/instagramFeeds/ThreadsFeedReduser";
import ThreadsAccountReuser from "../ormTicketing/stores/threadsAccount/ThreadsAccountReuser";
import ThreadsAccountFilterReduser from "../ormTicketing/stores/threadsAccount/ThreadsAccountFilterReduser";
import MailTriggerLIstFilterReduser from "../common/stores/triggerLIst/MailTriggerLIstFilterReduser";
import MailTriggerListReduser from "../common/stores/triggerLIst/MailTriggerListReduser";
import UserMailMappingReduser from "../common/stores/userMailMapping/UserMailMappingReduser";
import UserMailMappingFIlterReduser from "../common/stores/userMailMapping/UserMailMappingFIlterReduser";
import AllMentionsReduser from "../ormTicketing/stores/allMentions/AllMentionsReduser";
import AllMentionsFilterReduser from "../ormTicketing/stores/allMentions/AllMentionsFilterReduser";
import AiSuggestionReducer from "../ormTicketing/stores/aiSuggestions/AiSuggestionReducer.js";
import BardAiSuggestionReducer from "../ormTicketing/stores/aiSuggestions/BardAiSuggestionReducer.js";
import AiSettingsReducer from "../common/stores/aiSettings/AiSettingsReducer.js";
import OrmNotificationFilterReducer from "./ormNotifications/OrmNotificationFilterReducer.js";
import DashboardTicketCountReduser from "../ormTicketing/stores/ticketCount/DashboardTicketCountReduser";
import DIYReportTemplateReducer from "../ormTicketing/stores/newReportTemplate/DIYReportTemplateReducer.js";
import DIYReportTemplateReducerFilter from "../ormTicketing/stores/newReportTemplate/DIYReportTemplateReducerFilter.js";
import DIYReportTemplateByIdReduser from "../ormTicketing/stores/newReportTemplate/DIYReportTemplateByIdReduser.js";
import RedditPostReduser from "../ormTicketing/stores/reddit/RedditPostReduser";
import RedditCommentsReduser from "../ormTicketing/stores/reddit/RedditCommentsReduser";
import TelegramPlatformAccountReduser from "../ormTicketing/stores/platformAccounts/TelegramPlatformAccountReduser.js";
import DashboardStatusFilterReduser from "../ormTicketing/stores/status/DashboardStatusFilterReduser.js";
import ChatBotReducer from "./chatBot/ChatBotReducer.js";
import FaqReducer from "../ormTicketing/stores/faq/FaqReducer.js";
import FaqModulesReducer from "../ormTicketing/stores/faq/FaqModulesReducer.js";
import FaqTopicsReducer from "../ormTicketing/stores/faq/FaqTopicsReducer.js";
import AllMentionsExportFilterReduser from "../ormTicketing/stores/allMentions/AllMentionsExportFilterReduser.js";
import AutoresponseReducer from "../ormTicketing/stores/autoResponse/AutoResponseReducer.js";
import AutoResponsefilterReducer from "../ormTicketing/stores/autoResponse/AutoResponseFilterReducer.js";
import MentionsCustomerAndAccountsReducer from "../ormTicketing/stores/allMentions/MentionsCustomerAndAccountsReducer.js";
import AutomationOperationReducer from "../ormTicketing/stores/autoResponse/AutomationOperationReducer.js";
import AutomationFieldsReducer from "../ormTicketing/stores/autoResponse/AutomationFieldsReducer.js";
// import KpiConfigReducer from "../ormTicketing/stores/ticketSettings/KpiConfigReducer.js";
import SelectAllTicketsReduser from "../ormTicketing/stores/tickets/SelectAllTicketsReduser.js";

// eslint-disable-next-line import/no-anonymous-default-export
export default (history) => {
  let reducerMap = {
    error: ErrorReducer.reducer,
    requesting: RequestingReducer.reducer,
    router: connectRouter(history),
    toasts: new ToastsReducer().reducer,
    auth: new AuthReducer().reducer,
    tickets: new TicketsReducer().reducer,
    related_tickets: new RelatedTicketsReduser().reducer,
    ticketsReport: new TicketsReportReduser().reducer,
    ticketFields: new TicketsByFiledsReduser().reducer,
    ticketsSelectAll: new SelectAllTicketsReduser().reducer,
    // Filter Priority
    filterpriority: new FilterPriorityReducer().reducer,
    // priority
    priority: new PriorityReducer().reducer,
    project: new ProjectReducer().reducer,
    tag: new TagReducer().reducer,
    tagfilter: new TagFilterReducer().reducer,
    category: new CategoryReducer().reducer,
    playstore: new PlayStoreReducer().reducer,
    playstorefilter: new PlayStoreFilterReducer().reducer,
    twitter: new TwitterReducer().reducer,
    ticketType: new TicketTypeReducer().reducer,
    user: new UserReducer().reducer,
    appstore: new AppStoreReducer().reducer,
    appstorefilter: new AppStoreFilterReducer().reducer,
    filterUser: new UserFilterReducer().reducer,
    ticketfilter: new FilterTicketReducer().reducer,
    dashboard: new DashboardReducer().reducer,

    // user api reducer for filter (page_limit =none)
    userfilter: new UserPageLimitNoneReducer().reducer,
    categoryfilter: new FilterCategoryReducer().reducer,
    twitterfilter: new TwitterFilterReducer().reducer,

    //ticketType filter reducer for dropdown(page_limit = none)

    filterTicketType: new TicketTypeFilterReducer().reducer,

    // project filter with page_limit none
    projectfilter: new ProjectFilterReducer().reducer,
    projectById: new ProjectByIdReducer().reducer,

    // custom_ticket_field
    customticketfields: new CustomTicketFieldsReducer().reducer,

    // custom_ticket_field
    customticketfields_filter: new CustomTicketFieldFilterReducer().reducer,

    //ticketWorkedOn reducer
    ticketWorkedOn: new TicketWorkedOnReducer().reducer,

    //resolved Ticket reducer
    ticketResolved: new ResolvedTicketReducer().reducer,

    //ticket first response time
    ticketFirstResponseTime: new TicketFirstResponseTimeReducer().reducer,

    //platform filter reducer
    platformfilter: new PlatformFilterReducer().reducer,
    platform: new PlatformReducer().reducer,

    // twitterDashboar reducer
    twitterDashboard: new TwitterDashboardReducer().reducer,

    //Ticket By id Reducer
    ticket: new TicketByIdReducer().reducer,

    //All Mentions Reducer
    allmentions: new AllMentionsReduser().reducer,
    allmentionsFilter: new AllMentionsFilterReduser().reducer,
    allmentionsExportFilter: new AllMentionsExportFilterReduser().reducer,

    all_customer_and_account_names: new MentionsCustomerAndAccountsReducer()
      .reducer,

    //Report By id Reducer
    reportById: new ReportTemplateByIdReduser().reducer,

    //Customer By id Reducer
    customerById: new CustomerDataByIdReduser().reducer,

    //Reply Reducer
    reply: new ReplyReducer().reducer,
    // TwitterDirectmessage By id
    twitterDirectMeassage: new TwitterDirectMessageReducer().reducer,

    //Retweet Reducer
    retweet: new RetweetReducer().reducer,
    unretweet: new UnRetweetReduser().reducer,
    //subSource Reducer
    subSource: new SubSourceReducer().reducer,

    //UserById
    userById: new UserByIdReducer().reducer,

    //Add Account
    addAccount: new AddAccountReducer().reducer,
    addAccountFilter: new AddAccountFilterReducer().reducer,

    //PlayStore Reply
    playStoreReply: new PlayStoreReplyReducer().reducer,

    // last sync date based on platform
    lastSyncDate: new LastSyncDateReducer().reducer,

    client: new ClientReducer().reducer,
    clientFilter: new ClientFilterReducer().reducer,

    userLastWorkingProjectById: new LastProjectByIdReducer().reducer,

    // logOut : new LogoutReducer().reducer,

    // userPermission Reducer
    userPermission: new UserPermissionReducer().reducer,

    // Google Login
    googleLogin: new GoogleLoginReduser().reducer,

    // userPermission Reducer

    facebook: new FacebookReducer().reducer,
    facebookFilter: new FacebookFilterReducer().reducer,
    facebookWebhook: new FacebookWebhookReducer().reducer,

    //instagram Reducer
    instagramFeeds: new InstagramFeedsReducer().reducer,
    instagramFilter: new InstagramFilterReducer().reducer,
    instagramFeedById: new InstagramFeedsByIdReducer().reducer,

    //threads Reducer
    threadFeeds: new ThreadsFeedReduser().reducer,

    facebook_dm: new FacebookDmReducer().reducer,

    //roles Reducer
    roles: new RolesReducer().reducer,

    //role Permission Reducer

    rolePermission: new RolePermissionsReducer().reducer,

    //resource Reducer
    resourceFilter: new ResourceFilterReducer().reducer,
    resource: new ResourceReducer().reducer,

    //addrole Filterreducer
    rolesFilter: new RolesFilterReducer().reducer,

    //manageRole Reducer
    roleFilter: new ManageRoleReducer().reducer,
    roleTemplate: new ManageRoleTemplateReducer().reducer,

    //Google Account
    googleAccount: new GoogleAccountReduser().reducer,
    googleAccountFilter: new GoogleAccountFilterReducer().reducer,

    //operations Reducer
    operations: new OperationReducer().reducer,

    // Youtube Account Reduser
    youtubeAccount: new YoutubeAccountReduser().reducer,
    youtubeAccountFilter: new YoutubeAccountFilterReduser().reducer,

    //Youtube Feeds Reducer
    youtubeFeedById: new YoutubeFeedByIdReducer().reducer,
    youtubeFeed: new YoutubeFeedReducer().reducer,
    youtubeReply: new YoutubeReplyPostReducer().reducer,
    youtubeFeedFilter: new YoutubeFeedFilterReducer().reducer,

    // page Role Reduser
    pagePermission: new PagePermissionReduser().reducer,
    // pageRoleMapping: new PageRoleMappingReduser().reducer,
    // pageRoleMappingFilter: new PageRoleMappingFilterReduser().reducer,

    // FaceBook Account Reduser
    facebookAccount: new FaceBookAccountReduser().reducer,
    facebookAccountFilter: new FaceBookAccountFilterReduser().reducer,

    //Instagram Account
    instagramAccount: new InstagramAccountReduser().reducer,
    instagramAccountFilter: new InstagramAccountFilterReduser().reducer,

    // Email Feeds
    emailFeeds: new EmailFeedsReduser().reducer,
    emailFeedsFilter: new EmailFeedFilterReduser().reducer,

    // Email Account
    emailAccount: new EmailAccountReduser().reducer,
    emailAccountFilter: new EmailAccountFilterReduser().reducer,

    //AppStore Account
    appStoreAccount: new AppStoreAddAccountReducer().reducer,
    appstoreAccountFilter: new AppstoreAccountFilterReducer().reducer,

    //Threads Account
    threadsAddAccount: new ThreadsAccountReuser().reducer,
    threadsAddAccountFilter: new ThreadsAccountFilterReduser().reducer,

    //WhatsApp Feed
    whatsAppFeed: new WhatsAppFeedReduser().reducer,
    whatsAppFeedFilter: new WhatsAppFeedFilterReduser().reducer,

    //Telegram Feed
    telegramFeeds: new TelegramFeedsReducer().reducer,
    telegramFeedsFilter: new TelegramFeedsFilterReducer().reducer,

    //platform Scheduler
    platform_scheduling: new PlatformSchedulerReduser().reducer,
    platform_task_scheduling: new PlatformTaskSchedulingReduser().reducer,

    // Quora Feeds
    quoraFeed: new QuoraFeedsReduser().reducer,
    quoraFeedFilter: new QuoraFeedsFilterReduser().reducer,

    //Glassdoor Account
    glassdoorAccount: new GlassdoorAccountReduser().reducer,
    glassdoorAccountFilter: new GlassdoorAccountFilterReduser().reducer,

    //Glassdoor Feeds
    glassdoorFeeds: new GlassdoorFeedsReduser().reducer,
    glassdoorFeedsFilter: new GlassdoorFeedsFilterReduser().reducer,

    //AmbitionBox Feeds
    ambitionBoxFeeds: new AmbitionBoxFeedsReduser().reducer,
    ambitionBoxFeedsFilter: new AmbitionBoxFeedsFilterReduser().reducer,

    //AmbitionBox ACcount
    ambitionBoxAccount: new AmbitionBoxAccountReduser().reducer,
    ambitionBoxAccountFilter: new AmbitionBoxAccountFilterReuser().reducer,

    //Organization
    organization: new OrganizationReduser().reducer,
    organizationFilter: new OrganizationFilterReduser().reducer,

    //triggerList
    trigger_list: new MailTriggerListReduser().reducer,
    trigger_list_filter: new MailTriggerLIstFilterReduser().reducer,

    //user mail mapping
    userMailMapping: new UserMailMappingReduser().reducer,
    userMailMappingFilter: new UserMailMappingFIlterReduser().reducer,

    //User Role Mapping
    userRoleMapping: new UserRoleMappingReduser().reducer,
    userRoleMappingFilter: new UserRoleMappingFilterReduser().reducer,

    //instagramDirectMessage
    instagramDirectMessage: new InstagramDirectMessageReduser().reducer,

    //Whatsapp Account
    whatsappAccount: new WhatsappAccountReduser().reducer,

    // Platform Accounts
    platformAccount: new PlatformAccountReducer().reducer,
    platformAccountFilter: new PlatformAccountFilterReducer().reducer,
    telegramPlatformAccount: new TelegramPlatformAccountReduser().reducer,

    //Reports on Tickets
    ticketReports: new TicketReportsReducer().reducer,
    ticketsReportsWithPagination: new TicketReportsReduserWithPagination()
      .reducer,
    agentReports: new AgentReportsReducer().reducer,
    sentimentalAnalysisReport: new SentimentalAnalysisReportReducer().reducer,

    // frontEndResource reducer
    frontEndResource: new FrontEndResourceReducer().reducer,
    frontEndResourceFilter: new FrontEndResourceFilterReducer().reducer,

    //status
    status: new StatusReduser().reducer,
    statusFilter: new StatusFilterReduser().reducer,
    dashboardStatusFilter: new DashboardStatusFilterReduser().reducer,

    // ticet history
    ticketHistory: new TicketHistoryReducer().reducer,
    ticketMassHistory: new TicketHistoryMassReducer().reducer,

    // ticket settings
    ticketSettings: new TicketSettingsReducer().reducer,
    // kpi_report_config: new KpiConfigReducer().reducer,

    // response Category
    responseCategory: new ResponseCategoryReducer().reducer,
    responseCategoryFilter: new ResponseCategoryFilterReducer().reducer,

    // response message
    responseMessage: new ResponseMessageReducer().reducer,
    responseMessageFilter: new ResponseMessageFilterReducer().reducer,

    //keyword config
    keywordConfig: new KeywordConfigReducer().reducer,
    keywordConfigurationFilter: new KeywordConfigFilterReduser().reducer,

    //customer Data
    customerData: new CustomerDataReducer().reducer,

    // report template
    reportTemplate: new ReportTemplateReducer().reducer,
    reportTemplateFilter: new ReportTemplateReducerFilter().reducer,

    //DIY report template
    diyReportTemplate: new DIYReportTemplateReducer().reducer,
    diyReportTemplateFilter: new DIYReportTemplateReducerFilter().reducer,
    diyReportById: new DIYReportTemplateByIdReduser().reducer,

    // ormReport logo
    ormReportLogo: new OrmReportLogoReducer().reducer,

    // pageTagged Post
    pageTaggedPost: new PageTaggedPostReducer().reducer,

    // word cloud
    wordCloudSettings: new WordCloudReducer().reducer,

    // gmb account
    gmbAccount: new GMBAccountReducer().reducer,

    // gmb reviews
    GMBReviewFeeds: new GmbReviewFeedsReduser().reducer,
    GMBReviewFeedsFilter: new GmbReviewFeedsFilterReduser().reducer,

    // gmb questions
    GMBQuestionFeeds: new GmbQuestionsReduser().reducer,
    GMBQuestionFeedsFilter: new GmbQuestionsFilterReduser().reducer,

    // LinkedIn Feeds
    linkedInFeedFilter: new LinkedInFeedsFilterReduser().reducer,
    linkedInFeeds: new LinkedInFeedsReduser().reducer,

    //Reports Logo Reducer
    reportsLogo: new ReportsLogoReducer().reducer,
    reportsLogoFilter: new ReportsLogoFilterReducer().reducer,

    // LinkedIn Account
    linkedInAccount: new LinkedInAccountReducer().reducer,

    //youtube DataExtraction
    youtubeDataExtraction: new YoutubeDataExtractionReducer().reducer,
    youtubeDataExtractionFilter: new YoutubeDataExtractionFilterReducer()
      .reducer,
    dataExtractionProjects: new DataExtractionProjectsReducer().reducer,

    // app status
    appStatus: new AppStatusReducer().reducer,
    //campaignList
    campaignList: new CampainListReduser().reducer,

    // cloud mail
    cloudMail: new CloudMailReduser().reducer,
    cloudMailFilter: new CloudMailFilterReduser().reducer,

    // cloud mail list
    cloudMailList: new CloudMailListReducer().reducer,

    // cloud mail org
    cloudMailOrg: new CloudMailOrgReducer().reducer,
    // segment criteria reducer
    segmentCriteria: new CloudSegmentCriteriaReducer().reducer,

    // ASSignment rule
    assignmentRule: new AssignmentRuleReducer().reducer,
    assignmentRuleFilter: new AssignmentRuleFilterReducer().reducer,

    // AutoResponse
    autoResponse: new AutoresponseReducer().reducer,
    autoResponseFilter: new AutoResponsefilterReducer().reducer,
    automationOperations: new AutomationOperationReducer().reducer,
    automationFields: new AutomationFieldsReducer().reducer,
    // OpenAi Chat feed
    aiChatFeed: new AiChatReducer().reducer,
    aiChatFeedFilter: new AiChatFilterReducer().reducer,

    // telegram account
    telegramAccount: new TelegramAccountReducer().reducer,

    //ORM notifications
    ormNotifications: new OrmNotificationReducer().reducer,
    ormNotificationsFilter: new OrmNotificationFilterReducer().reducer,
    ormNotificationsById: new OrmNotificationsByIdReducer().reducer,

    // orm account
    OrmResource: new OrmResourceReducer().reducer,

    // orm Pages
    ormPages: new OrmPagesReducer().reducer,

    // ticket Count reducer
    ticketCount: new TicketcountReducer().reducer,
    dashboardTicketCount: new DashboardTicketCountReduser().reducer,

    // facebook Inner filter reducer
    fbInnerFilter: new FacebookInnerFilterReducer().reducer,

    brandCompitator: new BrandCompitatorReducer().reducer,
    brandCompitatorFilter: new BrandCompitatorFilterReducer().reducer,

    // Canned Message reducer
    cannedMessage: new CannedMessageReduser().reducer,

    // Indeed feed Reducer
    indeedFeeds: new IndeedFeedReducer().reducer,

    // Reddit Feed Reducer
    redditFeeds: new RedditFeedReducer().reducer,
    redditPosts: new RedditPostReduser().reducer,
    redditComments: new RedditCommentsReduser().reducer,

    // CONSUMER FEED
    consumer_feed: new ConsumerReducer().reducer,
    consumer_filter: new ConsumerFilterReducer().reducer,
    consumer_account: new ConsumerAccountReducer().reducer,

    //indeed Account Reducer
    indeedAccount: new IndeedAccountReducer().reducer,

    //Trust pilot Account Reducer
    trustpilotaccount: new TrustpilotAccountReduser().reducer,

    //quora account reducer
    quoraAccount: new QuoraAccountReducer().reducer,

    //reddit account reducer
    redditAccount: new RedditAccountReducer().reducer,

    // discord account reducer
    discordAccount: new DiscordAccountReducer().reducer,

    // pinterest account reducer
    pinterestAccount: new PinterestAccountReduser().reducer,

    //discord reducer
    discordFeeds: new DiscordFeedReduser().reducer,
    discordFilter: new DiscordFeedsFilterReduser().reducer,

    //pinterest reducer
    pinterestFeeds: new PinterestFeedsReduser().reducer,
    pinterestFilter: new PinterestFeedsFilterReduser().reducer,

    //trustpilot reducer
    trustPilotFeeds: new TrustpilotFeedsReduser().reducer,
    trustPilotFilter: new TrustpilotFeedsFilterReduser().reducer,

    // tikcet average tat
    ticketAvgTat: new TurnAroundTimeReducer().reducer,

    // Ai Suggestions
    aiSuggestions: new AiSuggestionReducer().reducer,
    BardAiSuggestions: new BardAiSuggestionReducer().reducer,

    //AI Settings Reducer
    aiSettings: new AiSettingsReducer().reducer,

    chat: new ChatBotReducer().reducer,

    faq: new FaqReducer().reducer,
    faqModules: new FaqModulesReducer().reducer,
    faqTopics: new FaqTopicsReducer().reducer,
  };

  if (process.env.REACT_APP_SURVEY_ENABLED === "true") {
    reducerMap = { ...reducerMap, ...surveyRootReducer };
  }

  if (process.env.REACT_APP_A3LABEL_ENABLED === "true") {
    reducerMap = { ...reducerMap, ...a3RootReducer };
  }

  if (process.env.REACT_APP_CRM_ENABLED === "true") {
    reducerMap = { ...reducerMap, ...crmRootReducer };
  }

  if (process.env.REACT_APP_FINGERPRINTING_ENABLED === "true") {
    reducerMap = { ...reducerMap, ...fingerPrintingRootReducer };
  }

  if (process.env.REACT_APP_LINK_CHECKING_ENABLED === "true") {
    reducerMap = { ...reducerMap, ...linkCheckingRootReducer };
  }

  if (process.env.REACT_APP_DSR_ENABLED === "true") {
    reducerMap = { ...reducerMap, ...dsrRootReducer };
  }

  if (process.env.REACT_APP_WEBSWEEPX_ENABLED === "true") {
    reducerMap = { ...reducerMap, ...websweepXRootReducer };
  }

  if (process.env.REACT_APP_WEBFORM_AUTOMATION_ENABLED === "true") {
    reducerMap = { ...reducerMap, ...WebformsRootReducer };
  }

  if (process.env.REACT_APP_SITE_DISCOVERY_ENABLED === "true") {
    reducerMap = { ...reducerMap, ...siteLinkDiscoveryRootReducer };
  }

  if (process.env.REACT_APP_INFLUENCER_ENABLED === "true") {
    reducerMap = { ...reducerMap, ...influencerRootReducer };
  }

  if (process.env.REACT_APP_MCN_ENABLED === "true") {
    reducerMap = { ...reducerMap, ...youtubeMcnRootReducer };
  }

  return combineReducers(reducerMap);
};
