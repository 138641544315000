import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import AutoCompleteMultiSelect from "../../../components/autoCompleteComponent/AutoCompleteMultiSelect";
import {
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  TextField,
} from "@mui/material";
import { Field, Form, Formik, getIn, ErrorMessage, FieldArray } from "formik";
import * as yup from "yup";
import UserAction from "../../stores/user/UserAction";
import { connect } from "react-redux";
import MuiButton from "../../../components/muiButton/MuiButton";
import MuiTextField from "../../../components/muiTextField/MuiTextField";
import LockIcon from "@mui/icons-material/Lock";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { theme } from "../../../views/App";
import CloseIcon from "@mui/icons-material/Close";
import environment from "environment";

// mapstateto props function

const mapDispatchToProps = (dispatch) => {
  return {};
};

// *********************************** validation schema
const validationSchema = yup.object({
  user_data: yup.object().shape({
    email: yup
      .string("Enter your Email")
      .trim()
      .min(1, "Email should be of minimum 1 characters length")
      .required("Email is required"),
    username: yup
      .string("Enter your User Name")
      .trim()
      .min(1, "User Name should be of minimum 1 character length")
      .required("User Name is required"),
    firstName: yup
      .string("Enter your First Name")
      .trim()
      .min(1, "First Name should be of minimum 1 characters length")
      .required("First Name is required"),
    lastName: yup
      .string("Enter your Last Name")
      .trim()
      .min(1, "Last Name should be of minimum 1 characters length")
      .required("Last Name is required"),
    // credentials: yup.array().of(
    //   yup.object().shape({
    //     value: yup.string().required("Password is required"),
    //   })
    // ),
    credentials: yup.array().of(
      yup.object().shape({
        value: yup.string().required("Password is Required"),
      })
    ),
    organization: yup
      .object()
      .shape({})
      .required("Organization is Required")
      .nullable(),
  }),
});

function UserPost(props) {
  // user id
  // const [id, setId] = useState(
  //   Math.floor(Math.random() * (999 - 100 + 1) + 100)
  // );'

  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const buttonForLoginRef = React.useRef(null);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //Initial values
  let initial = {};
  if (props.edit) {
    initial = {
      user_data: {
        email: props.userEditData.email,
        username: props.userEditData.username,
        firstName: props.userEditData.firstName,
        lastName: props.userEditData.lastName,
        organization: props.organizationList.filter(
          (val) => val.organization_name === props.userEditData.organization
        )[0],
        enabled: props.userEditData.enabled === "Enabled" ? true : false,
        mobile_login:
          props.userEditData.mobile_login === "Enabled" ? true : false,
        dsr_enabled:
          props.userEditData.dsr_enabled === "Enabled" ? true : false,
      },
    };
  } else {
    initial = {
      user_data: {
        email: "",
        username: "",
        firstName: "",
        lastName: "",
        organization:
          props.organizationList && props.organizationList.length === 1
            ? props.organizationList[0]
            : "",
        enabled: true,
        mobile_login: false,
        dsr_enabled: false,
        Attachment_url: "",
        boolean: false,
        credentials: [{ type: "password", value: "", temporary: false }],
      },
    };
  }

  const handlFileUpload = (value, setFieldValue, event) => {
    setFieldValue("boolean", true);

    // event.preventDefault();
    let formData = new FormData();
    formData.append("file", value);

    let access_tocken = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";

    fetch(`${environment.api.baseApi}/file_upload/add`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + access_tocken,
        // Accept: "application/json",
        // "Content-Type": "application/json",
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((response) => {
        setFieldValue("Attachment_url", response.url);
        setFieldValue("boolean", false);
      });
  };

  const clearFile = (setFieldValue) => {
    document.querySelector("input[type='file']").value = "";
    setFieldValue("Attachment_url", "");
  };

  // handleClear function
  const handleClear = () => {
    document.querySelector("input[type='file']").value = "";
    // setLoading(false);
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <Formik
        initialValues={initial}
        validationSchema={validationSchema}
        onSubmit={(values, formikHelpers) => {
          setLoading(true);
          let obj = {
            user_data: {
              email: values.user_data.email,
              username: values.user_data.username,
              firstName: values.user_data.firstName,
              lastName: values.user_data.lastName,
              enabled: values.user_data.enabled,
              attributes: {
                organization_id: values.user_data.organization.organization_id,
                mobile_login: values.user_data.mobile_login,
                dsr_enabled: values.user_data.dsr_enabled,
                user_profile_url: values.Attachment_url,
              },
              credentials: values.user_data.credentials,
            },
          };

          let obj_edit_data = {
            firstName: values.user_data.firstName,
            lastName: values.user_data.lastName,
            enabled: values.user_data.enabled,
            attributes: {
              organization_id: values.user_data.organization.organization_id,
              mobile_login: values.user_data.mobile_login,
              user_profile_url: values.Attachment_url,
              dsr_enabled: values.user_data.dsr_enabled,
            },
          };

          // parameter for showing list based on the modified_by
          let param = {};

          Object.assign(
            param,
            { order_by: '[["createdTimestamp", "desc"]]' },
            props.user_name && { search: `${props.user_name}` }
          );

          if (props.edit) {
            props
              .dispatch(
                UserAction.requestPutUser(obj_edit_data, props.userEditData.id)
              )
              .then(() => {
                props.dispatch(UserAction.requestUser(param));

                let filterParams = {
                  order_by: '[["createdTimestamp", "desc"]]',
                };
                props.dispatch(UserAction.requestUserFilter(filterParams));
                props.onClose();
                setLoading(false);
              });
          }

          // /       ****************************************   Post method
          else {
            const callBack = () => {
              props.onNavigateProject(obj.user_data.username);
            };

            props
              .dispatch(UserAction.requestPostUser(obj, callBack))
              .then(() => {
                const params = {
                  order_by: '[["createdTimestamp", "desc"]]',
                };
                props.dispatch(UserAction.requestUser(params));
                props.dispatch(UserAction.requestUserFilter(params));
                props.onClose();
                setLoading(false);
              });
          }

          // formikHelpers.resetForm();
        }}
      >
        {({
          values,
          errors,
          isValid,
          touched,
          dirty,
          handleChange,
          setFieldValue,
        }) => (
          <Form>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DialogContentText sx={{ width: "80%" }}>
                <div className="text-center pb-2">
                  <div className="row p-3">
                    <div className="col-10">
                      <h3 className="dailogtext">
                        <b>
                          {!props.edit ? (
                            <>
                              <span className="span_first_letter">A</span>dd{" "}
                            </>
                          ) : (
                            <>
                              <span className="span_first_letter">E</span>dit{" "}
                            </>
                          )}
                          <span className="span_first_letter">U</span>ser{" "}
                          <span className="span_first_letter">D</span>etails
                        </b>{" "}
                      </h3>
                    </div>
                    <div
                      className="col-2 closebtn"
                      onClick={() => {
                        props.onClose();
                      }}
                    >
                      <i
                        className="fa fa-times-circle-o fa-2x"
                        title="Close"
                        style={{ size: "15px" }}
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                </div>
              </DialogContentText>
              {/* ******************************************** Email */}
              {!props.edit ? (
                <Grid container mt={1}>
                  <Grid item lg={12} md={12} sm={12} container>
                    <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                      Email * :
                    </Grid>
                    <Grid item lg={5} md={5} sm={6}>
                      <Field
                        variant="outlined"
                        id="email"
                        autoFocus
                        as={MuiTextField}
                        size="small"
                        name="user_data.email"
                        placeholder="Email"
                        error={Boolean(
                          getIn(touched, "user_data.email") &&
                            getIn(errors, "user_data.email")
                        )}
                        helperText={
                          getIn(touched, "user_data.email") &&
                          getIn(errors, "user_data.email")
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
              {/* ******************************************** UserName */}
              {!props.edit ? (
                <Grid container mt={1}>
                  <Grid item lg={12} md={12} sm={12} container>
                    <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                      UserName * :
                    </Grid>
                    <Grid item lg={5} md={6} sm={6}>
                      <Field
                        variant="outlined"
                        size="small"
                        id="username"
                        as={MuiTextField}
                        handleKeyPress={(e) => {
                          const inputValue = e.target.value;
                          if (inputValue === "" && e.key === " ") {
                            e.preventDefault();
                          }
                        }}
                        name="user_data.username"
                        placeholder="User Name"
                        error={Boolean(
                          getIn(touched, "user_data.username") &&
                            getIn(errors, "user_data.username")
                        )}
                        helperText={
                          getIn(touched, "user_data.username") &&
                          getIn(errors, "user_data.username")
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
              {/* ******************************************** First NAme */}
              <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    First Name * :
                  </Grid>
                  <Grid item lg={5} md={6} sm={6}>
                    <Field
                      variant="outlined"
                      id="firstName"
                      as={MuiTextField}
                      handleKeyPress={(e) => {
                        const inputValue = e.target.value;
                        if (inputValue === "" && e.key === " ") {
                          e.preventDefault();
                        }
                      }}
                      size="small"
                      name="user_data.firstName"
                      placeholder="First Name"
                      error={Boolean(
                        getIn(touched, "user_data.firstName") &&
                          getIn(errors, "user_data.firstName")
                      )}
                      helperText={
                        getIn(touched, "user_data.firstName") &&
                        getIn(errors, "user_data.firstName")
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* ******************************************** Last NAme */}
              <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Last Name * :
                  </Grid>
                  <Grid item lg={5} md={6} sm={6}>
                    <Field
                      variant="space-evenly"
                      size="small"
                      id="lastName"
                      as={MuiTextField}
                      handleKeyPress={(e) => {
                        const inputValue = e.target.value;
                        if (inputValue === "" && e.key === " ") {
                          e.preventDefault();
                        }
                      }}
                      name="user_data.lastName"
                      placeholder="Last Name"
                      error={Boolean(
                        getIn(touched, "user_data.lastName") &&
                          getIn(errors, "user_data.lastName")
                      )}
                      helperText={
                        getIn(touched, "user_data.lastName") &&
                        getIn(errors, "user_data.lastName")
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* ******************************************** Password */}
              {!props.edit ? (
                <Grid container mt={1}>
                  <Grid item lg={12} md={12} sm={12} container>
                    <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                      Password * :
                    </Grid>
                    <Grid item lg={5} md={6} sm={6}>
                      <Field
                        variant="outlined"
                        size="small"
                        as={MuiTextField}
                        type={showPassword ? "password" : "text"}
                        id="user_data"
                        name="user_data.credentials[0].value"
                        placeholder="Password"
                        error={Boolean(
                          getIn(touched, "user_data.credentials") &&
                            getIn(errors, "user_data.credentials")
                        )}
                        // helperText={
                        //   getIn(touched, "user_data.credentials") &&
                        //   getIn(errors, "user_data.credentials")
                        // }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton size="small">
                                <LockIcon transform="scale(0.8)" />
                              </IconButton>
                            </InputAdornment>
                          ),

                          onKeyPress: (event) => {
                            const { key } = event;
                            if (key === "Enter") {
                              buttonForLoginRef.current.click();
                            }
                          },

                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                size="small"
                                sx={{ mr: 1 }}
                              >
                                {showPassword ? (
                                  <VisibilityOff transform="scale(0.8)" />
                                ) : (
                                  <Visibility transform="scale(0.8)" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <ErrorMessage name="user_data.credentials[0].value"></ErrorMessage>
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
              {/* ******************************************** Organization */}
              {/* {!props.edit ? ( */}
              <Grid container mt={-1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={3}>
                    Organization * :
                  </Grid>
                  <Grid item lg={5} md={6} sm={6}>
                    <Field
                      name="user_data.organization"
                      size="small"
                      component={AutoCompleteMultiSelect}
                      // multiple
                      getOptionLabel={(option) =>
                        option.organization_name ? option.organization_name : ""
                      }
                      disabled={
                        props.organizationList &&
                        props.organizationList.length === 1
                          ? true
                          : false
                      }
                      options={
                        props.organizationList ? props.organizationList : []
                      }
                      filterOptions={createFilterOptions({
                        matchFrom: "start",
                        stringify: (option) => option.organization_name,
                      })}
                      isOptionEqualToValue={(value, option) =>
                        value.organization_name === option.organization_name
                      }
                      value={
                        values.user_data ? values.user_data.organization : null
                      }
                      textFieldProps={{
                        margin: "normal",
                        variant: "outlined",
                        placeholder: "Organization",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* ) : null} */}
              {/* ***********************************************  Enabled  */}
              {/* <FormGroup> */}

              <Grid container>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  container
                  // className="bg-warning"
                >
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Profile Picture :
                  </Grid>
                  <Grid
                    item
                    container
                    lg={5}
                    md={6}
                    sm={6}
                    alignItems={"center"}
                    justifyContent="flex-start"
                  >
                    <Grid
                      item
                      // className="bg-success"
                      xl={10}
                      textAlign="left"
                      sx={{
                        overflow: "hidden",
                      }}
                    >
                      <input
                        disabled={values.boolean}
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          handlFileUpload(e.target.files[0], setFieldValue, e);
                        }}
                      />
                    </Grid>
                    {values.Attachment_url && (
                      <Grid
                        item
                        // className="bg-info"
                        xl={2}
                        textAlign="center"
                      >
                        <IconButton className="m-0 p-0" title="Remove">
                          <CloseIcon
                            style={{
                              width: "13px",
                            }}
                            onClick={() => {
                              clearFile(setFieldValue);
                            }}
                          />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid container mt={2}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    User Enable :
                  </Grid>
                  <Grid item lg={5} md={6} sm={6}>
                    <FormControlLabel
                      control={<Checkbox checked={values.user_data.enabled} />}
                      label="Enable/Disable"
                      name="user_data.enabled"
                      value={values.enabled}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    DSR Enable:
                  </Grid>
                  <Grid item lg={5} md={6} sm={6}>
                    <FormControlLabel
                      control={
                        <Checkbox checked={values.user_data.dsr_enabled} />
                      }
                      label="Enable/Disable"
                      name="user_data.dsr_enabled"
                      value={values.dsr_enabled}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Allow Mobile Login:
                  </Grid>
                  <Grid item lg={5} md={6} sm={6}>
                    <FormControlLabel
                      control={
                        <Checkbox checked={values.user_data.mobile_login} />
                      }
                      label="Enable/Disable"
                      name="user_data.mobile_login"
                      value={values.mobile_login}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
              >
                <Grid item lg={4.8} md={5} sm={5} mb={3}>
                  {!props.edit ? (
                    <MuiButton
                      name={"Add User"}
                      type="submit"
                      width="100%"
                      disabled={values.boolean}
                      loading={loading}
                      buttonRef={buttonForLoginRef}
                    />
                  ) : (
                    <MuiButton
                      name={"Submit"}
                      type="submit"
                      width="100%"
                      disabled={values.boolean}
                      loading={loading}
                      buttonRef={buttonForLoginRef}
                    />
                  )}
                </Grid>

                <Grid item lg={4.8} md={5} sm={5} mb={3}>
                  {!props.edit ? (
                    <MuiButton
                      name={"Reset"}
                      type="reset"
                      onClick={handleClear}
                      width="100%"
                    />
                  ) : (
                    <MuiButton
                      name={"Cancel"}
                      onClick={() => {
                        props.onClose();
                        // Formik.resetForm();
                      }}
                      width="100%"
                    />
                  )}
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default connect(mapDispatchToProps)(UserPost);
