import React, { useState, useEffect } from "react";
import "react-js-cron/dist/styles.css";
import MuiTextField from "../../../components/muiTextField/MuiTextField";
import "antd/dist/antd.css";
import CronPicker from "react-js-cron";

export default function CronComponent(props) {
  const { values, name, setFieldValue } = props; // Destructure props for clarity
  const [value, setValue] = useState(values);
  const [error, setError] = useState();

  return (
    <div className="cron-component">
      <div>
        {/* Display the current cron value in a readonly field */}
        <MuiTextField value={value} readOnly={true} />

        {/* Cron editor for managing cron expression */}
        <CronPicker
          value={value}
          setValue={(newValue) => {
            setValue(newValue);
            props.onChange(newValue);
          }}
          onError={setError}
          style={cronStyles.container}
        />
        {/* Display any error messages */}
        {error && (
          <p style={{ marginTop: 20, color: "red" }}>
            Error: {error.description}
          </p>
        )}
      </div>
    </div>
  );
}
const cronStyles = {
  container: {
    padding: "16px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    backgroundColor: "#f9f9f9",
    fontSize: "14px",
    color: "#333",
  },
  dropdown: {
    border: "1px solid #ddd",
    borderRadius: "4px",
    padding: "8px",
    backgroundColor: "#fff",
  },
  error: {
    color: "red",
    fontSize: "12px",
  },
};
