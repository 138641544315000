import BaseReducer from "../../../utilities/BaseReducer";
import TicketsAction from "./TicketsAction";

export default class SelectAllTicketsReduser extends BaseReducer {
  //initial state of ticket list
  initialState = {
    ticketsSelectAll: [],
  };

  //ticket request action finish
  [TicketsAction.REQUEST_TICKETS_SELECT_ALL_FINISHED](state, action) {
    return {
      ...state,
      ticketsSelectAll: action.payload,
    };
  }

  [TicketsAction.REQUEST_TICKETS_SELECT_ALL_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      ticketsSelectAll: action.payload,
    };
  }
}
