import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { IconButton } from "@mui/material";
import { connect } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import ProjectAction from "../../stores/project/projectAction";
import PriorityAction from "../../stores/priority/PriorityAction";
import AutoCompleteComponent from "../../../components/autoCompleteComponent/Autocomplete";
import MuiButton from "../../../components/muiButton/MuiButton";
import PostPlusButton from "../../../components/postPlusButton/PostPlusButton";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import AutoResponsePost from "./AutoResponsePost";
import AutoresponseAction from "../../stores/autoResponse/AutoresponseAction";
import StatusAction from "../../stores/status/StatusAction";
import RolesAction from "../../../common/stores/roles/RolesAction";
import UserAction from "../../stores/user/UserAction";
import CustomTicketFieldsAction from "../../../stores/customticketfields/CustomTicketFieldsAction";
import TagAction from "../../stores/tag/TagAction";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    AutoresponseAction.REQUEST_AUTORESPONSE,
    AutoresponseAction.REQUEST_AUTORESPONSE_FILTER,
    ProjectAction.REQUEST_PROJECT,
    PriorityAction.REQUEST_PRIORITY_FILTER,
    CustomTicketFieldsAction.REQUEST_CUSTOMTICKETFIELDS_FILTER,
    TagAction.REQUEST_TAG_FILTER,
  ]),
  autoResponse: state.autoResponse.autoResponse || [],
  autoResponseFilter: state.autoResponseFilter.autoResponseFilter || [],
  pagination: state.autoResponse.autoResponse || [],
  project: state.project.project || [],
  priority: state.filterpriority.filterpriority.hits || [],
  roles: state.roles.roles || [],
  user: state.user.user || [],
  automationFields: state.automationFields.automationFields || [],
  automationOperations: state.automationOperations.automationOperations || [],
  customticketfields_filter:
    state.customticketfields_filter.customticketfields_filter || {},
  tagfilter: state.tagfilter.tagfilter || [],
});

function AutoResponse(props) {
  const { isRequesting } = props;

  const [page_limit] = useState(50);
  const [open, setOpen] = useState(false);
  const [editRowsModel, setEditRowsModel] = useState({});
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);
  const [edit, setEdit] = useState(false);
  const [ruleName, setRuleName] = useState(null);

  function fetchAutoresponse(params) {
    props.dispatch(AutoresponseAction.requestAutorespons(params));
  }

  function fetchAutoresponseFilter(params) {
    props.dispatch(AutoresponseAction.requestAutoresponsFilter(params));
  }

  function fetchProject(params) {
    props.dispatch(ProjectAction.requestProject(params));
  }

  // filter priority dispatch function
  function fetchFilterPriority() {
    const priority_param = {
      page_limit: "none",
      order_by: `[["ticket_priority_name.keyword", "asc"]]`,
    };

    props.dispatch(PriorityAction.filterRequestPriority(priority_param));
  }

  function fetchStatusFilter(param) {
    props.dispatch(StatusAction.requestStatusFilter(param));
  }

  function fetchCustomticketfieldsFilter() {
    const paramater = {
      page_limit: "none",
    };

    props.dispatch(
      CustomTicketFieldsAction.requestCustomTicketFieldsFilter(paramater)
    );
  }

  function fetchTagFilter(param) {
    props.dispatch(TagAction.requestTagFilter(param));
  }

  // roles
  function fetch_role(params) {
    props.dispatch(RolesAction.requestRole(params));
  }

  function fetch_user(params) {
    props.dispatch(UserAction.requestUser(params));
  }

  function fetchAutomationFields() {
    props.dispatch(AutoresponseAction.requestAutomationFields());
  }

  function fetchAutomationOperations() {
    props.dispatch(AutoresponseAction.requestAutomationOperations());
  }

  useEffect(() => {
    fetchAutomationFields();
    fetchAutomationOperations();
    let project = JSON.parse(localStorage.getItem("user_last_working_project"));

    const params = {
      order_by: `[["order_number","asc"]]`,
      page_limit: `${page_limit}`,
    };
    const param = {
      order_by: `[["automation_name.keyword", "asc"]]`,
      page_limit: "none",
    };

    fetchAutoresponse(params);
    fetchAutoresponseFilter(param);
    fetchCustomticketfieldsFilter();

    const priority_param = {
      page_limit: "none",
      order_by: `[["ticket_priority_name.keyword", "asc"]]`,
    };

    fetchFilterPriority(priority_param);

    const tagParam = {
      page_limit: "none",
      order_by: `[["order_number","asc"]]`,
    };
    fetchTagFilter(tagParam);

    // status api
    const status_param = {
      page_limit: "none",
      order_by: `[["status_name.keyword", "asc"]]`,
    };

    fetchStatusFilter(status_param);
    const platform_param = {
      page_limit: "none",
    };

    const role_param = {
      page_limit: "none",
      filters: `[["project_id.keyword","must","","terms",["*",${JSON.stringify(
        project.project_id
      )}]]]`,
    };

    fetch_role(role_param);
    fetch_user(platform_param);

    let project_id = JSON.parse(
      localStorage.getItem("user_last_working_project")
    );

    const project_param = {
      filters: `[["_id","must","","match","${
        project_id ? project_id.project_id : ""
      }"]]`,
    };

    fetchProject(project_param);
    fetchFilterPriority();
  }, []);

  useEffect(() => {
    if (!isRequesting) {
      setFilterLoader(false);
      setClearLoader(false);
    }
  }, [isRequesting]);

  let orgId =
    props.project.hits &&
    props.project.hits.hits.map((item) => {
      return item._source.organization_id.organization_id;
    });

  // const priorityList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const handleRuleNameChange = (e, val) => {
    setRuleName(val);
  };

  let platformList = [];

  if (props.project?.hits) {
    props.project.hits?.hits?.map((item) => {
      item._source.platform.map((val) => {
        platformList.push({
          id: val.platform_id,
          name: val.platform_name,
        });
      });
    });
  }

  const tagList = [];
  let filterTagList = [];

  if (props.tagfilter?.hits) {
    props.tagfilter.hits?.hits?.map((item) => {
      tagList.push({
        id: item._id,
        name: item._source.tag_name,
      });
    });
  }
  filterTagList = [...new Set(tagList)];

  const workingStatusList = [
    {
      id: "true",
      name: "Online",
    },
    {
      id: "false",
      name: "Offline",
    },
  ];

  let RoleList = [];

  if (props.roles.hits) {
    props.roles.hits.hits.map((item) => {
      return RoleList.push({
        role_id: item._id,
        role_name: item._source.role_name,
      });
    });
  }

  let priority_list = [];

  props.priority &&
    props.priority.hits &&
    props.priority.hits.map((val) => {
      priority_list.push({
        id: val._id,
        name: val._source.ticket_priority_name,
      });
    });

  let AgentList = [];

  if (props.user?.data) {
    props.user.data.map((item) => {
      return AgentList.push({
        id: item.id,
        name: item.username,
      });
    });
  }

  const handleFilter = () => {
    const filter = [];
    if (ruleName && ruleName.rule_name !== null) {
      filter.push(
        `["automation_name.keyword","must","","match","${ruleName.rule_name}"]`
      );
    }

    if (ruleName !== null) {
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);

      const params = {
        order_by: `[["order_number","asc"]]`,
        page_limit: `${page_limit}`,
        filters: `[${filter}]`,
      };
      fetchAutoresponse(params);
    } else {
      alert("Please add filters");
      setClearLoader(false);
    }
  };

  const ClearState = () => {
    if (ruleName !== null) {
      setRuleName(null);
      setFilterClicked(false);

      const params = {
        order_by: `[["order_number","asc"]]`,
        page_limit: `${page_limit}`,
      };

      fetchAutoresponse(params);
    }
  };

  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let filter_params = {};
    const filter = [];

    if (ruleName !== null) {
      filter.push(
        `["automation_name.keyword","must","","match","${ruleName.rule_name}"]`
      );
    }

    if (FilterClicked) {
      if (ruleName !== null) {
        filter_params = {
          order_by: '[["order_number", "asc"]]',
          filters: `[${filter}]`,
          page_number: `${value}` - 1,
          page_limit: `${page_limit}`,
        };
      }
    } else {
      filter_params = {
        order_by: '[["order_number", "asc"]]',
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
      };
    }
    if (value - 1 !== props.pagination.current_page_no) {
      fetchAutoresponse(filter_params);
    }
  };

  function updateRowPosition(oldIndex, targetIndex, rows) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const rowsClone = [...rows];

        const row = rowsClone.splice(oldIndex, 1)[0];
        rowsClone.splice(targetIndex, 0, row);
        resolve(rowsClone);
      }, 100);
    });
  }

  const rowOrderHandleChange = async (params) => {
    const newRows = await updateRowPosition(
      params.oldIndex,
      params.targetIndex,
      rows
    );

    const newArr = newRows.map((obj, index) => {
      return {
        ...obj,
        order_number:
          props.pagination &&
          props.pagination.current_page_no * page_limit + ++index,
      };
    });

    let data = [];

    const bulkData = { data };

    newArr.map((item) => {
      data.push({
        _index: item._index,
        _id: item.id,
        _source: {
          order_number: item.order_number,
        },
      });
    });

    const paramss = {
      order_by: `[["order_number","asc"]]`,
      page_limit: `${page_limit}`,
    };
    const param = {
      order_by: `[["automation_name.keyword", "asc"]]`,
      page_limit: "none",
    };

    data &&
      props
        .dispatch(AutoresponseAction.requestPutAutoresponseBulkUpdate(bulkData))
        .then(() => {
          props.dispatch(AutoresponseAction.requestAutorespons(paramss));
          props.dispatch(AutoresponseAction.requestAutoresponsFilter(param));
        });
  };

  const openCloseModal = useCallback((open) => {
    setOpen(open);
  }, []);

  const editMode = useCallback((edit) => {
    setEdit(false);
  }, []);

  const columns = [
    {
      field: "sl_no",
      headerName: "SL No",
      width: 150,
      flex: 1,
    },
    {
      field: "automation_name",
      headerName: "Automation Name",
      width: 250,
      flex: 3,
    },

    {
      field: "created_by",
      headerName: "Created By",
      width: 250,
      flex: 3,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Edit",
      width: 160,
      flex: 2,
      cellClassName: "actions",
      renderCell: (params) => (
        <IconButton
          title="Edit"
          sx={{ border: "0" }}
          onClick={(event) => {
            event.ignore = true;
            setEdit(true);
            setOpen(true);
            setEditRowsModel(params.row);
          }}
        >
          <FontAwesomeIcon
            icon={faPenToSquare}
            style={{ height: "20px", width: "20px", color: "#2a5e6c" }}
          ></FontAwesomeIcon>
        </IconButton>
      ),
    },
  ];

  const rows = [];

  const returnPriorityObject = (priority_id) => {
    let filteredPriorityObject =
      priority_list &&
      priority_list.length !== 0 &&
      priority_list.filter((val) => {
        return val.id === priority_id;
      });

    return filteredPriorityObject && filteredPriorityObject.length !== 0
      ? filteredPriorityObject[0]
      : "";
  };

  if (props.autoResponse.hits) {
    props.autoResponse.hits.hits &&
      props.autoResponse.hits.hits.length &&
      props.autoResponse.hits.hits.map((item, index) => {
        return rows.push({
          sl_no: item._source.order_number,
          id: item._id,
          _index: item._index,
          _score: item._score,
          automation_name: item._source.automation_name,
          cron_job: item._source?.cron_job,
          actions: item._source?.actions,
          auto_response: item._source?.auto_response,
          notification: item._source?.notification,
          mail_trigger: item._source?.mail_trigger,
          auto_assignment: item._source?.auto_assignment,
          created_by: item._source.created_by.user_name,
          order_number: item._source.order_number,
          enabled: item._source.enabled,
          conditions: item._source.conditions,
        });
      });
  }

  let rule_name_list = [];
  if (props.autoResponseFilter.hits) {
    props.autoResponseFilter.hits.hits.length &&
      props.autoResponseFilter.hits.hits.map((item) => {
        rule_name_list.push({
          rule_id: item._id,
          rule_name: item._source.automation_name,
        });
      });
  }

  let rule_name_filtered_list = [];
  rule_name_filtered_list = [...new Set(rule_name_list)];

  // const uniqueList = ["Active", "InActive", "All"];

  return (
    <React.Fragment>
      <Box
        className="scrollable"
        style={{
          height: "100%",
          maxHeight: `${window.innerHeight}px`,
          overflow: "auto",
          position: "relative",
        }}
      >
        {open && (
          <AutoResponsePost
            open={open}
            onSubmit={() => {}}
            onClose={() => {
              setOpen(false);
            }}
            // orgId={JSON.stringify(orgId[0])}
            editData={editRowsModel}
            edit={edit}
            setEdit={setEdit}
            platformList={platformList}
            priority_list={priority_list}
            RoleList={RoleList}
            AgentList={AgentList}
            tagList={filterTagList}
            workingStatusList={workingStatusList}
          />
        )}

        {!open && (
          <Box>
            <Grid container>
              <Grid
                container
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-between"
                mt={1}
              >
                <Grid item lg={6} md={7} sm={7} container p={1}>
                  <Grid item lg={4.5} md={5} sm={5} p={1} mt={0}>
                    <AutoCompleteComponent
                      value={ruleName}
                      list={rule_name_filtered_list}
                      textFieldProps={{
                        placeholder: "Automation Name",
                      }}
                      getOptionLabel={(option) => option.rule_name}
                      handleChange={handleRuleNameChange}
                      size="small"
                      width="100%"
                    />
                  </Grid>
                </Grid>
                <Grid item lg={6} md={6} sm={5}>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    container
                    spacing={2}
                    justifyContent="flex-end"
                    mt={0}
                  >
                    <Grid
                      lg={8}
                      sm={8}
                      md={8}
                      display={"flex"}
                      justifyContent={"end"}
                      mt={1}
                      mr={2}
                      sx={{ gap: 2 }}
                    >
                      <Grid lg={3} md={3} sm={3}>
                        <MuiButton
                          name={"Filter"}
                          width="100%"
                          onClick={handleFilter}
                          loading={filterLoader && isRequesting}
                        />
                      </Grid>
                      <Grid lg={3} md={3} sm={3}>
                        <MuiButton
                          width="100%"
                          loading={clearLoader && isRequesting}
                          name={"Clear"}
                          onClick={ClearState}
                        />
                      </Grid>
                    </Grid>
                    <Grid>
                      <PostPlusButton
                        open={open}
                        openCloseModal={openCloseModal}
                        editMode={editMode}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* data grid */}
            <Grid item lg={12} md={12} sm={12} xs={12} pr={1}>
              <LoadingIndicator isActive={isRequesting}>
                {props.autoResponse.hits && !isRequesting ? (
                  <DataGridFunction
                    rows={rows}
                    columns={columns}
                    rowHeight={60}
                    height="70vh"
                    rowReordering
                    onRowOrderChange={rowOrderHandleChange}
                    pagelength={props.pagination.total_page_no}
                    page={props.pagination.current_page_no}
                    handleChange={handleChange}
                  />
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      height: "70vh",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <LoadingBgImage />
                  </Box>
                )}
              </LoadingIndicator>
            </Grid>
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
}
export default connect(mapStateToProps)(AutoResponse);
