/*
 * Base is the default environment for production.
 * Add everything here and override value in other files if needed.
 * https://blog.usejournal.com/my-awesome-custom-react-environment-variables-setup-8ebb0797d8ac
 */

let surveyEndpoints = (surveyApi) => {
  return {
    // survey
    survey: `${surveyApi}/survey`,
    surveyFilter: `${surveyApi}/survey`,

    //survey user
    surveyUser: `${surveyApi}/survey_user`,
    surveyUserFilter: `${surveyApi}/survey_user`,

    //survey data
    surveyData: `${surveyApi}/questions_answers`,
    surveyDataFilter: `${surveyApi}/questions_answers`,

    // dashboard
    surveyDashboard: `${surveyApi}/surveyManagementDashboard`,

    // survey management report
    surveyReport: `${surveyApi}/surveyManagementreport`,

    // survey mobileapp device reset endpoint
    surveyDeviceMapping: `${surveyApi}/survey_device_mapping`,

    //surveyUserMapping
    surveyUserMApping: `${surveyApi}/survey_user_mapping`,

    // questions
    questions: `${surveyApi}/questions`,
    questionsFilter: `${surveyApi}/questions`,
    questionsBulkUpdate: `${surveyApi}/surveyQuestionsBulkUpdate`,

    //resetPassword
    surveyAdminResetPassword: `${surveyApi}/v1/api/survey_admin_reset_password`,
    surveyUserResetPassword: `${surveyApi}/v1/api/survey_user_reset_password`,
  };
};

let influencerEndpoints = ({
  baseApi,
  influencersAPI,
  influencerSchedulerApi,
}) => {
  return {
    influencersAPI: `${influencersAPI}`,

    // Influencers Campaigns
    influencersCampaign: `${influencersAPI}/influencer_campaign`,
    influencersCampaignFilter: `${influencersAPI}/influencer_campaign`,

    // Influencers Activity
    influencersActivity: `${influencersAPI}/influencer_activity`,
    influencersActivityFilter: `${influencersAPI}/influencer_activity`,

    // Influencer User
    influencerUser: `${influencersAPI}/influencer_user`,
    influencerUserFilter: `${influencersAPI}/influencer_user`,

    //Influencer dashboard
    influencerDashboard: `${influencersAPI}/manager_dashboard`,

    //Influencer Reports
    influencerReports: `${influencersAPI}/reports_on_influencer`,
    campaignReports: `${influencersAPI}/reports_on_campaign`,
    InfluencerAgentReports: `${influencersAPI}/reports_on_agent`,
    influencerActivityReports: `${influencersAPI}/reports_on_activity`,

    // admin Reset Password
    influencerAdminResetPassword: `${influencersAPI}/v1/api/influencer_admin_reset_password`,

    // user Reset Password
    influencerUserResetPassword: `${influencersAPI}/v1/api/userResetPassword`,

    // campaign mapping
    campaignMapping: `${influencersAPI}/influencer_campaign_mapping`,
    campaignMappingFilter: `${influencersAPI}/influencer_campaign_mapping`,

    // device mapping
    influencerDeviceMapping: `${influencersAPI}/influencer_device_mapping`,

    //influencer activity type
    activityType: `${influencersAPI}/influencer_activity_type`,

    // influencer platforms
    influencerPlatformAccounts: `${influencersAPI}/influencer_platform_accounts`,
    influencerPlatformAccountsFilter: `${influencersAPI}/influencer_platform_accounts`,
    influencerFacebookPlatform: `${influencersAPI}/influencer_facebook_account`,
    influencerInstagramPlatform: `${influencersAPI}/influencer_instagram_account`,
    influencerYoutubePlatform: `${influencersAPI}/influencer_youtube_account`,
    influencerQuoraPlatform: `${influencersAPI}/influencer_quora_account`,
    influencerPlaystorePlatform: `${influencersAPI}/influencer_playstore_account`,
    influencerTwitterPlatform: `${influencersAPI}/influencer_twitter_account`,
    influencerWhatsappPlatform: `${influencersAPI}/influencer_whatsapp_account`,
    influencerAmbitionBoxPlatform: `${influencersAPI}/influencer_ambitionbox_account`,
    influencerAppstorePlatform: `${influencersAPI}/influencer_appstore_account`,
    influencerGlassdoorPlatform: `${influencersAPI}/influencer_glassdoor_account`,
    influencerTelegramAccount: `${influencersAPI}/influencer_telegram_account`,
    influencerEmailAccount: `${influencersAPI}/influencer_email_account`,

    influencersLanguage: `${influencersAPI}/influencer_languages`,

    //Influencer Platform Scheduling
    scheduling: `${baseApi}/scheduling`,
    influencerPlatformScheduling: `${influencerSchedulerApi}/orm_platform_scheduling`,
  };
};

let dataExtractionEndpoints = (dataExtractionApi) => {
  return {
    dataExtractionApi: `${dataExtractionApi}`,
    // youtube dataextraction
    youTubeDataExtraction: `${dataExtractionApi}/DataExtraction`,

    //setUser
    setuser: "http://localhost:5000/setuser",
    dataExtractionProjects: `${dataExtractionApi}/projects`,
    DataExtractionAll: `${dataExtractionApi}/DataExtractionAll`,
  };
};

let siteDiscoveryEndpoints = (siteDiscovery) => {
  return {
    // WebsiteLinks
    websiteLinks: `${siteDiscovery}/websiteUrl`,
    websiteLinksFilter: `${siteDiscovery}/websiteUrl`,

    // ScrapeLinks
    scrapeLinks: `${siteDiscovery}/scrapelinks`,
    scrapeLinksFilter: `${siteDiscovery}/scrapelinks`,
  };
};

let mcnEndpoints = (mcnApi, competitionalAnalyticsApi, youtubeMonitorApi) => {
  return {
    //ChannelAnalytics
    channelAnalytics: `${mcnApi}/channelanalytics`,

    //ChannelAnalytics Account
    channelAnalyticsAccount: `${mcnApi}/channel_analytics_account`,
    channelAnalyticsAccountFilter: `${mcnApi}/channel_analytics_account`,

    //Mcn Monitoring
    mcnMonitoring: `${competitionalAnalyticsApi}/youtube_competitor`,
    mcnMonitoringFilter: `${competitionalAnalyticsApi}/youtube_competitor`,

    // Mcn Feeds
    mcnFeeds: `${competitionalAnalyticsApi}/youtube_competational_analysis`,
    mcnFeedsFilter: `${competitionalAnalyticsApi}/youtube_competational_analysis`,

    youtubeVideosWebhook: `${youtubeMonitorApi}/youtube_videos_webhook`,
    channelMonitoringVideoListWebhook: `${youtubeMonitorApi}/youtube_channels_webhook`,
    subscribeChannel: `${youtubeMonitorApi}/subscribe`,

    ytVideosShadow: `${youtubeMonitorApi}/yt_videos_shadow`,

    youtubePostData: `http://localhost:7000/get_youtube_data`,
  };
};

let cloudMailEndpoints = (cloudMailApi) => {
  return {
    // CLOUD Mail apis
    // app status
    appStatus: `${cloudMailApi}/App_status`,
    // cloud mail organization
    cloudMailOrg: `${cloudMailApi}/cloudmail_get_organization`,

    // cloud mail get data mailing list
    cloudMailList: `${cloudMailApi}/cloudmail_get_data`,

    // cloud mail get data segment criteria
    segmentCriteria: `${cloudMailApi}/cloudmail_get_data`,

    // Cloud Mail
    cloudMail: `${cloudMailApi}/cloud_mail`,
    cloudMailFilter: `${cloudMailApi}/cloud_mail`,
    // campaignList
    campaignList: `${cloudMailApi}/cloudmail_campaigns`,
    campaignSchedule: `${cloudMailApi}/Campaign_schedule`,
  };
};

let openAiEndpoints = (baseApi, openAiApi) => {
  return {
    // OpenAi chat
    getOpenAiChat: `${baseApi}/openai_feed`,
    postOpenAiChat: `${openAiApi}/openai_feed`,
  };
};

let A3LabelEndpoints = (a3labelApi, baseApi, a3mailserviceApi) => {
  return {
    a3labelApi: `${a3labelApi}`,
    //A3 Labels
    statusA3: `${a3labelApi}/musiclabels_release_status`,
    batchA3: `${a3labelApi}/musiclabels_batch`,
    inrRates: `${a3labelApi}/musiclabels_inr_usd_exchange_rates`,
    statusFilterA3: `${a3labelApi}/musiclabels_release_status`,
    label: `${a3labelApi}/musiclabels_label`,
    labelFilter: `${a3labelApi}/musiclabels_label`,
    assets: `${a3labelApi}/musiclabels_assets`,
    assetsFilter: `${a3labelApi}/musiclabels_assets`,
    artist: `${a3labelApi}/musiclabels_artist`,
    artistFilter: `${a3labelApi}/musiclabels_artist`,
    releaseStatusCount: `${a3labelApi}/release_status_count`,
    trackStatusCount: `${a3labelApi}/track_status_count`,
    track: `${a3labelApi}/musiclabels_track`,
    trackAssetDsp: `${a3labelApi}/track_asset_dsp`,
    trackFilter: `${a3labelApi}/musiclabels_track`,
    syncPlayLink: `${a3labelApi}/update_track_play_link`,
    changeRequestTrack: `${a3labelApi}/musiclabels_change_request_track`,
    updateplaylink: `${a3labelApi}/update_playlink`,
    genre: `${a3labelApi}/musiclabels_genre`,
    genreFilter: `${a3labelApi}/musiclabels_genre`,
    stream_platform: `${a3labelApi}/musiclabels_stream_platform`,
    stream_platformFilter: `${a3labelApi}/musiclabels_stream_platform`,
    subgenre: `${a3labelApi}/musiclabels_subgenre`,
    subgenreFilter: `${a3labelApi}/musiclabels_subgenre`,
    clientA3: `${a3labelApi}/musiclabels_client`,
    revenueClaimAll: `${a3labelApi}/generate_musiclabels_revenue_claim_all`,
    clientFilterA3: `${a3labelApi}/musiclabels_client`,
    //  In admin side the /musiclabels_release endpoint will be /musiclabels_final_release
    release: `${a3labelApi}/musiclabels_final_release`,
    changeRequestRelease: `${a3labelApi}/musiclabels_change_request_release`,
    releaseFilter: `${a3labelApi}/musiclabels_final_release`,
    plan: `${a3labelApi}/musiclabels_plan`,
    territories: `${a3labelApi}/musiclabels_territories`,
    fileUpload: `${a3labelApi}/musiclabels_file_upload`,
    retriveAsset: `${a3labelApi}/musiclabels_retrive_asset`,
    musiclabels_retrieve_blog_image: `${a3labelApi}/musiclabels_retrieve_blog_image`,
    retriveTrackAsset: `${a3labelApi}/musiclabels_track_preview`,
    userprofile: `${a3labelApi}/user_details`,
    userResource: `${a3labelApi}/musiclabel_user_resource`,
    timezone: `${a3labelApi}/timezonedata`,
    language: `${a3labelApi}/listoflanguage`,
    errorExample: "https://httpstat.us/520",
    audioStreams: `${a3labelApi}/audio_streams`,
    topTracks: `${a3labelApi}/top_track_report`,
    topArtist: `${a3labelApi}/top_artists_report`,
    topLabel: `${a3labelApi}/top_label_report`,
    resource: `${a3labelApi}/musiclabels_resource`,
    validateSong: `${a3labelApi}/validate_song`,
    postToDsp: `${a3labelApi}/push_to_dsp`,
    blogs: `${a3labelApi}/musiclabels_blog`,
    musiclabels_blog_mail: `${a3labelApi}/musiclabels_blog_mail`,
    get_client_email_address: `${a3labelApi}/get_client_email_address`,
    releaseshadoow: `${a3labelApi}/musiclabels_release_shadow`,
    draftrelease: `${a3labelApi}/musiclabels_release`,
    initiateToken: `${a3labelApi}/musiclabels_initiate_token`,
    topReleases: `${a3labelApi}/top_releases_list`,
    topStore: `${a3labelApi}/top_stores_report`,
    top_territories_report: `${a3labelApi}/top_territories_report`,
    top_languages_report: `${a3labelApi}/top_languages_report`,
    top_composers_report: `${a3labelApi}/top_composers_report`,
    top_lyricists_report: `${a3labelApi}/top_lyricists_report`,
    top_albums_report: `${a3labelApi}/top_albums_report`,
    releasesShadowTable: `${a3labelApi}/musiclabels_release_shadow`,
    releaseValidateStatus: `${a3labelApi}/musiclabels_release_validate_status`,
    validationRelease: `${a3labelApi}/musiclabels_release_validate_status`,
    a3AdminResetPassword: `${a3labelApi}/v1/api/a3labels_client_reset_password`,
    finalReleases: `${a3labelApi}/musiclabels_final_release`,
    withdrawRelease: `${a3labelApi}/withdraw_release_from_review`,
    clientRevenueSharePolicy: `${a3labelApi}/client_revenue_sharing_policy`,
    revenueReport: `${a3labelApi}/revenue_report`,
    revenueReportFileUploader: `${a3labelApi}/revenue_report_file_uploader`,
    trackRevenue: `${a3labelApi}/musiclabels_track_revenue`,
    revenueTransaction: `${a3labelApi}/revenue_transaction`,
    ing_banner_list: `${a3labelApi}/ing_banner_list`,
    actor: `${a3labelApi}/musiclabels_actor`,
    actress: `${a3labelApi}/musiclabels_actress`,
    director: `${a3labelApi}/musiclabels_director`,
    rejectRelease: `${a3labelApi}/reject_release`,
    finalreleasestate: `${a3labelApi}/check_final_release_state`,
    youtubeContentDownload: `${a3labelApi}/musiclabels_youtube_content_download`,
    youtubeOfficial: `${a3labelApi}/youtube_cms_channel_list`,
    youtubeChannelRevenue: `${a3labelApi}/yt_channel_revenue_info`,
    syncDeliveredStores: `${a3labelApi}/sync_delivered_stores`,
    spotifyMigrateTrack: `${a3labelApi}/musiclabels_release_by_spotify`,
    appleMusicMigrateTrack: `${a3labelApi}/musiclabels_release_by_apple_music`,
    clientLabelReport: `${a3labelApi}/client_wise_label_report`,
    clientArtistReport: `${a3labelApi}/client_wise_artist_report`,
    clientReleaseReport: `${a3labelApi}/client_wise_release_report`,
    A3ClientResetDevice: `${a3labelApi}/musiclabels_mobile_device_id_mapping`,
    youtubeTrackClaims: `${a3labelApi}/youtube_track_claims`,
    youtubeAFPReports: `${a3labelApi}/youtube_afp_report`,
    storewiseRevenueReport: `${a3labelApi}/musiclabels_store_revenue`,
    releasewiseRevenue: `${a3labelApi}/musiclabels_release_wise_revenue`,
    artistwiseRevenue: `${a3labelApi}/musiclabels_artist_revenue`,
    monthwiseRevenueReport: `${a3labelApi}/net_revenue_by_month`,
    netRevenueByMonth: `${a3labelApi}/net_revenue_by_month`,
    countrywiseRevenueReport: `${a3labelApi}/revenue_by_country`,
    labelRevenueReport: `${a3labelApi}/musiclabels_label_revenue`,
    trackwiseRevenue: `${a3labelApi}/musiclabels_track_wise_revenue`,
    clientwiserevnue: `${a3labelApi}/musiclabels_client_wise_revenue`,
    mapDSPID: `${a3labelApi}/map_dsp_id`,
    trackBulk: `${a3labelApi}/musiclabels_track_bulk_update`,
    feedback: `${a3labelApi}/get_all_feedback`,
    sessionLog: `${a3labelApi}/musiclabels_session_logs`,
    sessionLogReport: `${a3labelApi}/musiclabels_session_logs_report`,
    mailLogs: `${a3labelApi}/musiclabels_mail_logs`,
    albumPlayLink: `${a3labelApi}/musiclabels_release_playlink`,
    a3Notifications: `${a3labelApi}/a3_notifications_data`,
    a3NotificationsBulkUpdate: `${a3labelApi}/a3_notifications_bulk_update`,
    netRevenueByMonth: `${a3labelApi}/net_revenue_by_month`,
    trackwiseRevenueSummary: `${a3labelApi}/musiclabels_track_wise_summary`,
    activityInsight: `${a3labelApi}/musiclabels_activity_insight`,
    activityInsightReport: `${a3labelApi}/activity_insight_report`,
    updateDelivered: `${a3labelApi}/musiclabels_update_delivered`,
    updateToIngest: `${a3labelApi}/musiclabels_update_to_injest`,
    fetchReleaseStatus: `${a3labelApi}/musiclabels_fetch_release_status`,
    fetchReleaseUpc: `${a3labelApi}/musiclabels_fetch_release_upc`,
    csvStreamingReport: `${a3labelApi}/upload_csv_streaming_report`,
    csvUploadSummary: `${a3labelApi}/musiclabels_csv_upload_summary`,
    ytOfficialRevenueReport: `${a3labelApi}/youtube_official_revenue_report`,

    // revenue claims
    revenueClaim: `${a3labelApi}/musiclabels_revenue_claim`,
    clientRevenueBalance: `${a3labelApi}/musiclabels_client_revenue_balance`,
    revenueClaimRequest: `${a3labelApi}/revenue_claim_request`,
    updateRevenueClaimRequest: `${a3labelApi}/update_revenue_claim_request`,
    getInvoiceClaimDetails: `${a3labelApi}/get_invoice_client_details`,
    generateRevenueClaim: `${a3labelApi}/generate_musiclabels_revenue_claim`,
    advancePaymentRequest: `${a3labelApi}/revenue_advance_request`,
    generateClientInvoice: `${a3labelApi}/generate_client_invoice`,

    // YT revenue report
    ytRevenueReport: `${a3labelApi}/youtube_revenue_report`,
    ytRevenueReportFileUploader: `${a3labelApi}/youtube_revenue_report_uploader`,
    revenueReportErrors: `${a3labelApi}/revenue_report_error`,
    get_configured_email_address: `${a3mailserviceApi}/get_configured_email_address`,
  };
};

let crmEndpoints = (crmApi) => {
  return {
    crmApi: `${crmApi}`,
    lead: `${crmApi}/lead`,
    task: `${crmApi}/task`,
    customer: `${crmApi}/customer`,
    opportunity: `${crmApi}/opportunity`,
    contact: `${crmApi}/contact`,
    lead_source: `${crmApi}/lead_source`,
    territory: `${crmApi}/territory`,
  };
};

let fingerPrintEndpoints = (fingerPrintApi) => {
  return {
    collection: `${fingerPrintApi}/fingerprint_collection`,
    fingerPrintAsset: `${fingerPrintApi}/fingerprint_asset`,
    fingerPrintAssets: `${fingerPrintApi}/fingerprint_assets`,
    fingerPrintAssetTrackingStatus: `${fingerPrintApi}/fingerprint_asset_tracking_status`,
    whitelist: `${fingerPrintApi}/fingerprint_whitelist`,
    copies: `${fingerPrintApi}/fingerprint_copies`,
    fingerPrintAssetSuncedCopies: `${fingerPrintApi}/fingerprint_copies_by_asset`,
    fingerPrintMultipartUpload: `${fingerPrintApi}/fingerprint_upload_multipart`,
    fingerPrintPlatforms: `${fingerPrintApi}/fingerprint_platforms`,

    //Reports Endpoints
    FPtotalAssetCount: `${fingerPrintApi}/fingerprint_total_asset_count`,
    FPtotalCollectionsCount: `${fingerPrintApi}/fingerprint_total_collections_count`,
    FPtotalCopiesCount: `${fingerPrintApi}/fingerprint_total_copies_count`,
    FPassetWiseCopies: `${fingerPrintApi}/fingerprint_copies_count_asset`,
    FPviewsPerAsset: `${fingerPrintApi}/fingerprint_views_per_asset`,
    FPactiveAssets: `${fingerPrintApi}/fingerprint_active_assets`,
    FPSearch: `${fingerPrintApi}/fingerprint_search`,
    FPacrSearch: `${fingerPrintApi}/fingerprint_acr_search`,
  };
};

let linkCheckingEndpoints = (linkCheckingApi) => {
  return {
    uploadLinks: `${linkCheckingApi}/upload_links`,
    linkValidator: `${linkCheckingApi}/linkValidator`,
    linkValidatorFilter: `${linkCheckingApi}/linkValidator_filter`,
    linkValidatorAll: `${linkCheckingApi}/linkValidator_all`,
    cmsUploadLinks: `${linkCheckingApi}/cms_upload_links`,
    deListedLink: `${linkCheckingApi}/delisted_link`,
    export_links: `${linkCheckingApi}/delisted_link_export`,
    batchLink: `${linkCheckingApi}/batch_link`,
    linkStatus: `${linkCheckingApi}/link_status`,
    batchLinkStatusReport: `${linkCheckingApi}/batch_link_status_report`,
    proxyCountries: `${linkCheckingApi}/proxy_countries`,
    linkCheckPlatforms: `${linkCheckingApi}/platforms`,
    customiseProxy: `${linkCheckingApi}/customise_proxy`,
    proxyAccountInfo: `${linkCheckingApi}/proxy_account_info`,
    proxyBulkUpdate: `${linkCheckingApi}/proxy_bulk_update`,
    LinkTransferSiteData: `${linkCheckingApi}/link_transfer_site_data`,
    cmsLinkStatusLog: `${linkCheckingApi}/cms_link_status_log`,
    cmsLinkValidator: `${linkCheckingApi}/cms_link_validator`,
    cmsProcessingStatus: `${linkCheckingApi}/cms_processing_status`,
  };
};

let dsrEndpoints = ({ dsrApi, socketApi }) => {
  return {
    dsrActivity: `${dsrApi}/dsr_activity`,
    dsrActivityShadow: `${dsrApi}/dsr_activity_shadow`,
    dsrActivityGroup: `${dsrApi}/dsr_activity_group`,

    dsrActiviyData: `${dsrApi}/activity_data`,
    dsrActiviyGroupwise: `${dsrApi}/activity_data_groupwise`,
    dsrDashboardSummary: `${dsrApi}/dashboard_summary`,
    dsrNotification: `https://${socketApi}/dsr_new_data`,
    dsrAlertTrigger: `${dsrApi}/dsr_alert_trigger`,
  };
};

let websweepxEndpoints = ({ websweepxApi }) => {
  return {
    websweepXDiscoveredData: `${websweepxApi}/discovered_data`,
    export_discovered_data: `${websweepxApi}/export_discovered_data`,
    websweepXHssDetails: `${websweepxApi}/hss_details`,
    websweepNvProject: `${websweepxApi}/nv_project`,
    googleSearchExtractor: `${websweepxApi}/googleSearchExtractor`,
    websweepXExecuteScripts: `${websweepxApi}/execute_scripts`,
    websweepXExecuteAllScripts: `${websweepxApi}/execute_all_scripts`,
    websweepXResumeScripts: `${websweepxApi}/resume_execute_script`,
    websweepXPauseScripts: `${websweepxApi}/pause_execute_script`,
    websweepXStopScripts: `${websweepxApi}/stop_execute_script`,
    websweepXReports: `${websweepxApi}/wsx_reports`,
    websweepXReportCMSvsWSX: `${websweepxApi}/total_cms_links_vs_wsx_links`,
    websweepXReportCMSvsWSXProperties: `${websweepxApi}/cms_propertiesWise_vs_wsx_propertiesWise`,
    websweepXhssCategory: `${websweepxApi}/hss_category`,
    topSitestobeAutomated: `${websweepxApi}/top_sites_to_be_automated`,
    websweepXWhitelistedSites: `${websweepxApi}/whitelisted_sites`,
    websweepXPrompts: `${websweepxApi}/prompt_config`,
    websweepXPromptsChecker: `${websweepxApi}/prompt_checker`,
    telegram_datasets: `${websweepxApi}/telegram_datasets`,
    telegram_bots: `${websweepxApi}/telegram_bots`,
    search_telegram_bots: `${websweepxApi}/search_telegram_bots`,
    websweepXHssDetailsShadow: `${websweepxApi}/hss_details_shadow`,
    websweepXReportCMSvsWSXHostWise: `${websweepxApi}/cms_hostWise_vs_wsx_hostWise`,
    wsxStatsReportData: `${websweepxApi}/wsx_stats_report`,
    websweepxCrawledPagesReport: `${websweepxApi}/crawled_pages_report`,
    websweepxCMSPropertiesMapping: `${websweepxApi}/cms_properties_mapping`,
    websweepXReportCMSvsWSXHSSWise: `${websweepxApi}/cms_vs_wsx_hsswise_properties`,
    websweepXSitesExecutionLogs: `${websweepxApi}/sites_execution_logs`,
    websweepxCMSvsWSXTelegramReport: `${websweepxApi}/telegram_cms_links_vs_wsx_links`,
    websweepxTelegramChannelMonitoring: `${websweepxApi}/telegram_channel_monitoring`,
    websweepxTelegramIntermediate: `${websweepxApi}/telegram_intermediate_data`,
    intermediate_detailed_report: `${websweepxApi}/intermediate_detailed_report`,
    fetch_propertyWise_channels: `${websweepxApi}/fetch_propertyWise_channels`,
    websweepxTelegramIntermediateBulkUpdate: `${websweepxApi}/telegram_intermediate_data_bulk_update`,
    websweepxExtractMessages: `${websweepxApi}/extract_messages`,
    executeScriptsPropertywise: `${websweepxApi}/execute_scripts_propertywise`,
    websweepxSyncTelegramChannels: `${websweepxApi}/sync_telegram_subscribed_channels`,
    websweepxFetchAllChannelMsgs: `${websweepxApi}/fetch_all_channel_messages`,
    websweepxFetchSelectedChannelMsgs: `${websweepxApi}/fetch_channel_messages`,
    telegramChannelwiseLinks: `${websweepxApi}/channelwise_links`,
    reportsOnTelegram: `${websweepxApi}/reportsOnTelegram`,
    websweepxTelegramPostToDiscoveredData: `${websweepxApi}/push_to_discovered_data`,
    websweepxGoogleSearchConfig: `${websweepxApi}/google_config`,
    websweepxGoogleSearchMain: `${websweepxApi}/google_search`,
    websweepxGoogleSearchSync: `${websweepxApi}/google_search_data`,
    websweepxGoogleSearchDiscovered: `${websweepxApi}/google_search_discovered_data`,
    websweepxGoogleSearchBulkUpdate: `${websweepxApi}/google_search_status_bulk_update`,
    websweepxPropertyWiseContentUrls: `${websweepxApi}/propertywise_content_urls`,
    websweepxExecuteContentUrlWise: `${websweepxApi}/execute_content_urls_wise`,
    websweepxPropertyWiseContentUrlsHistory: `${websweepxApi}/propertywise_content_urls_history`,
    keyword_discover_pattern: `${websweepxApi}/keyword_discover_pattern`,
    keyword_discover_pattern_filter: `${websweepxApi}/keyword_discover_pattern`,
  };
};

let webformAutomationEndpoints = ({ webformsApi, webformFillAutomate }) => {
  return {
    webformsData: `${webformsApi}/webforms`,
    webforms_csv_data: `${webformsApi}/webforms_csv_data`,
    webformFillAutomate: `${webformFillAutomate}`,
  };
};

// let sendEmailEndpoints = (sendmailApi) => {
//   console.log("ll", sendmailApi);

//   return {
//     triggerList: `${sendmailApi}/email_trigger`,
//   };
// };

export default function baseEnv({
  baseApi,
  influencersAPI,
  surveyApi,
  dataExtractionApi,
  siteDiscovery,
  mcnApi,
  cloudMailApi,
  competitionalAnalyticsApi,
  openAiApi,
  socketApi,
  schedulerapi,
  translateApi,
  telegramApi,
  a3labelApi,
  crmApi,
  youtubeMonitorApi,
  fingerPrintApi,
  a3TunesApi,
  sendmailApi,
  linkCheckingApi,
  aiSuggestionsApi,
  dsrApi,
  influencerSchedulerApi,
  websweepxApi,
  noCodeAPi,
  webformsApi,
  noCodeWebformsApi,
  webformFillAutomate,
  chatBotApi,
  a3mailserviceApi,
}) {
  let routes = {
    route: {
      baseRoute: "/react-redux-architecture", // Fixes issue with Github Pages
    },
    api: {
      baseApi: `${baseApi}`,
      telegramApi: `${telegramApi}`,
      socketApi: `${socketApi}`,
      translateApi: `${translateApi}`,
      a3mailserviceApi: `${a3mailserviceApi}`,
      a3TunesApi: `${a3TunesApi}`,
      fingerPrintApi: `${fingerPrintApi}`,
      sendmailApi: `${sendmailApi},`,
      linkCheckingApi: `${linkCheckingApi}`,
      noCodeAPi: `${noCodeAPi}`,
      login: `${baseApi}/v1/api/login`,
      noCodeWebformsApi: `${noCodeWebformsApi}`,
      // Logout api
      logout: `${baseApi}/v1/api/logout`,
      errorExample: "https://httpstat.us/520",
      tickets: `${baseApi}/ticket`, //tickets list
      allMentions: `${baseApi}/all_mentions`, //all_mentions list
      ticketFeedFilters: `${baseApi}/ticketfeedfilters`,
      mentionFeedFilters: `${baseApi}/all_mentions_feed_filters`,
      ticketsBulkPut: `${baseApi}/ticketBulkUpdate`,
      // ticket with page limit none
      ticketfilter: `${baseApi}/ticket`,
      update_ticket_sentiment: `${baseApi}/update_ticket_sentiment`,
      // priority filter
      filterpriority: `${baseApi}/ticket_priority`,
      // priority filter
      priority: `${baseApi}/ticket_priority`,
      priorityBulkUpdate: `${baseApi}/ticketPriorityBulkUpdate`,
      project: `${baseApi}/project`, //Prject API

      projectfilter: `${baseApi}/project`, //Project filter
      tag: `${baseApi}/tag`, //Tag Api
      tagBulkUpdate: `${baseApi}/tagBulkUpdate`, //Tag Api

      category: `${baseApi}/category`, //category API
      categoryBulkUpdate: `${baseApi}/categoryBulkUpdate`, //category API

      categoryfilter: `${baseApi}/category`,
      playstore: `${baseApi}/playstore`, // playstore Apt
      playstorefilter: `${baseApi}/playstore?order_by=[["created_on","desc"]]`, // playstore filter
      appstore: `${baseApi}/appstore_feed`, // appstore feed
      appstorefilter: `${baseApi}/appstore_feed`, //appstore filter
      custom_ticket_fields: `${baseApi}/custom_ticket_fields`, //custom ticket fields
      platform: `${baseApi}/platform`,

      // twitter api
      twitter: `${baseApi}/twitter`,
      twitterfilter: `${baseApi}/twitter`,
      tweetDelete: `${baseApi}/delete_tweet`,

      // ticketType
      ticketType: `${baseApi}/ticket_type`,
      filterTicketType: `${baseApi}/ticket_type`,
      ticketTypeUpdate: `${baseApi}/ticketTypeBulkUpdate`,

      // user api
      user: `${baseApi}/user`,
      workingStatus: `${baseApi}/working_status`,

      // admin Reset Password
      adminResetPassword: `${baseApi}/v1/api/adminResetPassword`,

      // user Reset Password
      userResetPassword: `${baseApi}/v1/api/userResetPassword`,

      // user Update Profile Img
      userProfileImgUpdate: `${baseApi}/update_my_profile_picture`,

      // user api with page_limit none
      userfilter: `${baseApi}/user`,

      //dashboard api
      dashboard: `${baseApi}/dashboard`,

      // customfield api
      custom_ticket_field: `${baseApi}/custom_ticket_fields`,
      customfieldsBulkUpdate: `${baseApi}/customTicketFieldsBulkUpdate`,

      // customfield api for filter
      custom_ticket_field_filter: `${baseApi}/custom_ticket_fields`,

      //ticket Worked on
      ticketWorkedOn: `${baseApi}/ticket_worked_on`,

      //ticket resolved
      ticketResolved: `${baseApi}/ticket_resolved`,

      //ticket first response time api
      ticketFirstResponseTime: `${baseApi}/ticket_avg_frt`,

      //ticket tat response
      ticketAvgTat: `${baseApi}/ticket_avg_tat`,

      //twitter dashboard
      twitterDashboard: `${baseApi}/twitter_customer_details`,

      //reply api
      reply: `${baseApi}/reply`,
      // Twitter direct message api
      twitterDM: `${baseApi}/twitter_dm`,
      twitterDirectMessage: `${baseApi}/twitter_direct`,

      //retweet api
      retweet: `${baseApi}/retweet`,
      unretweet: `${baseApi}/unretweet`,

      //Activities
      activities: `${baseApi}/activities`,

      //User Last Working Proejct
      userLastWorkingProject: `${baseApi}/user_last_working_project`,

      //PlayStore Reply
      playStoreReply: `${baseApi}/playstore_replay`,
      playStoreBulkReply: `${baseApi}/bulk_reply`,

      //Twitter Account
      addAccount: `${baseApi}/twitter_account`,
      addAccountFilter: `${baseApi}/twitter_account`,

      //  last sync date based on the platform
      last_sync_date: `${baseApi}/last_sync_date`,

      // Client
      client: `${baseApi}/client`,
      clientFilter: `${baseApi}/client`,

      //user permisson
      userPermission: `${baseApi}/UserPermission`,

      //Google Login
      googleLogin: `${baseApi}/google_login`,

      // facebook
      facebook: `${baseApi}/facebook_webhook`,
      facebookFilter: `${baseApi}/facebook`,
      facebookWebhook: `${baseApi}/facebook_webhook`,
      facebook_dm: `${baseApi}/facebook_dm`,
      facebookReply: `${baseApi}/post_facebook_reply`,
      facebookComment: `${baseApi}/post_facebook_comment`,
      facebookCommentDelete: `${baseApi}/post_facebook_comment`,
      facebookCommentHide: `${baseApi}/hide_facebook_comment`,

      //instagram
      instagram: `${baseApi}/instagram`,
      instagramFilter: `${baseApi}/instagram`,
      instagramReply: `${baseApi}/instagram_replay`,
      instagramComment: `${baseApi}/instagram_comment`,
      instagramHideComment: `${baseApi}/instagram_hide_comment`,
      instagramDeleteComment: `${baseApi}/instagram_delete_comment`,
      threadFeeds: `${baseApi}/threads_feed`,
      threadsReply: `${baseApi}/threads_reply`,

      //discord_feed
      discord: `${baseApi}/discord_feed`,

      //pinterest_feed
      pinterest: `${baseApi}/pinterest_feed`,

      //trustPilot_feed
      trustPilot: `${baseApi}/trustpilot_feed`,

      //roles
      roles: `${baseApi}/roles`,

      //ROLE PERMISSION

      rolePermission: `${baseApi}/role_permission`,

      //roles filter
      rolesfilter: `${baseApi}/roles`,

      //resources filter
      resourceFilter: `${baseApi}/resource`,

      //rolePermision delete and post operation
      RolePermission: `${baseApi}/role_permission`,

      //Add Account
      googleAccount: `${baseApi}/google_account`,
      googleAccountFilter: `${baseApi}/google_account`,

      //operations
      operation: `${baseApi}/operation`,

      //YouTube Account Account
      youtubeAccount: `${baseApi}/youtube_account`,
      youtubeAccountFilter: `${baseApi}/youtube_account`,

      // pageRole role mapping opa
      pagePermission: `${baseApi}/page_permission`,
      // pageRoleMapping: `${baseApi}/page_role_mapping`,
      // pageRoleMappingFilter: `${baseApi}/page_role_mapping`,

      //Youtube feeds
      youtubeFeed: `${baseApi}/youtube_feed`,
      youtubeReply: `${baseApi}/youtube_replay`,

      //Facebook Account
      facebookAccount: `${baseApi}/facebook_account`,
      facebookAccountFilter: `${baseApi}/facebook_account`,

      //indeed account
      indeedAccount: `${baseApi}/indeed_account`,

      //Trustpilot account
      trustpilotaccount: `${baseApi}/trustpilot_account`,

      //reddit account
      redditAccount: `${baseApi}/reddit_account`,

      // quora Account
      quoraAccount: `${baseApi}/quora_account`,

      //Instagram Account
      instagramAccount: `${baseApi}/instagram_account`,
      instagramAccountFilter: `${baseApi}/instagram_account`,

      // Email Feeds
      emailFeeds: `${baseApi}/email_feed`,
      emailFeedsFilter: `${baseApi}/email_feed`,
      emailReply: `${baseApi}/email_reply`,
      postSendEmail: `${baseApi}/send_email`,

      // Email Account
      emailAccount: `${baseApi}/email_account`,
      emailAccountFilter: `${baseApi}/email_account`,

      //Appstore AddAccount
      appStoreAddAccount: `${baseApi}/appstore_account`,
      appstoreAccountFilter: `${baseApi}/appstore_account`,

      //Threads AddAccount
      threadsAddAccount: `${baseApi}/threads_account`,
      threadsAddAccountFilter: `${baseApi}/threads_account`,

      //WhatsApp Feed
      whatsAppFeed: `${baseApi}/whatsapp_feed`,
      whatsAppFeedFilter: `${baseApi}/whatsapp_feed`,
      whatsappPostMessage: `${baseApi}/whatsapp_post_message`,

      //telegram feed
      telegramFeed: `${baseApi}/telegram_feed`,
      telegramSendMessage: `${telegramApi}/send_message`,

      //platformScheduler
      platform_scheduling: `${baseApi}/scheduling`,
      platform_task_scheduling: `${schedulerapi}/orm_platform_scheduling`,

      // Quora Feeds
      quoraFeed: `${baseApi}/quora_answers`,
      quoraFeedFilter: `${baseApi}/quora_answers`,

      // Glassdoor Account
      glassdoorAccount: `${baseApi}/glassdoor_account`,
      glassdoorAccountFilter: `${baseApi}/glassdoor_account`,

      // Glassdoor Feeds
      glassdoorFeeds: `${baseApi}/glassdoor_feed`,
      glassdoorFeedsFilter: `${baseApi}/glassdoor_feed`,

      // AmbitionBox Feeds
      ambitionBoxFeeds: `${baseApi}/ambitionbox_feed`,
      ambitionBoxFeedsFilter: `${baseApi}/ambitionbox_feed`,

      // AmbitionBox Account
      ambitionBoxAccount: `${baseApi}/ambitionbox_account`,
      ambitionBoxAccountFilter: `${baseApi}/ambitionbox_account`,

      // Organization
      organization: `${baseApi}/organization`,
      organizationFilter: `${baseApi}/organization`,
      triggerList: `${sendmailApi}/email_trigger`,
      userMailMapping: `${sendmailApi}/email_trigger_user_mapping`,
      updateUserMailMapping: `${sendmailApi}/update_email_subscription`,

      // User Role Mapping
      userRoleMapping: `${baseApi}/user_role_mapping`,
      userRoleMappingFilter: `${baseApi}/user_role_mapping`,

      // Instagram direct message api
      instagramDirectMessage: `${baseApi}/instagram_direct`,

      // Instagram direct message Post
      instagramDirectMessagePost: `${baseApi}/instagram_direct_send_message`,

      // Whatsapp account
      whatsappAccount: `${baseApi}/whatsapp_account`,

      // GMB platform account
      gmbAccount: `${baseApi}/gmb_account`,

      //platform accounts
      platformAccount: `${baseApi}/add_account`,
      platformAccountFilter: `${baseApi}/add_account`,
      telegramPlatformAccount: `${baseApi}/add_account`,

      //reportsOnTickets
      ticketReports: `${baseApi}/reportsOnTicket`,
      agentReports: `${baseApi}/agent_report`,
      sentimentalAnalysisReports: `${baseApi}/sentiment_analysis_report`,

      //frontEndResource endpoint for Page creation
      frontEndResource: `${baseApi}/front_end_resource`,

      //status
      status: `${baseApi}/status`,
      statusFilter: `${baseApi}/status`,
      statusUpdate: `${baseApi}/statusBulkUpdate`,

      // ticket history
      ticketHistory: `${baseApi}/ticket_history`,

      // ticket settings
      ticketSettings: `${baseApi}/ticket_settings`,

      // response category
      responseCategory: `${baseApi}/response_category`,
      responseCategoryFilter: `${baseApi}/response_category`,

      // response category
      cannedMessage: `${baseApi}/canned_message`,

      // response message
      responseMessage: `${baseApi}/response_message`,
      responseMessageFilter: `${baseApi}/response_message`,

      // keyword configuration
      keywordConfiguration: `${baseApi}/keyword_config`,
      keywordConfigurationFilter: `${baseApi}/keyword_config`,

      //customerData
      customerData: `${baseApi}/customer_data`,

      ormCustomerAndAccountNames: `${baseApi}/all_customer_and_account_names`,

      // report template
      reportTemplate: `${baseApi}/report_template`,
      reportTemplateFilter: `${baseApi}/report_template`,
      UpdateOrgReportTemplate: `${baseApi}/update_report_template`,

      //diy Reporttemplate
      diyReportTemplate: `${baseApi}/new_report_template`,

      // orm report logo
      ormReportLogo: `${baseApi}/logo`,

      //pageTagged posts
      pageTaggedPost: `${baseApi}/pageTaggedPosts`,

      // reports on insatgram
      reportsOnInstagram: `${baseApi}/reportsOnInstagram`,

      // word cloud settings
      wordCloudSettings: `${baseApi}/word_cloud_settings`,

      //Gmb Reviews
      GMBReviewFeeds: `${baseApi}/gmb_feed`,
      GMBReviewFeedsFilter: `${baseApi}/gmb_feed`,
      GMBReviewReply: `${baseApi}/gmbReplay`,
      GMBAnswerReply: `${baseApi}/gmbAnswer`,
      GMBReviewBulkReply: `${baseApi}/gmbBulkReply`,

      //Gmb Reviews
      GMBQuestionFeeds: `${baseApi}/gmb_question`,
      GMBQuestionFeedsFilter: `${baseApi}/gmb_question`,

      // Linked feeds
      LinkedFeed: `${baseApi}/linkedin_feed`,
      LinkedInReply: `${baseApi}/linkedin_reply`,
      LinkedInPostMentionReply: `${baseApi}/linkedin_post_mention_reply`,

      // Reports Logo api
      reportLogo: `${baseApi}/logo`,

      // linked In Account
      linkedInAccount: `${baseApi}/linkedin_account`,

      // ORM user device reset
      ormResetDevice: `${baseApi}/mobile_device_id_mapping`,

      //Assignment rule
      assignmentRule: `${baseApi}/assignment_rule`,
      assignmentRuleFilter: `${baseApi}/assignment_rule`,

      //Auto-response
      autoresponse: `${baseApi}/orm_automation`,
      automationOperations: `${baseApi}/automation_operations`,
      automationFields: `${baseApi}/automation_fields`,
      export_ticket_report: `${baseApi}/export_ticket_report`,
      autoresponseBulkUpdate: `${baseApi}/orm_automation_bulk_update`,

      // Telegram Account
      telegramAccount: `${baseApi}/telegram_account`,
      telegramAuth: `${baseApi}/telegram_validate_otp`,
      telegramSeach: `${websweepxApi}/search_telegram_channels`,
      telegram_search_history: `${websweepxApi}/telegram_search_history`,
      telegramSubscribe: `${websweepxApi}/subscribe_channels`,
      // ORM notification
      ormNotificationsData: `${baseApi}/orm_notifications_data`,
      ormNotificationsDataBulkUpdate: `${baseApi}/orm_notification_bulk_update`,

      // orm resources
      ormResource: `${baseApi}/ormResource`,

      // orm pages
      ormPages: `${baseApi}/ormPages`,

      // Ticket count for status count and category count
      ticketCount: `${baseApi}/ticket_count`,

      // facebook inner filter for filtering comments of same user
      fbInnerFilter: `${baseApi}/fb_inner_filter`,
      brandCompitator: `${baseApi}/brand_competitor_account`,
      brandCompitatorFilter: `${baseApi}/brand_competitor_account`,

      // indeed platform endpoints
      indeed: `${baseApi}/indeed_feed`,

      // reddit platform endpoints
      reddit: `${baseApi}/reddit_feed`,
      redditPosts: `${baseApi}/reddit_posts`,
      redditComments: `${baseApi}/reddit_comments`,

      // consumer feeds
      consumer_feed: `${baseApi}/consumercomplaints_feed`,
      consumer_account: `${baseApi}/consumercomplaints_account`,

      // Platform Post api
      platformPost: `${baseApi}/orm_schedule_post`,

      // discord account
      discordAccount: `${baseApi}/discord_account`,

      // discord account
      pinterestAccount: `${baseApi}/pinterest_account`,

      // aiSuggestion endoint
      aiSuggestion: `${aiSuggestionsApi}/ai_suggested_responses`,
      bardAiSuggestion: `${aiSuggestionsApi}/bard_ai_suggested_responses`,

      // AI settings
      aiSettings: `${baseApi}/openai_settings`,

      chat: `${chatBotApi}/chat`,

      // FAQ
      faq: `${baseApi}/faq`,
      faqTopics: `${baseApi}/faq_topics`,
      faqModules: `${baseApi}/faq_modules`,
    },

    isProduction: true,
    isDevelopment: false,
    isTesting: false,
  };

  if (process.env.REACT_APP_SURVEY_ENABLED === "true") {
    routes.api = { ...routes.api, ...surveyEndpoints(surveyApi) };
  }

  if (process.env.REACT_APP_INFLUENCER_ENABLED === "true") {
    routes.api = {
      ...routes.api,
      ...influencerEndpoints({
        baseApi,
        influencersAPI,
        influencerSchedulerApi,
      }),
    };
  }

  if (process.env.REACT_APP_DATAEXTRACTION_ENABLED === "true") {
    routes.api = {
      ...routes.api,
      ...dataExtractionEndpoints(dataExtractionApi),
    };
  }

  if (process.env.REACT_APP_SITE_DISCOVERY_ENABLED === "true") {
    routes.api = {
      ...routes.api,
      ...siteDiscoveryEndpoints(siteDiscovery),
    };
  }

  if (process.env.REACT_APP_MCN_ENABLED === "true") {
    routes.api = {
      ...routes.api,
      ...mcnEndpoints(mcnApi, competitionalAnalyticsApi, youtubeMonitorApi),
    };
  }

  if (process.env.REACT_APP_CLOUD_MAIL_ENABLED === "true") {
    routes.api = {
      ...routes.api,
      ...cloudMailEndpoints(cloudMailApi),
    };
  }

  if (process.env.REACT_APP_OPEN_AI_ENABLED === "true") {
    routes.api = {
      ...routes.api,
      ...openAiEndpoints(baseApi, openAiApi),
    };
  }

  if (process.env.REACT_APP_A3LABEL_ENABLED === "true") {
    routes.api = {
      ...routes.api,
      ...A3LabelEndpoints(a3labelApi, baseApi, a3mailserviceApi),
    };
  }

  if (process.env.REACT_APP_CRM_ENABLED === "true") {
    routes.api = { ...routes.api, ...crmEndpoints(crmApi) };
  }

  if (process.env.REACT_APP_FINGERPRINTING_ENABLED === "true") {
    routes.api = { ...routes.api, ...fingerPrintEndpoints(fingerPrintApi) };
  }

  if (process.env.REACT_APP_LINK_CHECKING_ENABLED === "true") {
    routes.api = { ...routes.api, ...linkCheckingEndpoints(linkCheckingApi) };
  }

  if (process.env.REACT_APP_DSR_ENABLED === "true") {
    routes.api = {
      ...routes.api,
      ...dsrEndpoints({ dsrApi, socketApi }),
    };
  }
  if (process.env.REACT_APP_WEBSWEEPX_ENABLED === "true") {
    routes.api = {
      ...routes.api,
      ...websweepxEndpoints({ websweepxApi }),
    };
  }

  if (process.env.REACT_APP_WEBFORM_AUTOMATION_ENABLED === "true") {
    routes.api = {
      ...routes.api,
      ...webformAutomationEndpoints({ webformsApi, webformFillAutomate }),
    };
  }

  return routes;
}
